import Vue from 'vue'
import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBVOnPzeJWa4pKUjKkqQs0FMSrtQf7g7RE',
    libraries: 'places'
  },
  autobindAllEvents: false,
  installComponents: true
})
