import axios from 'axios'
import router from '@/router'
import Vue from 'vue'
import firebase from 'firebase/app'
import 'firebase/database'
import metricsAPI from '@/modules/advisor/api/metrics'

export const getDecisionTreeData = function({ commit, state }, options) {
  return metricsAPI.decisionTree(state.reportId, options).then(treeData => {
    commit('setDecisionTreeData', treeData)
  })
}

export const getMenuData = function({ commit, state }) {
  if (state.selectedInstitution.id && state.selectedPeerGroup.id) {
    return axios
      .get('/api/metrics/report_menu', {
        handleErrors: true,
        params: {
          bank_id: state.selectedInstitution.id,
          peer_group_id: state.selectedPeerGroup.id
        }
      })
      .then(res => {
        commit('setMenuData', res.data)
        var starCountRef = firebase
          .database()
          .ref('reports/' + res.data.report_token)
        if (res.data.report_progress !== 100) {
          starCountRef.on('value', function(snapshot) {
            let snap = snapshot.val()
            commit('setReportStatus', snap)
          })
        }
      })
  } else if (state.selectedInstitution) {
    Vue.toasted.show('Please select peer group.', {
      icon: 'chain-broken',
      type: 'error'
    })
    router.push('/analytics/advisor/select_peer_group')
  } else {
    Vue.toasted.show('Please select institution.', {
      icon: 'chain-broken',
      type: 'error'
    })
    router.push('/analytics/advisor/select_institution')
  }
}
