import getters from './industry_reporter.getters'
import mutations from './industry_reporter.mutations'
import actions from './industry_reporter.actions'

import { getField, updateField } from 'vuex-map-fields'

const initialState = () => ({
  selectedGroups: [],
  selectedReport: null,
  selectedSegment: { id: null, name: null },
  metricMode: 'multi',
  showSegment: false,
  showTotals: false,
  showGraph: true,
  mergerRestated: false,
  selectedSpan: { id: 2, name: 'Trailing-12 Months' },
  selectedScope: 'trend',
  selectedPeriod: null,
  selectedMetric: null,
  groupFIType: { value: 'bank', text: 'Bank' },
  spans: [
    { id: 1, name: 'Quarterly' },
    { id: 2, name: 'Trailing-12 Months' },
    { id: 3, name: 'Year-to-Date' }
  ],
  scopes: [
    { id: 'trend', name: 'Multi-Period' },
    { id: 'single', name: 'Single-Period' }
  ],
  enableTotal: false,
  enableGraph: false,
  fiGroups: [],
  metrics: [],
  financialStatements: [],
  periodItems: [],
  periods: [],
  segmentSets: [],
  segmentDetails: [],
  tableData: [],
  latestPeriod: [],
  bankServices: [],
  bankServicesData: [],
  displayType: 'chart',
  singleMetricOptions: [],
  segmentRules: [],
  printWithSingleMetricsData: false,
  downloadReportData: { name: '', data: [] },
  colorSeries: [
    '#A7CA77',
    '#414142',
    '#84B0E2',
    '#EBB38A',
    '#F6F89E',
    '#5E803F',
    '#4C73BE',
    '#EB3323',
    '#FFFD54',
    '#67379A'
  ],
  totalFICount: null,
  validSegment: true,
  validSet: true,
  validSetDetails: true,
  reportOutdated: false,
  rerunReport: false,
  treeRules: [
    { id: 'common-metrics', label: 'Common Metrics (Bank/CU)' },
    { id: 'detailed-metrics', label: 'Detailed Metrics' },
    { id: 'financial-statements', label: 'Common Statements (Bank/CU)' },
    { id: 'detailed-statements', label: 'Detailed Statements' },
    { id: 'technographics', label: 'Technographics' }
  ]
})

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters: {
    ...getters,
    getField
  },
  mutations: {
    ...mutations,
    resetIndustryReporterState(state) {
      let baseState = initialState()
      Object.keys(baseState).forEach(key => {
        state[key] = baseState[key]
      })
    },
    updateField
  }
}
