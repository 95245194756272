export default {
  setSelectedInstitution(state, institution) {
    state.selectedInstitution = institution
  },
  setSelectedSolution(state, solution) {
    state.selectedSolution = solution
  },
  setProfileDetail(state, payload) {
    state.profileDetail = payload
  },
  setSolutionDetail(state, payload) {
    state.solutionDetail = payload
  },
  setClientInstitutions(state, payload) {
    state.clientInstitutions = payload
  },
  setSelectedVendor(state, vendor) {
    state.selectedVendor = vendor
  },
  setCategoryElements(state, payload) {
    state[`${payload.category}Elements`] = payload.values
  },
  setVendorProducts(state, payload) {
    state.vendorProducts = payload
  },
  setAllInstitutions(state, payload) {
    state.allInstitutions = payload
  },
  setFILimit(state, payload) {
    state.fiLimit = payload
  },
  setVendorLimit(state, payload) {
    state.vendorLimit = payload
  },
  setShowAllFIs(state, payload) {
    state.showAllFIs = payload
  },
  setShowAllVendors(state, payload) {
    state.showAllVendors = payload
  },
  resetFIDependentData(state) {
    state.selectedVendor = null
    state.profileDetail = {}
    state.solutionDetail = {}
    state.clientInstitutions = []
    state.sizeElements = []
    state.marketElements = []
    state.strategyElements = []
    state.profitElements = []
    state.growthElements = []
    state.riskElements = []
    state.productivityElements = []
    state.technologyElements = []
    state.vendorProducts = []
    state.allInstitutions = []
    state.fiLimit = 20
    state.vendorLimit = 5
    state.showAllFIs = false
  },
  resetVendorDependentData(state) {
    state.sizeElements = []
    state.marketElements = []
    state.strategyElements = []
    state.profitElements = []
    state.growthElements = []
    state.riskElements = []
    state.productivityElements = []
    state.technologyElements = []
    state.clientInstitutions = []
    state.allInstitutions = []
    state.fiLimit = 20
    state.showAllFIs = false
  }
}
