import Highcharts from 'highcharts'
import exportingInit from 'highcharts/modules/exporting'
import exportData from 'highcharts/modules/export-data'

Highcharts.SVGRenderer.prototype.symbols.download = function(x, y, w, h) {
  var path = [
    'M',
    x + w * 0.5,
    y,
    'L',
    x + w * 0.5,
    y + h * 0.7,
    'M',
    x + w * 0.3,
    y + h * 0.5,
    'L',
    x + w * 0.5,
    y + h * 0.7,
    'L',
    x + w * 0.7,
    y + h * 0.5,
    'M',
    x,
    y + h * 0.9,
    'L',
    x,
    y + h,
    'L',
    x + w,
    y + h,
    'L',
    x + w,
    y + h * 0.9
  ]
  return path
}

exportingInit(Highcharts)
exportData(Highcharts)
