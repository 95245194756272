<template>
  <div class="app flex-row align-items-center">
    <div class="container">
      <b-row class="justify-content-center">
        <b-col md="6">
          <div class="clearfix">
            <h1 class="float-left display-3 mr-4">301</h1>
            <h4 class="pt-3">Application upgraded.</h4>
            <p class="text-muted">
              Please refresh the page and relogin to get updated version.
            </p>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PageMoved'
}
</script>
