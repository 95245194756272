<template>
  <div>
    <b-card-header header-class="aside-header pl-2">
      <strong>Report Options</strong>
      <span
        class="fa fa-times-circle fa-lg close-icon"
        @click.stop="closeSettings"
      ></span>
    </b-card-header>

    <b-card body-class="p-2">
      <h6 class="text-left mt-2 mb-3">View Options</h6>
      <div class="p-2">
        <b-form-group class="mb-3">
          <b-button-group>
            <b-button
              :variant="isMultiMetric ? 'primary' : 'secondary'"
              @click="updateMetricMode('multi')"
            >
              Multi Metric
            </b-button>
            <b-button
              :variant="isMultiMetric ? 'secondary' : 'primary'"
              @click="updateMetricMode('single')"
            >
              Single Metric
            </b-button>
          </b-button-group>
        </b-form-group>

        <b-form-group class="mb-4">
          <b-button-group>
            <b-button
              :variant="isMultiPeriod ? 'primary' : 'secondary'"
              @click="updatePeriod('trend')"
            >
              Multi Period
            </b-button>
            <b-button
              :variant="isMultiPeriod ? 'secondary' : 'primary'"
              @click="updatePeriod('single')"
            >
              Single Period
            </b-button>
          </b-button-group>
        </b-form-group>

        <b-form-group class="mb-2">
          <b-row class="align-items-center">
            <b-col cols="7">Show Segments:</b-col>
            <b-col cols="4" class="pl-0">
              <c-switch
                type="text"
                variant="success"
                on="Yes"
                off="No"
                :pill="true"
                size="sm"
                :class="[
                  selectedSegment.id ? '' : 'tag-disabled',
                  'mb-0 float-left'
                ]"
                :checked="showSegment"
                :disabled="!selectedSegment.id"
                @change="setShowSegments($event)"
              />
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group class="mb-2">
          <b-row class="align-items-center">
            <b-col cols="7">Show Totals:</b-col>
            <b-col cols="4" class="pl-0">
              <c-switch
                type="text"
                variant="success"
                on="Yes"
                off="No"
                :pill="true"
                size="sm"
                :class="[enableTotal ? '' : 'tag-disabled', 'mb-0 float-left']"
                :checked="showTotals"
                @change="setShowTotals($event)"
                :disabled="!enableTotal"
              />
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group class="mb-4">
          <b-row class="align-items-center">
            <b-col cols="7">Show Graph:</b-col>
            <b-col cols="4" class="pl-0">
              <c-switch
                type="text"
                variant="success"
                on="Yes"
                off="No"
                :pill="true"
                size="sm"
                :class="[enableGraph ? '' : 'tag-disabled', 'mb-0 float-left']"
                :checked="showGraph"
                :disabled="!enableGraph"
                @change="setShowGraph($event)"
              />
            </b-col>
          </b-row>
        </b-form-group>

        <b-form-group class="mb-4" v-if="!isMultiMetric">
          <b-row>
            <b-col cols="6" class="d-flex align-self-center"
              >Display Type:</b-col
            >
            <b-col cols="6 pl-0">
              <b-form-select
                v-model="chartDisplayType"
                :class="[isMultiPeriod ? '' : 'tag-disabled']"
              >
                <option
                  v-for="(chart, index) in displayOptions"
                  :value="chart.id"
                  :key="index"
                  >{{ chart.type }}</option
                >
              </b-form-select>
            </b-col>
          </b-row>
        </b-form-group>

        <hr class="mb-0" />
        <div class="text-muted text-right mb-3">
          <small class="font-weight-bold">
            <sup class="">* </sup>Report recalculation required.
          </small>
        </div>

        <b-form-group>
          <label for="fi_asset_group">Select Period Span:</label>
          <b-form-select :value="selectedSpan.id" @change="setSpan($event)">
            <option
              v-for="(span, index) in spans"
              :value="span.id"
              :key="index"
              >{{ span.name }}</option
            >
          </b-form-select>
        </b-form-group>

        <b-form-group v-if="groupFIType.value !== 'credit_union'">
          <b-row>
            <b-col cols="6" class="pr-0">Merger Restate:</b-col>
            <b-col cols="4">
              <c-switch
                on="Yes"
                off="No"
                type="text"
                variant="success"
                size="sm"
                :pill="true"
                class="float-left"
                :checked="mergerRestated"
                @change="setMergerRestated($event)"
              />
            </b-col>
          </b-row>
        </b-form-group>

        <div v-if="reportOutdated">
          <hr class="mb-3" />
          <div class="mb-2">
            Source data for this report has been updated. Would you like to
            rerun the report now?
          </div>
          <div class="text-center">
            <b-button variant="primary" size="sm" @click="rerunReport">
              Rerun Report
            </b-button>
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
// global
import { mapState, mapMutations } from 'vuex'
import { mapFields } from 'vuex-map-fields'
// ui components
import cSwitch from '@/components/Switch'

export default {
  name: 'IRReportAside',
  components: {
    cSwitch
  },
  data() {
    return {
      displayOptions: [
        { id: 'chart', type: 'Chart' },
        { id: 'table', type: 'Table' }
      ]
    }
  },
  computed: {
    ...mapState('IndustryReporter', {
      metricMode: state => state.metricMode,
      showSegment: state => state.showSegment,
      showTotals: state => state.showTotals,
      showGraph: state => state.showGraph,
      mergerRestated: state => state.mergerRestated,
      selectedSpan: state => state.selectedSpan,
      selectedScope: state => state.selectedScope,
      selectedSegment: state => state.selectedSegment,
      groupFIType: state => state.groupFIType,
      enableTotal: state => state.enableTotal,
      enableGraph: state => state.enableGraph,
      spans: state => state.spans,
      scopes: state => state.scopes,
      reportOutdated: state => state.reportOutdated
    }),
    ...mapFields('IndustryReporter', ['selectedScope', 'displayType']),
    chartDisplayType: {
      get() {
        return this.displayType
      },
      set(type) {
        this.setDisplayType(type)
      }
    },
    isMultiMetric() {
      return this.metricMode === 'multi'
    },
    isMultiPeriod() {
      return this.selectedScope === 'trend'
    }
  },
  methods: {
    ...mapMutations('IndustryReporter', [
      'setMetricMode',
      'setSelectedSpan',
      'setShowSegments',
      'setShowTotals',
      'setShowGraph',
      'setSelectedScope',
      'setMergerRestated',
      'setDisplayType',
      'setReportRerunStatus'
    ]),
    setSpan(spanID) {
      this.setSelectedSpan(this.spans.find(s => s.id === spanID))
    },
    updateMetricMode(mode) {
      this.setMetricMode(mode)
    },
    updatePeriod(scope) {
      this.setSelectedScope(scope)
    },
    closeSettings() {
      document.body.classList.add('aside-menu-hidden')
      this.$store.dispatch('setAsideToogle', false)
    },
    rerunReport() {
      this.setReportRerunStatus(true)
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
