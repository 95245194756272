import store from '@/store/store.js'
// general utilities
import deepClone from '@/utilities.js'
import {
  getDisplayTextForMLElement,
  getDisplayTextForFirmographicsElement
} from '@/modules/advanced_query/helpers/utilities.js'

class QueryTransformer {
  constructor(query = null) {
    this.query = query || store.getters['AdvancedQuery/currentQuery']
    this.queryRules = store.getters['AdvancedQuery/queryRules']
    this.criteria = null
    this.runtime = null
    this.ruleCounts = {}
    this.list = []
    this.queryElements = []
    this.buildQueryElements(this.query)
  }

  getSegmentRule(rule) {
    return this.queryRules.find(qr => qr.id === rule.ruleId)
  }

  getOperandRule(rule) {
    return this.getSegmentRule(rule).children.find(or => or.id === rule.operand)
  }

  getChildOperandRule(rule) {
    return this.getOperandRule(rule).children.find(
      cor => cor.id === rule.childOperand
    )
  }
  prepareTechnographicElement(rule) {
    let duplicateElement = this.queryElements.find(qe => {
      return qe.type === 'technographics' && qe.elementID === rule.operand
    })

    let property = rule.operator
      .match(/vendor|product|has service/g)[0]
      .replace(' ', '_')

    if (duplicateElement) {
      if (!duplicateElement.property.includes(property)) {
        duplicateElement.property.push(property)
      }
      duplicateElement.ruleID.push(rule.id)
    } else {
      this.queryElements.push({
        ruleID: [rule.id],
        id: this.queryElements.length + 1,
        elementID: rule.operand,
        type: 'technographics',
        name: rule.operandLabel,
        property: [property],
        section: ''
      })
    }
  }

  prepareMortgageLendingElement(rule) {
    //let operandRule = this.getChildOperandRule(rule)
    //let gChildOperandRule = this.getOperandRule(rule)
    let duplicateElement = null
    if (!rule.filterOn) {
      //need to update the duplicate element chk
      duplicateElement = this.queryElements.find(qe => {
        return (
          qe.type === 'mortgage_lending' &&
          qe.elementID === rule.operand &&
          qe.grandChild === rule.grandChildOperand &&
          qe.filterOn === false
        )
      })
    }

    if (duplicateElement) {
      duplicateElement.ruleID.push(rule.id)
    } else {
      let filters = []
      if (rule.filterOn) {
        let segmentType = this.getSegmentRule(rule)
        rule.filters.forEach(filter => {
          filters.push(
            this.createFilterObject(filter, segmentType.filters, filters.length)
          )
        })
      }
      this.queryElements.push({
        ruleID: [rule.id],
        id: this.queryElements.length + 1,
        elementID: null,
        type: 'mortgage_lending', //gChildOperandRule.element_type,
        name: getDisplayTextForMLElement(rule),
        // property: gChildOperandRule.element_property,
        actionTaken: rule.actionTaken,
        outputType: rule.outputType,
        filterOn: rule.filterOn,
        filterOperator: rule.filterOperator,
        filters: filters
      })
    }
  }
  createFilterObject(filter, filterList, prevId) {
    //fetch the filter obj
    let selectedFilter = filterList.find(f => {
      return f.id === filter.filterId
    })
    let selectedFilterChild = selectedFilter.children.find(child => {
      return child.id === filter.childOperand.id
    })
    return {
      id: prevId + 1,
      elementID: filter.childOperand.id,
      filterId: filter.filterId,
      type: selectedFilterChild.element_type,
      property: selectedFilterChild.element_property,
      operator: filter.operator,
      value: filter.value,
      ruleId: filter.ruleID,
      filterObjId: filter.id,
      elementName: filter.childOperand.label
    }
  }
  prepareVendorFitElement(rule) {
    let operandRule = this.getOperandRule(rule)
    let childOperandRule = this.getChildOperandRule(rule)
    let productEqual = operandRule.label === 'product equal'

    let duplicateElement = this.queryElements.find(qe => {
      return (
        qe.type === childOperandRule.element_type &&
        qe.elementID === childOperandRule.element_id &&
        qe.fitType === rule.fitType &&
        qe.productEqual === productEqual
      )
    })

    if (duplicateElement) {
      duplicateElement.ruleID.push(rule.id)
    } else {
      this.queryElements.push({
        ruleID: [rule.id],
        id: this.queryElements.length + 1,
        elementID: childOperandRule.element_id,
        type: childOperandRule.element_type,
        name: childOperandRule.label,
        property: childOperandRule.element_property,
        fitType: rule.fitType,
        productEqual: productEqual,
        section: ''
      })
    }
  }

  prepareMetricElement(rule, elType, elProperty) {
    let duplicateElement = this.queryElements.find(qe => {
      return qe.type === elType && qe.elementID === rule.operand
    })

    if (duplicateElement) {
      duplicateElement.ruleID.push(rule.id)
    } else {
      this.queryElements.push({
        ruleID: [rule.id],
        id: this.queryElements.length + 1,
        elementID: rule.operand,
        type: elType,
        name: rule.operandLabel,
        property: elProperty
      })
    }
  }

  prepareFinancialStatementElement(rule, elType, elProperty) {
    let duplicateElement = this.queryElements.find(qe => {
      return qe.type === elType && qe.elementID === rule.operand
    })

    if (duplicateElement) {
      duplicateElement.ruleID.push(rule.id)
    } else {
      this.queryElements.push({
        ruleID: [rule.id],
        id: this.queryElements.length + 1,
        elementID: rule.operand,
        type: elType,
        name: rule.operandLabel,
        property: elProperty
      })
    }
  }

  prepareFirmographicElement(rule) {
    let operandRule = this.getOperandRule(rule)
    if (operandRule.operators.action_taken) {
      this.prepareFirmoActionTakenElement(rule)
    } else {
      if (['metrics', 'common_metric'].includes(operandRule.element_type)) {
        this.prepareGeneralIDElement(rule)
      } else {
        this.prepareGeneralPropertyElement(rule)
      }
    }
  }

  /* This is specific to Firmographics for Mortgage Lenders */
  prepareFirmoActionTakenElement(rule) {
    let operandRule = this.getOperandRule(rule)
    //Need to update this duplicate element chk
    let displayText = getDisplayTextForFirmographicsElement(rule)
    let duplicateElement = this.queryElements.find(qe => {
      return qe.type === operandRule.element_type && qe.name === displayText
    })

    if (duplicateElement) {
      duplicateElement.ruleID.push(rule.id)
    } else {
      this.queryElements.push({
        ruleID: [rule.id],
        id: this.queryElements.length + 1,
        elementID: null,
        type: operandRule.element_type,
        name: displayText,
        property: operandRule.element_property,
        actionTaken: rule.actionTaken,
        outputType: rule.outputType,
        elementOption: rule.elementOption
      })
    }
  }
  prepareDigitalChannelElement(rule) {
    if (
      (rule.source && rule.source.match('bank_services.id')) ||
      rule.section === 'digital_channels'
    ) {
      this.prepareDigitalChannelTechnographicElement(rule, 'digital_channels')
    } else {
      this.prepareGeneralPropertyElement(rule)
    }
  }

  prepareDigitalChannelTechnographicElement(rule) {
    let operandRule = this.getOperandRule(rule)

    let duplicateElement = this.queryElements.find(qe => {
      return (
        qe.type === operandRule.element_type &&
        parseInt(qe.elementID) === parseInt(operandRule.element_id)
      )
    })

    let property = rule.operator
      .match(/vendor|product|has service/g)[0]
      .replace(' ', '_')

    if (duplicateElement) {
      if (!duplicateElement.property.includes(property)) {
        duplicateElement.property.push(property)
      }
      duplicateElement.ruleID.push(rule.id)
    } else {
      this.queryElements.push({
        ruleID: [rule.id],
        id: this.queryElements.length + 1,
        elementID: operandRule.element_id,
        type: operandRule.element_type,
        name: operandRule.label
          .split('-')
          .pop()
          .trim(),
        property: [property],
        section: 'digital_channels'
      })
    }
  }

  prepareGeneralIDElement(rule) {
    let operandRule = this.getOperandRule(rule)

    let duplicateElement = this.queryElements.find(qe => {
      return (
        qe.type === operandRule.element_type &&
        parseInt(qe.elementID) === parseInt(operandRule.element_id)
      )
    })

    if (duplicateElement) {
      duplicateElement.ruleID.push(rule.id)
    } else {
      this.queryElements.push({
        ruleID: [rule.id],
        id: this.queryElements.length + 1,
        elementID: operandRule.element_id,
        type: operandRule.element_type,
        name: operandRule.label,
        property: operandRule.element_property
      })
    }
  }

  prepareGeneralPropertyElement(rule) {
    let operandRule = this.getOperandRule(rule)
    let duplicateElement = this.queryElements.find(qe => {
      return (
        qe.type === operandRule.element_type &&
        qe.property === operandRule.element_property
      )
    })

    if (duplicateElement) {
      duplicateElement.ruleID.push(rule.id)
    } else {
      this.queryElements.push({
        ruleID: [rule.id],
        id: this.queryElements.length + 1,
        elementID: null,
        type: operandRule.element_type,
        name: operandRule.label,
        property: operandRule.element_property
      })
    }
  }

  preparePerformographicElement(rule) {
    let operandRule = this.getOperandRule(rule)

    let duplicateElement = this.queryElements.find(qe => {
      return (
        qe.type === operandRule.element_type &&
        parseInt(qe.elementID) === parseInt(operandRule.element_id)
      )
    })

    if (duplicateElement) {
      duplicateElement.ruleID.push(rule.id)
    } else {
      this.queryElements.push({
        ruleID: [rule.id],
        id: this.queryElements.length + 1,
        elementID: operandRule.element_id,
        type: operandRule.element_type,
        name: operandRule.label,
        property: operandRule.element_property
      })
    }
  }

  prepareJobSearchDummyElement(rule) {
    const dummy_element_type = 'job_search'
    let property = rule.value[0]
    let duplicateElement = this.queryElements.find(qe => {
      return qe.type === dummy_element_type && qe.property === property
    })

    if (duplicateElement) {
      duplicateElement.ruleID.push(rule.id)
    } else {
      this.queryElements.push({
        ruleID: [rule.id],
        id: this.queryElements.length + 1,
        type: dummy_element_type,
        property: property
      })
    }
  }

  prepareSiteSearchDummyElement(rule) {
    const dummy_element_type = 'site_search'

    let property = rule.value[0]
    let duplicateElement = this.queryElements.find(qe => {
      return qe.type === dummy_element_type && qe.property === property
    })

    if (duplicateElement) {
      duplicateElement.ruleID.push(rule.id)
    } else {
      this.queryElements.push({
        ruleID: [rule.id],
        id: this.queryElements.length + 1,
        type: dummy_element_type,
        property: property
      })
    }
  }
  prepareContactSearchDummyElement(rule) {
    const dummy_element_type = 'contact_search'

    let property = rule.value[0]
    let duplicateElement = this.queryElements.find(qe => {
      return qe.type === dummy_element_type && qe.property === property
    })

    if (duplicateElement) {
      duplicateElement.ruleID.push(rule.id)
    } else {
      this.queryElements.push({
        ruleID: [rule.id],
        id: this.queryElements.length + 1,
        type: dummy_element_type,
        property: property
      })
    }
  }
  prepareSelectIntitionsDummyElement(rule) {
    const dummy_element_type = 'select_institutions'
    let property = rule.source
    let duplicateElement = this.queryElements.find(qe => {
      return qe.type === dummy_element_type && qe.property === property
    })

    if (duplicateElement) {
      duplicateElement.ruleID.push(rule.id)
    } else {
      this.queryElements.push({
        ruleID: [rule.id],
        id: this.queryElements.length + 1,
        type: dummy_element_type,
        property: property
      })
    }
  }

  addQueryElement(rule) {
    let segmentType = this.getSegmentRule(rule)

    switch (segmentType.label) {
      case 'Market':
      case 'Segmentation':
        this.prepareGeneralPropertyElement(rule)
        break
      case 'Digital Channels':
        this.prepareDigitalChannelElement(rule)
        break
      case 'Firmographics':
        this.prepareFirmographicElement(rule)
        break
      case 'Performographics':
        this.preparePerformographicElement(rule)
        break
      case 'Common Metrics (Bank/CU)':
        this.prepareMetricElement(rule, 'common_metric', 'value')
        break
      case 'Detailed Metrics':
        this.prepareMetricElement(rule, 'metrics', 'value')
        break
      case 'Common Statements (Bank/CU)':
        this.prepareFinancialStatementElement(
          rule,
          'common_financial_statements',
          'default'
        )
        break
      case 'Detailed Statements':
        this.prepareFinancialStatementElement(
          rule,
          'financial_statements',
          'value'
        )
        break
      case 'Technographics':
        this.prepareTechnographicElement(rule)
        break
      case 'Core Processing':
        this.prepareVendorFitElement(rule)
        break
      case 'Job Search':
        this.prepareJobSearchDummyElement(rule)
        break
      case 'Site Search':
        this.prepareSiteSearchDummyElement(rule)
        break
      case 'Contact Search':
        this.prepareContactSearchDummyElement(rule)
        break
      case 'Select Institutions':
        this.prepareSelectIntitionsDummyElement(rule)
        break
      case 'Mortgage Lending':
        this.prepareMortgageLendingElement(rule)
        break
      default:
        break
    }
  }

  buildQueryElements(query) {
    if (query.type === 'group') {
      query.children.forEach(rule => {
        this.buildQueryElements(rule)
      })
    } else {
      this.addQueryElement(query)
    }
  }

  buildCriteria(query) {
    if (query.type === 'group') {
      return {
        id: query.id,
        type: query.type,
        primary: query.primary,
        operator: query.operator,
        children: query.children.map(rule => {
          return this.buildCriteria(rule)
        })
      }
    } else {
      let filters = null
      let queryElement = this.queryElements.find(qe =>
        qe.ruleID.includes(query.id)
      )
      if (queryElement.type === 'mortgage_lending') {
        filters = this.transformToFilterCriteria(query, queryElement.filters)
      }
      return {
        id: query.id,
        type: query.type,
        element: queryElement.id,
        operator: query.operator,
        value: query.value,
        filters: filters,
        filter_operator: query.filterOperator
      }
    }
  }

  matchingCustomElement(queryElement) {
    let customElements = store.getters['ReportWriter/customElements']

    return customElements.find(
      customElement =>
        parseInt(customElement.id) === parseInt(queryElement.elementID) &&
        customElement.type === queryElement.type
    )
  }

  propertyUpdatedQueryEls() {
    return this.queryElements.map(qe => {
      if (qe.elementID) {
        let matchingCustomEl = this.matchingCustomElement(qe)

        if (matchingCustomEl) {
          let queryElement = deepClone(qe)

          queryElement.set = matchingCustomEl.set
          queryElement.span = matchingCustomEl.span
          queryElement.scope = matchingCustomEl.scope

          if (matchingCustomEl.type === 'technographics') {
            queryElement.property = matchingCustomEl.property
          }

          return queryElement
        } else {
          if (qe.type === 'technographics') {
            qe.property = ['default']
          }
          return qe
        }
      } else {
        return qe
      }
    })
  }

  uniqCustomElements() {
    let customElements = store.getters['ReportWriter/customElements']
    return customElements.filter(re => {
      return re.id
        ? !this.queryElements.find(
            qe =>
              parseInt(re.id) === parseInt(qe.elementID) && re.type === qe.type
          )
        : re.type === 'firmographics' &&
          !this.queryElements.find(
            qe => qe.type === re.type && qe.name === re.name
          )
        ? true
        : re.type === 'mortgage_lending' &&
          !this.queryElements.find(
            qe => qe.type === re.type && qe.name === re.name
          )
        ? true
        : !this.queryElements.find(
            qe => qe.type === re.type && qe.property === re.property
          )
    })
  }

  validElements(mode) {
    if (mode === 'save') {
      return this.queryElements
    } else {
      let globalSettings = store.getters['ReportWriter/globalSettings']
      let globalFinMetrics = store.getters['ReportWriter/globalFinMetrics']
      let globalFinStatements =
        store.getters['ReportWriter/globalFinStatements']
      let globalTechnographics =
        store.getters['ReportWriter/globalTechnographics']
      let transformedCustomElements = this.uniqCustomElements().map(
        (ce, index) => {
          let customElement = {
            id: this.queryElements.length + index + 1,
            elementID: ce.id,
            type: ce.type,
            name: ce.name,
            property: ce.property,
            ruleID: []
          }

          if (
            [
              'metrics',
              'common_metric',
              'financial_statements',
              'common_financial_statements'
            ].includes(ce.type)
          ) {
            if (
              ['metrics', 'common_metric'].includes(ce.type) &&
              globalSettings['Financial Metrics'] == 'true'
            ) {
              customElement.set = globalFinMetrics.set.id
              customElement.span = globalFinMetrics.span.id
              customElement.scope = globalFinMetrics.scope.id
              customElement.isGlobalSetting = true
            } else if (
              ['financial_statements', 'common_financial_statements'].includes(
                ce.type
              ) &&
              globalSettings['Financial Statements'] == 'true'
            ) {
              customElement.set = globalFinStatements.set.id
              customElement.span = globalFinStatements.span.id
              customElement.scope = globalFinStatements.scope.id
              customElement.isGlobalSetting = true
            } else {
              customElement.set = ce.set
              customElement.span = ce.span
              customElement.scope = ce.scope
            }
          } else if (ce.type === 'vendor_fit_static') {
            customElement.leading = ce.leading
          } else if (ce.type === 'fi_contacts') {
            customElement.has_email = ce.email
            customElement.exclude_fi = ce.excludeFI
            customElement.titles = ce.titles
            customElement.roles = ce.roles
          } else if (ce.type === 'firmographics' && ce.actionTaken) {
            customElement.actionTaken = ce.actionTaken
            customElement.outputType = ce.outputType
            customElement.elementOption = ce.elementOption
            customElement.property = ce.property
          } else if (ce.type === 'mortgage_lending') {
            customElement.actionTaken = ce.actionTaken
            customElement.outputType = ce.outputType
            customElement.filterOn = ce.filterOn
            customElement.filterOperator = ce.filterOperator
            customElement.isCustomElement = true
            customElement.filters = ce.filters
          } else if (
            ce.type === 'technographics' &&
            globalSettings['Technographics'] == 'true'
          ) {
            customElement.property = globalTechnographics.property.map(
              prop => prop.id
            )
            customElement.isGlobalSetting = true
          }

          return customElement
        }
      )
      return [...this.propertyUpdatedQueryEls(), ...transformedCustomElements]
    }
  }

  transformElements(mode) {
    return this.validElements(mode).map(qe => {
      let element = { element_id: qe.elementID, element_type: qe.type }

      if (qe.section) {
        element.element_section = qe.section
      }

      if (
        [
          'metrics',
          'common_metric',
          'financial_statements',
          'common_financial_statements'
        ].includes(qe.type)
      ) {
        element.element_properties = [
          {
            set: qe.set || 1,
            span: qe.span || 2,
            scope: qe.scope || 'single',
            element_property: qe.property,
            is_global_setting: qe.isGlobalSetting || false
          }
        ]
      } else if (qe.type === 'fi_contacts') {
        element.element_properties = [
          {
            roles: qe.roles,
            titles: qe.titles,
            has_email: qe.has_email,
            exclude_fi: qe.exclude_fi,
            element_property: qe.property
          }
        ]
      } else if (qe.type === 'vendor_fit') {
        element.element_properties = [
          {
            fit_type: qe.fitType,
            element_property: qe.property,
            product_equal: qe.productEqual
          }
        ]
      } else if (qe.type === 'vendor_fit_static') {
        element.element_properties = [
          {
            leading: qe.leading,
            element_property: qe.property
          }
        ]
      } else if (qe.type === 'firmographics' && qe.actionTaken) {
        element.element_properties = [
          {
            action_taken: qe.actionTaken,
            output_type: qe.outputType,
            element_option: qe.elementOption,
            element_property: qe.property
          }
        ]
      } else if (qe.type === 'mortgage_lending') {
        element.element_properties = [
          {
            action_taken: qe.actionTaken,
            output_type: qe.outputType,
            filter_on: qe.filterOn,
            filter_operator: qe.filterOperator
          }
        ]
        element.filters = qe.filterOn
          ? this.transformToFilterElement(qe.filters, qe.isCustomElement, qe)
          : []
      } else if (qe.type === 'technographics') {
        element.element_property = qe.property
        element.is_global_setting = qe.isGlobalSetting || false
      } else {
        element.element_property = qe.property
      }

      return {
        id: qe.id,
        element: [element]
      }
    })
  }

  transformToElement(queryRule) {
    let qe = null
    if (queryRule.type === 'filter') {
      let ruleElement = this.queryElements.find(qe =>
        qe.ruleID.includes(queryRule.ruleId)
      )
      qe = ruleElement.filters.find(
        f => f.elementID === queryRule.childOperand.id
      )
    } else {
      qe = this.queryElements.find(qe => qe.ruleID.includes(queryRule.id))
    }

    let element = { element_id: qe.elementID, element_type: qe.type }

    if (qe.section) {
      element.element_section = qe.section
    }

    if (
      [
        'metrics',
        'common_metric',
        'financial_statements',
        'common_financial_statements'
      ].includes(qe.type)
    ) {
      element.element_properties = [
        {
          set: qe.set || 1,
          span: qe.span || 2,
          scope: qe.scope || 'single',
          element_property: qe.property
        }
      ]
    } else if (qe.type === 'vendor_fit') {
      element.element_properties = [
        {
          fit_type: qe.fitType,
          element_property: qe.property,
          product_equal: qe.productEqual
        }
      ]
    } else if (qe.type === 'firmographics' && qe.actionTaken) {
      element.element_properties = [
        {
          action_taken: qe.actionTaken,
          output_type: qe.outputType,
          element_option: qe.elementOption,
          element_property: qe.property
        }
      ]
    } else if (qe.type === 'mortgage_lending') {
      //element.element_property = qe.property
      if (queryRule.type === 'filter') {
        element.element_property = qe.property
      } else {
        element.element_properties = [
          {
            action_taken: qe.actionTaken,
            output_type: qe.outputType,
            filter_on: qe.filterOn,
            filter_operator: qe.filterOperator
          }
        ]
        element.filters = qe.filterOn
          ? this.transformToFilterElement(qe.filters, false, null)
          : []
      }
    } else {
      element.element_property = qe.property
    }

    return {
      id: qe.id,
      element: [element]
    }
  }
  transformToFilterElement(filters, addCriteriaInfo, customElement) {
    let filtersElement = []
    filters &&
      filters.forEach(fe => {
        let e = {
          id: fe.id,
          filter_id: fe.filterId
        }
        if (addCriteriaInfo) {
          e.element_id = fe.childOperand.id
          e.element_type = customElement.type
          e.element_property = fe.childOperand.property
          e.operator = fe.operator
          e.value = fe.value
        } else {
          e.element_id = fe.elementID
          e.element_type = fe.type
          e.element_property = fe.property
          e.operator = fe.operator
          e.value = fe.value
        }
        filtersElement.push(e)
      })
    return filtersElement
  }
  transformToCriteria(queryRule) {
    let queryElement = null
    let filters = null
    if (queryRule.type === 'filter') {
      let ruleElement = this.queryElements.find(qe =>
        qe.ruleID.includes(queryRule.ruleId)
      )
      queryElement = ruleElement.filters.find(
        f => f.elementID === queryRule.childOperand.id
      )
    } else {
      queryElement = this.queryElements.find(qe =>
        qe.ruleID.includes(queryRule.id)
      )
    }
    if (queryElement.type === 'mortgage_lending') {
      filters = this.transformToFilterCriteria(queryRule, queryElement.filters)
    }
    return {
      id: queryRule.id,
      type: queryRule.type,
      element: queryElement.id,
      operator: queryRule.operator,
      value: queryRule.value,
      filters: filters,
      filter_operator: queryRule.filterOperator
    }
  }
  transformToFilterCriteria(queryRule, filters) {
    let filtersElement = []
    filters &&
      filters.forEach(fe => {
        let filter = queryRule.filters.find(
          f => f.childOperand.id === fe.elementID
        )
        filtersElement.push({
          id: fe.id,
          type: 'filter',
          //element: filter.childOperand.id,
          operator: filter.operator,
          value: filter.value
        })
      })
    return filtersElement
  }
  updateRuleCounts(query) {
    if (query.type === 'group') {
      query.children.forEach(rule => {
        this.updateRuleCounts(rule)
      })
    } else {
      this.ruleCounts[query.id] = query.count
      query.filters.forEach(filter => {
        if (filter.filterId) this.ruleCounts[filter.id] = filter.count
      })
    }
  }

  get finQueryElements() {
    return this.queryElements.filter(
      qe =>
        ![
          'job_search',
          'site_search',
          'contact_search',
          'select_institutions'
        ].includes(qe.type)
    )
  }

  get transform() {
    return {
      elements: this.transformElements('run'),
      criteria: this.buildCriteria(this.query)
    }
  }

  get transformToSave() {
    this.updateRuleCounts(this.query)

    return {
      elements: this.transformElements('save'),
      criteria: this.buildCriteria(this.query),
      counts: this.ruleCounts
    }
  }
}

export default QueryTransformer
