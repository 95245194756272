import Vue from 'vue'
import { accounting } from 'accounting'
import { MONTHNAMES } from '../utilities'

Vue.filter('capitalize', function(value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

Vue.filter('titleCase', function(value) {
  if (!value) return ''
  return value
    .toString()
    .toLowerCase()
    .split(/\s|_/)
    .map(function(word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    })
    .join(' ')
})

Vue.filter('numberFormat', function(number) {
  if (number !== 0 && !number) return ''
  return new Intl.NumberFormat('en-CA', { currency: 'CAD' }).format(number)
})

Vue.filter('percent', function(value) {
  if (!value) return 'NA'
  return new Intl.NumberFormat('en-US', { style: 'percent' }).format(value)
})

Vue.filter('percent_strict', function(value) {
  if (value === null || value === undefined) return 'NA'
  return new Intl.NumberFormat('en-US', { style: 'percent' }).format(value)
})

Vue.filter('currencyFormat', function(amount) {
  if (amount !== 0 && !amount) return ''
  return new Intl.NumberFormat('en-CA', {
    style: 'currency',
    currency: 'CAD'
  }).format(amount)
})

Vue.filter('formatValue', function(amount, format) {
  if (amount !== 0 && !amount) {
    return 'NA'
  }
  switch (format) {
    case 'P0':
      return accounting.formatNumber(amount * 100, 0) + '%'
    case 'P1':
      return accounting.formatNumber(amount * 100, 1) + '%'
    case 'P2':
      return accounting.formatNumber(amount * 100, 2) + '%'
    case 'N2':
      return accounting.formatNumber(amount, 2)
    case 'N1':
      return accounting.formatNumber(amount, 1)
    case '$#,###':
      return accounting.formatMoney(amount, '$', 0)
    case 'C2':
      return accounting.formatMoney(amount, '$', 2)
    case '$#,###/M':
      return accounting.formatMoney(amount / 1000, '$', 0)
    case '$#,###.0/M':
      return accounting.formatMoney(amount / 1000, '$', 1)
    case '#,###':
      return accounting.formatNumber(amount, 0)
    case '$###.0':
      return accounting.formatMoney(amount, '$', 1)
    case 'C0':
      return accounting.formatMoney(amount, '$', 0)
    case 'N0':
      return accounting.formatNumber(amount, 0)
    case '#,###K':
      return accounting.formatNumber(amount / 1000, 0) + 'K'
    default:
      return accounting.formatNumber(amount, 0)
  }
})

Vue.filter('formatMetricNumber', function(amount, format) {
  if (amount !== 0 && !amount) {
    return 0
  }
  switch (format) {
    case 'P1':
      return accounting.formatNumber(amount * 100, 1)
    case 'P2':
      return accounting.formatNumber(amount * 100, 2)
    case 'N2':
      return accounting.formatNumber(amount, 2)
    case '$#,###':
      return accounting.formatMoney(amount, '', 0)
    case 'C2':
      return accounting.formatMoney(amount, '', 2)
    case '$#,###/M':
      return accounting.formatMoney(amount / 1000, '', 0)
    case '#,###':
      return accounting.formatNumber(amount, 0)
    case '$#,###.0/M':
      return accounting.formatMoney(amount / 1000, '', 1)
    case '$###.0':
      return accounting.formatMoney(amount, '', 1)
    case 'C0':
      return accounting.formatMoney(amount, '$', 0)
    case 'N0':
      return accounting.formatNumber(amount, 0)
    default:
      return accounting.formatNumber(amount, 0)
  }
})

Vue.filter('convertToActualValue', function(amount, format) {
  if (amount !== 0 && !amount) {
    return 0
  }
  switch (format) {
    case 'P1':
    case 'P2':
      return parseFloat(amount) * 100
    case 'N0':
    case 'N2':
    case 'C0':
    case 'C2':
    case '#,###':
    case '$#,###':
    case '$###.0':
      return parseFloat(amount)
    case '$#,###/M':
    case '$#,###.0/M':
      return parseFloat(amount) / 1000
    default:
      return parseFloat(amount)
  }
})

Vue.filter('formatLabel', function(amount, format) {
  switch (format) {
    case 'P1':
      return accounting.formatNumber(amount, 1) + '%'
    case 'P2':
      return accounting.formatNumber(amount, 2) + '%'
    case 'N2':
      return accounting.formatNumber(amount, 2)
    case '$#,###':
      return accounting.formatMoney(amount, '$', 0)
    case 'C2':
      return accounting.formatMoney(amount, '$', 2)
    case '$#,###/M':
      return accounting.formatMoney(amount, '$', 0)
    case '#,###':
      return accounting.formatNumber(amount, 0)
    case '$#,###.0/M':
      return accounting.formatMoney(amount, '$', 1)
    case '$###.0':
      return accounting.formatMoney(amount, '$', 1)
    case 'N0':
      return accounting.formatNumber(amount, 0)
    case 'C0':
      return accounting.formatMoney(amount, '$', 0)
    default:
      return accounting.formatNumber(amount, 0)
  }
})

Vue.filter('toMetricPeriod', function(date) {
  let year = date.toString().slice(2, 4)
  let month = parseInt(date.toString().slice(4, 6))
  return MONTHNAMES()[month].slice(0, 3) + ' ' + year
})

Vue.filter('metricPeriodFormat', function(dateString) {
  let year = dateString.slice(2, 4)
  let month = parseInt(dateString.slice(5, 7))
  return MONTHNAMES()[month].slice(0, 3) + '-' + year
})

Vue.filter('formatFIType', function(fiType) {
  switch (fiType) {
    case 'bank':
      return 'Bank'
    case 'credit_union':
    case 'cu':
      return 'Credit Union'
    case 'bhc':
      return 'BHC'
    case 'mbc':
      return 'MBC'
    case 'both':
      return 'Bank & Credit Union'
  }
})
