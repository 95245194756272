<template>
  <div class="market-share-options">
    <b-card-header header-class="aside-header">
      <strong>Report Options</strong>
      <span
        class="fa fa-times-circle fa-lg close-icon"
        @click="closeSettings"
      ></span>
    </b-card-header>
    <b-card
      :body-class="[
        'pb-3',
        'pl-3',
        'pr-3',
        isFilterCountMoreThanSeven ? 'dc-aside-body pt-2' : 'pt-3'
      ]"
    >
      <div v-if="loadingReport">
        <content-placeholders :rounded="true">
          <content-placeholders-text :lines="15"></content-placeholders-text>
        </content-placeholders>
      </div>
      <div v-else>
        <b-form-group v-if="isFilterEnabled('Current Period')">
          <label for="market_measure">Current Period:</label>
          <b-form-select
            v-model="currentPeriod"
            :options="currentPeriodOptions"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>

        <b-form-group v-if="isFilterEnabled('Previous Period')">
          <label for="market_measure">Previous Period:</label>
          <b-form-select
            v-model="previousPeriod"
            :options="previousPeriodOptions"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>

        <b-form-group v-if="isFilterEnabled('Beginning Period')">
          <label for="market_measure">Beginning Period:</label>
          <b-form-select
            v-model="beginningPeriod"
            :options="beginningPeriodOptions"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>

        <b-form-group v-if="isFilterEnabled('Date Level')">
          <label for="market_measure">Date Level:</label>
          <b-form-select
            v-model="dateLevel"
            :options="dateLevelOptions"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>

        <b-form-group v-if="isFilterEnabled('Client Size Metric')">
          <label for="market_measure">Client Size Metric:</label>
          <b-form-select
            v-model="clientSizeMetric"
            :options="clientSizeMetricOptions"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>
        <b-form-group v-if="isFilterEnabled('OLB Client Size Metric')">
          <label for="market_measure">OLB Client Size Metric:</label>
          <b-form-select
            v-model="olbClientSizeMetric"
            :options="olbClientSizeMetricOptions"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>
        <b-form-group v-if="isFilterEnabled('Market Measure')">
          <label for="market_measure">Market Measure:</label>
          <b-form-select
            v-model="marketMeasure"
            :options="marketMeasureOptions"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>

        <b-form-group v-if="isFilterEnabled('OLB Market Measure')">
          <label for="olb_market_measure">OLB Market Measure:</label>
          <b-form-select
            v-model="olbMarketMeasure"
            :options="olbMarketMeasureOptions"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>
        <b-form-group v-if="isFilterEnabled('Satisfaction Measure')">
          <label for="satisfaction_measure">Satisfaction Measure:</label>
          <b-form-select
            v-model="satisfactionMeasure"
            :options="satisfactionMeasureOptions"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>

        <b-row class="pb-4" v-if="isFilterEnabled('Top Vendors')">
          <b-col cols="12">
            <b-row>
              <b-col cols="8">Top Vendors:</b-col>
              <b-col cols="4" class=" text-right">
                <small
                  class="cursor-pointer show-all"
                  @click="updateShowAllVendors()"
                >
                  {{ showAllVendors ? 'Reset' : 'Show All' }}</small
                >
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-row class="justify-content-center pt-1">
              <div class="btn-group item1">
                <b-button
                  variant="secondary"
                  :disabled="reachedMinCount"
                  @click="decrementVendorCount()"
                  ><i class="fa fa-minus fa-lg"></i
                ></b-button>
              </div>

              <div class="btn-group item2">
                <b-input-group>
                  <b-form-input
                    class="text-right top-vendor-input"
                    v-model="topVendors"
                    @change="setVendorCount()"
                    ref="topVendorInput"
                    size="4"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-input-group-text class="no-left-border">
                      <span
                        @click="focusTopVendor"
                        class="fa fa-pencil pr-2 cursor-pointer text-muted "
                      ></span>
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </div>

              <div class="btn-group item3">
                <b-button
                  variant="secondary"
                  :disabled="reachedMaxCount"
                  @click="incrementVendorCount()"
                  ><i class="fa fa-plus fa-lg"></i
                ></b-button>
              </div>
            </b-row>
          </b-col>
        </b-row>

        <b-row class="pb-4" v-if="isFilterEnabled('Top Features')">
          <b-col cols="12">
            <b-row>
              <b-col cols="8">Top Features:</b-col>
              <b-col cols="4" class=" text-right">
                <small
                  class="cursor-pointer show-all"
                  @click="updateShowAllFeatures()"
                  >{{ showAllFeatures ? 'Reset' : 'Show All' }}</small
                >
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="12">
            <b-row class="justify-content-center pt-1">
              <div class="btn-group item1">
                <b-button
                  variant="secondary"
                  :disabled="reachedMinCountOfFeatures"
                  @click="decrementFeatureCount()"
                  ><i class="fa fa-minus fa-lg"></i
                ></b-button>
              </div>

              <div class="btn-group item2">
                <b-input-group>
                  <b-form-input
                    class="text-right top-vendor-input"
                    v-model="topFeatures"
                    @change="setFeatureCount()"
                    ref="topFeatureInput"
                    size="4"
                  ></b-form-input>

                  <b-input-group-append>
                    <b-input-group-text class="no-left-border">
                      <span
                        @click="focusTopFeature"
                        class="fa fa-pencil pr-2 cursor-pointer text-muted "
                      ></span>
                    </b-input-group-text>
                  </b-input-group-append>
                </b-input-group>
              </div>

              <div class="btn-group item3">
                <b-button
                  variant="secondary"
                  :disabled="reachedMaxCountOfFeatures"
                  @click="incrementFeatureCount()"
                  ><i class="fa fa-plus fa-lg"></i
                ></b-button>
              </div>
            </b-row>
          </b-col>
        </b-row>
        <b-form-group v-if="isFilterEnabled('Name')">
          <label for="name">Name:</label>
          <b-form-select
            id="name"
            v-model="name"
            :options="nameOptions"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>
        <b-row class="pb-4" v-if="isFilterEnabled('Show MB Products')">
          <b-col cols="8">Show Products:</b-col>
          <b-col cols="4">
            <c-switch
              type="text"
              variant="success"
              on="Yes"
              off="No"
              :pill="true"
              size="sm"
              :checked="showMbProducts === 'Yes'"
              @change="toggleShowProduct($event)"
            />
          </b-col>
        </b-row>

        <b-form-group v-if="isFilterEnabled('FI Type')">
          <label for="fi_type">FI Type:</label>
          <b-form-select
            id="fi_type"
            v-model="fiType"
            :options="fiTypeOptions"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>

        <b-form-group v-if="isFilterEnabled('FI Asset Group')">
          <label for="fi_asset_group">FI Asset Group (000):</label>

          <multiselect
            v-model="fiAssetGroup"
            :options="fiAssetGroupOptions"
            :multiple="true"
            :close-on-select="false"
            label="name"
            track-by="id"
            :showLabels="false"
            placeholder=""
            class="simple-select"
            :searchable="false"
            @close="submitFilterSelection"
          >
            <template slot="selection" slot-scope="{ values }">
              {{ values.length }}
              {{ values.length | pluralize('group') }} selected
            </template>

            <template slot="beforeList">
              <li class="multiselect__elemet p-2 border-bottom">
                <span
                  @click="selectOrRemoveAllAssetGroups()"
                  class="select-all-option"
                >
                  <b>{{ assetGroupSelectAllText() }}</b></span
                >
              </li>
            </template>
            <template slot="option" slot-scope="asset">
              <div>
                <input
                  type="checkbox"
                  :checked="isAssetSelected(asset.option)"
                  class="mr-1"
                />
                {{ asset.option.name }}
              </div>
            </template>
          </multiselect>
        </b-form-group>

        <b-form-group v-if="isFilterEnabled('MB Age Group')">
          <label for="fi_asset_group">MB Age Group (000):</label>

          <multiselect
            v-model="mbAgeGroup"
            :options="mbAgeGroupOptions"
            :multiple="true"
            :close-on-select="false"
            label="name"
            track-by="id"
            :showLabels="false"
            placeholder=""
            class="simple-select"
            :searchable="false"
            @close="submitFilterSelection"
          >
            <template slot="selection" slot-scope="{ values }">
              {{ values.length }}
              {{ values.length | pluralize('group') }} selected
            </template>

            <template slot="beforeList">
              <li class="multiselect__elemet p-2 border-bottom">
                <span
                  @click="selectOrRemoveAllAgeGroups()"
                  class="select-all-option"
                >
                  <b>{{ ageGroupSelectAllText() }}</b></span
                >
              </li>
            </template>
            <template slot="option" slot-scope="asset">
              <div>
                <input
                  type="checkbox"
                  :checked="isAgeGroupSelected(asset.option)"
                  class="mr-1"
                />
                {{ asset.option.name }}
              </div>
            </template>
          </multiselect>
        </b-form-group>
        <b-form-group v-if="isFilterEnabled('Feature MB Age Group')">
          <label for="fi_asset_group">MB Age Group (000):</label>

          <multiselect
            v-model="featureMbAgeGroup"
            :options="featureMbAgeGroupOptions"
            :multiple="true"
            :close-on-select="false"
            label="name"
            track-by="id"
            :showLabels="false"
            placeholder=""
            class="simple-select"
            :searchable="false"
            @close="submitFilterSelection"
          >
            <template slot="selection" slot-scope="{ values }">
              {{ values.length }}
              {{ values.length | pluralize('group') }} selected
            </template>

            <template slot="beforeList">
              <li class="multiselect__elemet p-2 border-bottom">
                <span
                  @click="selectOrRemoveAllFeatureAgeGroups()"
                  class="select-all-option"
                >
                  <b>{{ featureAgeGroupSelectAllText() }}</b></span
                >
              </li>
            </template>
            <template slot="option" slot-scope="asset">
              <div>
                <input
                  type="checkbox"
                  :checked="isFeatureAgeGroupSelected(asset.option)"
                  class="mr-1"
                />
                {{ asset.option.name }}
              </div>
            </template>
          </multiselect>
        </b-form-group>
        <!-- <b-form-group v-if="isFilterEnabled('MB Vendor')">
          <label for="fi_asset_group">MB Vendor:</label>

          <multiselect
            v-model="mbVendor"
            :options="mbVendorOptions"
            :multiple="true"
            :close-on-select="false"
            label="name"
            track-by="id"
            :showLabels="false"
            placeholder=""
            class="simple-select"
            :searchable="false"
            @close="submitFilterSelection"
          >
            <template slot="selection" slot-scope="{ values }">
              {{ values.length }}
              {{ values.length | pluralize('vendor') }} selected
            </template>

            <template slot="beforeList">
              <li class="multiselect__elemet p-2 border-bottom">
                <span
                  @click="selectOrRemoveAllVendors()"
                  class="select-all-option"
                >
                  <b>{{ vendorSelectAllText() }}</b></span
                >
              </li>
            </template>
            <template slot="option" slot-scope="asset">
              <div>
                <input
                  type="checkbox"
                  :checked="isVendorSelected(asset.option)"
                  class="mr-1"
                />
                {{ asset.option.name }}
              </div>
            </template>
          </multiselect>
        </b-form-group> -->

        <b-form-group v-if="isFilterEnabled('Known Vendor')">
          <label for="known_vendor">{{ knownVendorLabel }}</label>
          <b-form-select
            id="known_vendor"
            v-model="knownVendor"
            :options="knownVendorOptions"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>

        <b-form-group v-if="isFilterEnabled('App Type')">
          <label for="app_type">App Type:</label>
          <b-form-select
            id="app_type"
            v-model="appType"
            :options="appTypeOptions"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>

        <b-form-group v-if="isFilterEnabled('Vendor Branded')">
          <label for="known_vendor">Vendor Branded:</label>
          <b-form-select
            id="vendor_branded"
            v-model="vendorBranded"
            :options="vendorBrandedOptions"
            value-field="id"
            text-field="name"
          ></b-form-select>
        </b-form-group>

        <b-form-group v-if="isFilterEnabled('Core Vendor Product')">
        </b-form-group>
      </div>
    </b-card>
  </div>
</template>

<script>
// global
import { mapFields } from 'vuex-map-fields'
import { mapState, mapMutations, mapActions } from 'vuex'
// ui components
import cSwitch from '@/components/Switch'
import Multiselect from 'vue-multiselect'
// mixins
import PageSettings from '@/mixins/PageSettings'

export default {
  name: 'DigitalChannelsAside',
  components: {
    cSwitch,
    Multiselect
  },
  mixins: [PageSettings],
  props: {
    reportType: {
      required: true,
      default: 'DigitalChannels'
    }
  },
  created() {},
  data() {
    return {}
  },
  computed: {
    ...mapState('DigitalChannels', [
      'loadingReport',
      'reportFilters',
      'selectedReport',
      'selectedReportFilters',
      'currentPeriodOptions',
      'previousPeriodOptions',
      'beginningPeriodOptions',
      'marketMeasureOptions',
      'olbMarketMeasureOptions',
      'clientSizeMetricOptions',
      'olbClientSizeMetricOptions',
      'satisfactionMeasureOptions',
      'showMbProductsOptions',
      'fiTypeOptions',
      'nameOptions',
      'fiAssetGroupOptions',
      'mbAgeGroupOptions',
      'featureMbAgeGroupOptions',
      'mbVendorOptions',
      'vendorBrandedOptions',
      'appTypeOptions',
      'knownVendorOptions',
      'topVendors',
      'topVendorsMax',
      'topFeatures',
      'topFeaturesMax',
      'multiSelectFilterChanged',
      'dateLevelOptions',
      'showAllVendors',
      'showAllFeatures',
      'selectedReportModule'
    ]),
    ...mapFields('DigitalChannels', [
      'currentPeriod',
      'previousPeriod',
      'beginningPeriod',
      'marketMeasure',
      'olbMarketMeasure',
      'clientSizeMetric',
      'olbClientSizeMetric',
      'satisfactionMeasure',
      'showMbProducts',
      'fiType',
      'name',
      'fiAssetGroup',
      'mbAgeGroup',
      'featureMbAgeGroup',
      'mbVendor',
      'vendorBranded',
      'appType',
      'knownVendor',
      'dateLevel',
      'topVendors',
      'topFeatures'
    ]),
    knownVendorLabel() {
      return this.selectedReportModule === 'Retail Online Banking'
        ? 'Known OLB Vendor:'
        : 'Known Vendor:'
    },
    reachedMinCount() {
      return this.topVendors === 1
    },
    reachedMaxCount() {
      return this.topVendors === this.topVendorsMax
    },
    reachedMinCountOfFeatures() {
      return this.topFeatures === 1
    },
    reachedMaxCountOfFeatures() {
      return this.topFeatures === this.topFeaturesMax
    },
    allAssetGroupSelected() {
      return (
        this.fiAssetGroup &&
        this.fiAssetGroup.length === this.fiAssetGroupOptions.length
      )
    },
    allAgeGroupSelected() {
      return (
        this.mbAgeGroup &&
        this.mbAgeGroup.length === this.mbAgeGroupOptions.length
      )
    },
    allFeatureAgeGroupSelected() {
      return (
        this.featureMbAgeGroup &&
        this.featureMbAgeGroup.length === this.featureMbAgeGroupOptions.length
      )
    },
    allVendorsSelected() {
      return (
        this.mbVendor && this.mbVendor.length === this.mbVendorOptions.length
      )
    },
    isFilterCountMoreThanSeven() {
      return (
        this.selectedReport &&
        this.selectedReport.filter_ids &&
        this.selectedReport.filter_ids.length > 7
      )
    }
  },
  mounted() {},
  methods: {
    ...mapMutations('DigitalChannels', [
      'setMbVendor',
      'setMbAgeGroup',
      'setFeatureMbAgeGroup',
      'setFIAssetGroup',
      'setShowAllVendors',
      'setShowAllFeatures',
      'updateTopVendors',
      'setTopVendors',
      'updateTopFeatures',
      'updateShowMBProduct',
      'setMultiSelectFilterChanged',
      'resetTopFeatures'
    ]),
    ...mapActions('DigitalChannels', ['loadReportData']),
    focusTopVendor() {
      this.$refs.topVendorInput.focus()
    },
    focusTopFeature() {
      this.$refs.topFeatureInput.focus()
    },
    toggleShowProduct(enableProduct) {
      this.updateShowMBProduct(enableProduct)
    },
    incrementVendorCount() {
      this.updateTopVendors(parseInt(this.topVendors) + 1)
      this.setShowAllVendors(this.reachedMaxCount)

      this.loadReportData()
    },
    decrementVendorCount() {
      this.updateTopVendors(this.topVendors - 1)
      this.setShowAllVendors(this.reachedMaxCount)

      this.loadReportData()
    },
    setVendorCount() {
      if (this.topVendors < 0) this.topVendors = 0
      if (this.topVendors > this.topVendorsMax)
        this.topVendors = this.topVendorsMax
      this.setShowAllVendors(this.reachedMaxCount)
      this.loadReportData()
    },
    updateShowAllVendors() {
      if (this.showAllVendors) {
        this.setShowAllVendors(false)
        this.updateTopVendors(15) //set to default value
      } else {
        this.setShowAllVendors(true)
        this.updateTopVendors(this.topVendorsMax)
      }
      this.loadReportData()
    },
    setFeatureCount() {
      if (this.topFeatures < 0) this.topFeatures = 0
      if (this.topFeatures > this.topFeaturesMax)
        this.topFeatures = this.topFeaturesMax
      this.setShowAllFeatures(this.reachedMaxCountOfFeatures)
      this.loadReportData()
    },
    updateShowAllFeatures() {
      if (this.showAllFeatures) {
        this.setShowAllFeatures(false)
        this.resetTopFeatures() //set to default value
      } else {
        this.setShowAllFeatures(true)
        this.updateTopFeatures(this.topFeaturesMax)
      }
      this.loadReportData()
    },
    incrementFeatureCount() {
      this.updateTopFeatures(parseInt(this.topFeatures) + 1)
      this.setShowAllFeatures(this.reachedMaxCountOfFeatures)
      this.loadReportData()
    },
    decrementFeatureCount() {
      this.updateTopFeatures(this.topFeatures - 1)
      this.setShowAllFeatures(this.reachedMaxCountOfFeatures)

      this.loadReportData()
    },
    /*TODO:Remove*/
    setMaxFeatureCount() {
      this.updateTopFeatures(this.topFeaturesMax)
    },
    assetGroupSelectAllText() {
      return this.allAssetGroupSelected ? 'Clear All' : 'Select All'
    },
    ageGroupSelectAllText() {
      return this.allAgeGroupSelected ? 'Clear All' : 'Select All'
    },
    featureAgeGroupSelectAllText() {
      return this.allFeatureAgeGroupSelected ? 'Clear All' : 'Select All'
    },
    vendorSelectAllText() {
      return this.allVendorsSelected ? 'Clear All' : 'Select All'
    },
    selectOrRemoveAllAssetGroups() {
      this.setFIAssetGroup(
        this.allAssetGroupSelected ? [] : this.fiAssetGroupOptions
      )
    },
    selectOrRemoveAllAgeGroups() {
      this.setMbAgeGroup(this.allAgeGroupSelected ? [] : this.mbAgeGroupOptions)
    },
    selectOrRemoveAllFeatureAgeGroups() {
      this.setFeatureMbAgeGroup(
        this.allFeatureAgeGroupSelected ? [] : this.featureMbAgeGroupOptions
      )
    },
    selectOrRemoveAllVendors() {
      this.setMbVendor(this.allVendorsSelected ? [] : this.mbVendorOptions)
    },
    isAssetSelected(option) {
      return (
        this.fiAssetGroup &&
        this.fiAssetGroup.map(ag => ag.id).includes(option.id)
      )
    },
    isAgeGroupSelected(option) {
      return (
        this.mbAgeGroup && this.mbAgeGroup.map(ag => ag.id).includes(option.id)
      )
    },
    isFeatureAgeGroupSelected(option) {
      return (
        this.featureMbAgeGroup &&
        this.featureMbAgeGroup.map(ag => ag.id).includes(option.id)
      )
    },
    isVendorSelected(option) {
      return this.mbVendor && this.mbVendor.map(v => v.id).includes(option.id)
    },
    isFilterEnabled(filterName) {
      let matchingFilter =
        this.reportFilters &&
        this.reportFilters.find(rf => rf.name === filterName)

      return (
        this.selectedReport &&
        matchingFilter &&
        this.selectedReport.filter_ids.includes(matchingFilter.id)
      )
    },
    submitFilterSelection() {
      this.setMultiSelectFilterChanged(true)
    }
  }
}
</script>

<style lang="scss" scoped>
.show-all {
  color: #20a8d8;
}
.no-left-border {
  background-color: white;
  border-left: none;
  padding-right: 0;
}
.top-vendor-input {
  border-right: none;
  padding-right: 2rem;
}
.select-all-option {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
.btn-group.item1 {
  width: 15%;
}
.btn-group.item2 {
  width: 55%;
}
.btn-group.item3 {
  width: 15%;
}
.dc-aside-body {
  label {
    margin-bottom: 0;
  }
  .form-group {
    margin-bottom: 0.5rem;
  }
}
</style>
