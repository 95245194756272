<template>
  <button
    class="sidebar-minimizer"
    type="button"
    @click="
      sidebarMinimize()
      brandMinimize()
    "
  ></button>
</template>

<script>
import { mapState, mapMutations } from 'vuex'

export default {
  name: 'sidebar-minimizer',
  created() {
    if (!this.isSidebarInFullView()) {
      this.setSidebarExpanded = false
    }
  },
  computed: {
    ...mapState({
      isSidebarExpanded: state => state.isSidebarExpanded
    })
  },
  methods: {
    ...mapMutations(['setSidebarExpanded']),
    sidebarMinimize() {
      document.body.classList.toggle('sidebar-minimized')
      this.setSidebarExpanded(!this.isSidebarExpanded)
    },
    brandMinimize() {
      document.body.classList.toggle('brand-minimized')
    },
    isSidebarInFullView() {
      return !(
        document.body.classList.contains('sidebar-minimized') ||
        document.body.classList.contains('sidebar-hidden')
      )
    }
  }
}
</script>
