export const toggleSet = function(state) {
  state.setId = state.setId === 1 ? 2 : 1
}

export const setAllSingleMetricsData = function(state, payload) {
  state.allSingleMetricsData = payload
}

export const setPrintWithSingleMetricsData = function(state, payload) {
  state.printWithSingleMetricsData = payload
}

export const unsetSelectedReport = function(state) {
  state.metric_args.reportTypeId = null
  state.metric_args.reportType = null
}
