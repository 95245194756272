import fitProfileAPI from '@/api/finapps/fit_profile'
import userProfileAPI from '@/modules/profile/api/user.profile'

export default {
  fetchProfileDetail({ state, commit }, options) {
    if (Object.keys(state.profileDetail).length) {
      return Promise.resolve()
    }

    return userProfileAPI.fiProfileDetails(options.fiID).then(details => {
      commit('setProfileDetail', details)
    })
  },
  fetchSolutionDetail({ state, commit }, options) {
    if (Object.keys(state.solutionDetail).length) {
      return Promise.resolve()
    }

    return fitProfileAPI
      .institutionSolution(options.fiID, options.solutionID)
      .then(details => {
        commit('setSolutionDetail', details)
      })
  },
  fetchVendorProducts({ state, commit }, options) {
    if (state.vendorProducts.length) {
      return Promise.resolve()
    }

    return fitProfileAPI
      .vendorProducts(options.fiID, options.limit)
      .then(res => {
        commit('setVendorProducts', res)
      })
  },
  fetchAllInstitutions({ commit }, options) {
    return fitProfileAPI
      .clientInstitutions(options.productID, options.limit)
      .then(institutions => {
        commit('setAllInstitutions', institutions)
      })
  },
  fetchClientInstitutions({ state, commit }, options) {
    if (state.clientInstitutions.length) {
      return Promise.resolve()
    }

    return fitProfileAPI
      .clientInstitutions(options.productID, options.limit)
      .then(institutions => {
        commit('setClientInstitutions', institutions)
      })
  },
  fetchCategoryElements({ state, commit }, options) {
    let categoryElements = state[`${options.category}Elements`]

    if (categoryElements.length) {
      return Promise.resolve()
    }

    return fitProfileAPI
      .categoryElements(
        options.fiID,
        options.solutionID,
        options.productID,
        options.category
      )
      .then(res => {
        commit('setCategoryElements', {
          category: options.category,
          values: res.product_values
        })
      })
  }
}
