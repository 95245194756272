export default {
  path: '/admin',
  redirect: '/admin/groups',
  name: 'Admin',
  meta: { requiredAuth: true },
  component: () => import('./pages/admin.js'),
  children: [
    {
      path: 'groups',
      name: 'Groups',
      component: () => import('./pages/Groups'),
      meta: { type: 'admin' }
    },
    {
      path: 'users',
      name: 'Users',
      component: () => import('./pages/Users'),
      meta: { type: 'admin' }
    },
    {
      path: 'users/new',
      name: 'CreateUser',
      component: () => import('./pages/CreateUser'),
      meta: { type: 'admin' }
    },
    {
      path: 'user/:id/edit',
      name: 'EditUser',
      component: () => import('./pages/EditUser'),
      meta: { type: 'admin' }
    },
    {
      path: 'groups/new',
      name: 'CreateGroup',
      component: () => import('./pages/CreateGroup'),
      meta: { type: 'admin' }
    },
    {
      path: 'group/:id/edit',
      name: 'EditGroup',
      component: () => import('./pages/EditGroup'),
      meta: { type: 'admin' }
    },
    {
      path: 'user_activity',
      name: 'UserActivity',
      component: () => import('./pages/UserActivity'),
      meta: { type: 'admin' }
    },
    {
      path: 'user_order_report',
      name: 'UserPurchaseHistory',
      component: () => import('./pages/UserPurchaseHistory'),
      meta: { type: 'admin' }
    },
    {
      path: 'direct_access_defaults',
      name: 'SingleClickDefaults',
      component: () => import('./pages/SingleClickDefaults'),
      meta: { type: 'admin' }
    },
    {
      path: 'group_profile_settings',
      name: 'GroupProfileSettings',
      component: () => import('./pages/GroupProfileSettings'),
      meta: { type: 'admin' }
    }
  ]
}
