import store from '@/store/store.js'

class MetricFormatter {
  constructor() {
    this.report = store.getters['multiMetrics']
  }

  formatAllElementReport() {
    return this.report.format
      ? this.prepareFormattedReport()
      : this.prepareUnFormattedReport()
  }

  prepareFormattedReport() {
    let list = []

    this.report.format.forEach(formatterElement => {
      this.addElementToList(formatterElement, list, 0)
    })

    return list
  }

  addElementToList(formatterElement, list, indent) {
    let matchingMetrics = this.getMatchingElements(formatterElement)

    matchingMetrics.forEach(matchingMetric => {
      matchingMetric.indent = indent
      list.push(matchingMetric)
    })

    if (formatterElement.children && formatterElement.children.length) {
      formatterElement.children.forEach(child => {
        this.addElementToList(child, list, indent + 1)
      })
    }
  }

  getMatchingElements(formatterElement) {
    return ['space', 'lineBreak'].includes(formatterElement.type)
      ? [{ hideGraph: true, type: formatterElement.type }]
      : formatterElement.type === 'graph'
      ? [
          {
            hideGraph: true,
            type: formatterElement.type,
            metrics: formatterElement.metrics
          }
        ]
      : formatterElement.type === 'header'
      ? [{ hideGraph: true, name: formatterElement.text, type: 'header' }]
      : this.report.metrics[0].metric_values.filter(
          mv => mv.element_id === formatterElement.id
        ) || []
  }

  prepareUnFormattedReport() {
    return this.report.metrics[0].metric_values.reduce((list, mv) => {
      list.push(mv)

      for (let i = mv.space; i >= 1; i--) {
        list.push({ hideGraph: true })
      }
      return list
    }, [])
  }

  formatTechnographicReport() {
    return this.report.format
      ? this.prepareFormattedTechnographicReport()
      : this.prepareUnFormattedTechnographicReport()
  }

  prepareFormattedTechnographicReport() {
    let services = []

    this.report.format.forEach(formatterElement => {
      this.addTechnographicToList(formatterElement, services, 0)
    })
    return services
  }

  addTechnographicToList(formatterElement, services, indent) {
    let matchingTechnographics = this.getMatchingElements(formatterElement)

    matchingTechnographics.forEach(matchingTechnographic => {
      services.push({
        name: matchingTechnographic.name,
        peer: matchingTechnographic.peer,
        metric_id: matchingTechnographic.metric_id,
        type: matchingTechnographic.type || 'service',
        indent: indent
      })

      if (
        !['space', 'header', 'lineBreak', 'graph'].includes(
          matchingTechnographic.type
        ) &&
        !['True', 'False', null].includes(matchingTechnographic.value5)
      ) {
        services.push({
          name: matchingTechnographic.value5,
          peer: matchingTechnographic.peer_vendor_ratio,
          type: 'vendor'
        })
      }
    })

    if (formatterElement.children && formatterElement.children.length) {
      formatterElement.children.forEach(child => {
        this.addTechnographicToList(child, services, indent + 1)
      })
    }
  }

  prepareUnFormattedTechnographicReport() {
    return this.report.metrics[0].metric_values.reduce((services, mv) => {
      services.push({
        name: mv.name,
        peer: mv.peer,
        metric_id: mv.metric_id,
        type: 'service'
      })

      if (!['True', 'False', null].includes(mv.value5)) {
        services.push({
          name: mv.value5,
          peer: mv.peer_vendor_ratio,
          type: 'vendor'
        })
      }
      services.push({ type: 'divider' })
      return services
    }, [])
  }

  formatReportForDownload() {
    if (this.report.format) {
      return this.prepareFormattedReport().filter(
        el => !['space', 'header', 'lineBreak', 'graph'].includes(el.type)
      )
    } else {
      return this.report.metrics[0].metric_values
    }
  }

  formatTechnographicReportForDownload() {
    if (this.report.format) {
      return this.prepareFormattedTechnographicReport().filter(
        el => !['space', 'header', 'lineBreak', 'graph'].includes(el.type)
      )
    } else {
      return this.report.metrics[0].metric_values
    }
  }

  // functions accessible outside class

  get downloadReportFormat() {
    return this.report && this.report.metrics
      ? this.formatReportForDownload()
      : []
  }

  get downloadTechnographicReportFormat() {
    return this.report && this.report.metrics
      ? this.formatTechnographicReportForDownload()
      : []
  }

  get formattedReport() {
    return this.report && this.report.metrics
      ? this.formatAllElementReport()
      : []
  }

  get formattedTechnographicReport() {
    return this.report && this.report.metrics
      ? this.formatTechnographicReport()
      : []
  }
}

export default MetricFormatter
