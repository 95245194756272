import * as getters from './ability.getters'
import * as mutations from './ability.mutations'

export default {
  namespaced: true,
  state: {
    userRole: 'Viewer',
    manageAdminBoard: false,
    manageSuperAdminBoard: false,
    managePartnerAdminBoard: false,
    editClientGroup: false,
    canPurchase: false,
    ui_modules: [],
    enabledModules: [],
    enabledPaths: [],
    showPremiumContentAlert: true,
    enterprisePermissions: null,
    activeClientPricingAvailable: true,
    currentUserGuide: null
  },
  mutations,
  getters
}
