export default {
  setUsers(state, users) {
    state.users = users
  },
  setGroups(state, groups) {
    state.groups = groups
  },
  resetReportAccessData(state) {
    state.users = []
    state.groups = []
  }
}
