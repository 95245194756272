import Vue from 'vue'
import axios from 'axios'

export default {
  get(subscriptionID) {
    return axios
      .get(`/api/subscriptions/${subscriptionID}`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  create(queryID, reportID, interval, format, source) {
    return axios
      .post(
        '/api/subscriptions',
        {
          advanced_query_id: queryID,
          report_id: reportID,
          schedule: interval,
          delivery_mode: 'app_link',
          delivery_format: format,
          subscription_source: source
        },
        {
          handleErrors: false
        }
      )
      .then(
        res => {
          return res.data
        },
        error => {
          if (error.response.status === 422) {
            Vue.toasted.show('Validation failed. Please recheck.', {
              icon: 'chain-broken',
              type: 'error'
            })
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
        }
      )
  },
  update(subscriptionID, subscriptionParams) {
    return axios
      .put(
        `/api/subscriptions/${subscriptionID}`,
        {
          subscription: subscriptionParams
        },
        {
          handleErrors: false
        }
      )
      .then(
        res => {
          return res.data
        },
        () => {
          Vue.toasted.show(
            'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
            {
              icon: 'chain-broken',
              type: 'error'
            }
          )
        }
      )
  },
  find(queryID, reportID) {
    return axios
      .get('/api/subscriptions/find', {
        params: {
          advanced_query_id: queryID,
          report_id: reportID
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  delete(subscriptionID) {
    return axios
      .delete(`/api/subscriptions/${subscriptionID}`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
