import axios from 'axios'

export default {
  institutions(query) {
    return axios
      .get('/api/fit_profile/institutions', {
        params: {
          query: query
        },
        handleErrors: true
      })
      .then(res => {
        return res.data.result
      })
  },
  solutions() {
    return axios
      .get('/api/fit_profile/solutions', {
        handleErrors: true
      })
      .then(res => {
        return res.data.result
      })
  },
  institutionSolution(fiID, solutionID) {
    return axios
      .get('/api/fit_profile/current_solution', {
        params: {
          institution_id: fiID,
          bank_service_id: solutionID
        },
        handleErrors: true
      })
      .then(res => {
        return res.data.result
      })
  },
  profileElements() {
    return axios
      .get('/api/fit_profile/profile_elements', {
        params: {},
        handleErrors: true
      })
      .then(res => {
        return res.data.result
      })
  },
  categoryElements(fiID, solutionID, productID, category) {
    return axios
      .get('/api/fit_profile/category', {
        params: {
          institution_id: fiID,
          bank_service_id: solutionID,
          product_id: productID,
          category: category
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  vendorProducts(fiID, limit) {
    return axios
      .get('/api/fit_profile/product_fit_ranking', {
        params: {
          institution_id: fiID,
          limit: limit
        },
        handleErrors: true
      })
      .then(res => {
        return res.data.result
      })
  },
  clientInstitutions(productID, limit) {
    return axios
      .get('/api/fit_profile/client_institutions', {
        params: {
          product_id: productID,
          limit: limit
        },
        handleErrors: true
      })
      .then(res => {
        return res.data.result
      })
  },
  xlsxDownload: params => {
    return axios
      .get('/api/fit_profile/download.xlsx', {
        params: {
          institution_id: params.fiID,
          bank_service_id: params.solutionID,
          product_id: params.productID,
          vendor_id: params.vendorID
        },
        handleErrors: true,
        responseType: 'blob'
      })
      .then(response => {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'vendor_fit.xlsx')
        document.body.appendChild(link)
        link.click()
      })
      .then(() => {})
  }
}
