<template>
  <PageCard pageName="FIN Apps" :iconClass="['fa', 'fa-home', 'fa-lg']">
    <template slot="page_content">
      <b-row>
        <b-col>
          <div>
            FI Navigator arms FinTech sales, marketing, and strategy
            professionals with insight on every U.S. financial institution’s
            strengths & weaknesses in profitability, growth, risk and customer
            offering, relative to peer institutions.
          </div>
          <div class="my-2">
            Instantly leverage FI Navigator’s data & analytics to:
            <ul>
              <li>
                Build laser-focused prospect lists to drive strong campaign
                results
              </li>
              <li>Engage as a trusted advisor with relevant recommendations</li>
              <li>
                Enrich your CRM data with unique insights on performance,
                offering or vendor relationships
              </li>
              <li>
                Evolve your strategy for product, segments or white space
                adjacencies
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
      <b-row class="about-row">
        <b-col cols="3" class="px-1">
          <b-card
            class="std-border mb-0 pb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">Marketing</div>
            <b-card-body class="p-0">
              <div>
                Build your most targeted prospect list ever, in under 90
                seconds.
              </div>
              <b-card-img
                src="../../../static/img/fin-advanced-query.png"
                class="rounded-0 mt-3 mb-5"
              />
              <!-- <div class="about-footer">
                <div class="about-footer-content">
                  <router-link
                    tag="button"
                    :to="{ path: '/technographics/about' }"
                    class="btn btn-secondary"
                    >View now</router-link
                  >
                </div>
              </div> -->
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="3" class="px-1">
          <b-card
            class="std-border mb-0  pb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">Sales</div>
            <b-card-body class="p-0">
              <div>
                Develop a comprehensive account plan on any institution —
                instantly.
              </div>
              <b-card-img
                src="../../../static/img/advisor.png"
                class="rounded-0 my-2"
              />
              <!-- <div class="about-footer">
                <div class="about-footer-content">
                  <router-link
                    tag="button"
                    :to="{ path: '/analytics/analytics/about' }"
                    class="btn btn-secondary"
                    >View now</router-link
                  >
                </div>
              </div> -->
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="3" class="px-1">
          <b-card
            class="std-border mb-0  pb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">CRM</div>
            <b-card-body class="p-0">
              <div>
                Easily enrich your CRM account data with FI performance,
                offering & other unique data.
              </div>
              <b-card-img
                src="../../../static/img/fin-query-output.png"
                class="rounded-0 mt-4 mb-5"
              />
              <!-- <div class="about-footer">
                <div class="about-footer-content">
                  <router-link
                    tag="button"
                    :to="{ path: '/crm_data/about' }"
                    class="btn btn-secondary"
                    >View now</router-link
                  >
                </div>
              </div> -->
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="3" class="px-1">
          <b-card
            class="std-border mb-0  pb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">Strategy</div>
            <b-card-body class="p-0">
              <div>
                Instantly calculate market size and market share for any FI
                segment by technology solution.
              </div>
              <b-card-img
                src="../../../static/img/market-share.png"
                class="rounded-0 my-2"
              />
              <!-- <div class="about-footer">
                <div class="about-footer-content">
                  <router-link
                    tag="button"
                    :to="{ path: '/reports/about' }"
                    class="btn btn-secondary"
                    >View now</router-link
                  >
                </div>
              </div> -->
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
    </template>
  </PageCard>
</template>

<script>
import PageCard from '@/modules/core/components/layouts/PageCard'

export default {
  name: 'AboutDashboard',
  components: {
    PageCard
  }
}
</script>

<style lang="scss" scoped>
.about-footer {
  margin-top: 0.5rem;
}
</style>
