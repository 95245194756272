<template>
  <div>
    <b-card-header header-class="aside-header pl-2">
      <strong>Report Options</strong>
      <span
        class="fa fa-times-circle fa-lg close-icon"
        @click="closeSettings"
      ></span>
    </b-card-header>

    <b-card>
      <h6 class="text-left mt-2 mb-3">View Options</h6>
      <div class="p-2">
        <b-form-group class="mb-4">
          <b-button-group>
            <b-button
              :variant="isMultiMetricView ? 'info' : 'secondary'"
              @click="setReportView('multi')"
            >
              Multi <br v-if="isCustomReport && !isTechnographicReport" />{{
                isTechnographicReport ? 'Service' : 'Metric'
              }}
            </b-button>
            <b-button
              :variant="isSingleMetricView ? 'info' : 'secondary'"
              @click="setReportView('single')"
            >
              Single <br v-if="isCustomReport && !isTechnographicReport" />{{
                isTechnographicReport ? 'Service' : 'Metric'
              }}
            </b-button>
            <b-button
              v-if="!isTechnographicReport"
              :variant="isPeriodView ? 'info' : 'secondary'"
              @click="setReportView('period')"
            >
              Period
            </b-button>
          </b-button-group>
        </b-form-group>
        <b-form-group class="mb-1" v-if="!isPeriodView">
          <b-row>
            <b-col cols="6">Show Peers:</b-col>
            <b-col cols="4">
              <c-switch
                type="text"
                variant="success"
                on="Yes"
                off="No"
                :pill="true"
                size="sm"
                :class="[
                  disableShowPeers ? 'disable' : '',
                  isTechnographicReport ? 'mb-4' : '',
                  'float-right'
                ]"
                :checked="showPeers"
                :disabled="disableShowPeers"
                @change="togglePeers($event)"
              />
            </b-col>
          </b-row>
        </b-form-group>

        <div v-if="!isTechnographicReport">
          <b-form-group v-if="isMultiMetricView">
            <b-row>
              <b-col cols="6">Show Graph:</b-col>
              <b-col cols="4">
                <c-switch
                  type="text"
                  variant="success"
                  on="Yes"
                  off="No"
                  :pill="true"
                  size="sm"
                  class="float-right"
                  :checked="showSparkGraph"
                  @change="toggleSparkGraph($event)"
                />
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group v-else-if="!isPeriodView" class="mb-4">
            <b-row>
              <b-col cols="6">Chart Type:</b-col>
              <b-col cols="6 pl-0">
                <b-form-select
                  v-model="metricChartType"
                  class="chart-type-select"
                  :disabled="isNonMetric"
                >
                  <option
                    v-for="(chart, index) in chartOptions"
                    :value="chart.id"
                    :key="index"
                    >{{ chart.type }}</option
                  >
                </b-form-select>
              </b-col>
            </b-row>
          </b-form-group>
          <b-form-group>
            <label for="fi_asset_group">Select Period Span:</label>
            <b-form-select :value="selected_span" @change="updateSpan">
              <option
                v-for="(span, index) in spans"
                :value="span.id"
                :key="index"
                >{{ span.name }}</option
              >
            </b-form-select>
          </b-form-group>
        </div>

        <b-form-group>
          <label for="fi_asset_group">Select Peer Group:</label>
          <b-form-select :value="selected_peer_group" @change="updatePeerGroup">
            <option
              v-for="(peer, index) in peer_groups"
              :value="peer.id"
              :key="index"
              >{{ peer.name }}</option
            >
          </b-form-select>
        </b-form-group>

        <b-form-group v-if="mergerEnabled && !isTechnographicReport">
          <b-row>
            <b-col cols="6" class="pr-0">Merger Restate:</b-col>
            <b-col cols="4">
              <c-switch
                on="Yes"
                off="No"
                type="text"
                variant="success"
                size="sm"
                :pill="true"
                class="float-right"
                :checked="mergerRestated"
                @change="updateSetId()"
              />
            </b-col>
          </b-row>
        </b-form-group>
        <b-row class="justify-content-center">
          <b-button variant="primary" class="rounded" @click="downloadAll">
            Download All
          </b-button>
        </b-row>
      </div>
    </b-card>

    <div v-if="isMultiMetricView" class="d-none">
      <hr />
      <b-card>
        <h6 class="text-left mt-2 mb-3">Customize Metrics</h6>
        <div class="p-2">
          <b-form-group>
            <b-row class="justify-content-center">
              <b-col cols="10" class="text-center">
                <b-button variant="info" class="fw-btn"> Edit Report </b-button>
              </b-col>
            </b-row>
          </b-form-group>

          <b-form-group>
            <b-row class="justify-content-center">
              <b-col cols="10" class="text-center">
                <b-button variant="success" class="fw-btn">
                  Save Current View
                </b-button>
              </b-col>
            </b-row>
          </b-form-group>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import cSwitch from '@/components/Switch'
import { mapGetters, mapMutations } from 'vuex'
import PageSettings from '@/mixins/PageSettings'
import customAPI from '@/modules/reporter/api/metrics.custom'

export default {
  name: 'c-aside',
  components: {
    cSwitch
  },
  mixins: [PageSettings],
  data() {
    return {
      value: null,
      reportViewOptions: [
        { id: 'multi', name: 'Multi-Metric' },
        { id: 'single', name: 'Single-Metric' },
        { id: 'period', name: 'Period' }
      ]
    }
  },
  computed: {
    ...mapGetters([
      'selectedInstitution',
      'isMultiMetricView',
      'isSingleMetricView',
      'isPeriodView',
      'reportView',
      'showPeers',
      'showSparkGraph',
      'spans',
      'peer_groups',
      'chartType',
      'metric_args',
      'selected_span',
      'setId',
      'selected_peer_group',
      'single_metrics',
      'multi_metrics',
      'isScoreMetric',
      'isCustomReport',
      'isTechnographicReport',
      'reportProgress',
      'report_token'
    ]),
    metricChartType: {
      get: function() {
        return this.chartType
      },
      set: function(value) {
        this.$store.commit('setChartType', value)
      }
    },
    disableShowPeers() {
      return (
        this.isSingleMetricPeerTable || this.isTechnographicSingleServiceView
      )
    },
    isSingleMetricPeerTable() {
      return this.chartType === 'peer' && !this.isMultiMetricView
    },
    isTechnographicSingleServiceView() {
      return this.isTechnographicReport && this.isSingleMetricView
    },
    isNonMetric() {
      return (
        this.single_metrics.selectedMetric &&
        this.single_metrics.selectedMetric
          .toString()
          .startsWith('non_metric_id:')
      )
    },
    mergerRestated() {
      return this.setId === 2
    },
    mergerEnabled() {
      return this.selectedInstitution.fi_type === 'bank'
    },
    chartOptions() {
      let options = [
        { id: 'bar', type: 'Bar Chart' },
        { id: 'line', type: 'Line Chart' },
        { id: 'peer', type: 'Peer Table' }
      ]
      if (this.isNonMetric) {
        this.$store.commit('setChartType', 'peer')
      }
      return options
    }
  },
  methods: {
    ...mapMutations(['toggleSet']),
    updateSpan(spanValue) {
      let viewType = this.isMultiMetricView ? 'multi_metrics' : 'single_metrics'
      if (this.reportView == 'period') {
        viewType = 'period_metrics'
        this.$store.commit(viewType, {
          selectedPeriod: null,
          metricsLoading: true
        })
      } else {
        this.$store.commit(viewType, { metricsLoading: true })
      }
      this.$store.commit('selected_span', spanValue)
      this.getMetricData().then(() => {
        this.$store.commit(viewType, { metricsLoading: false })
      })
    },
    updateSetId() {
      this.toggleSet()
      let viewType = this.isMultiMetricView ? 'multi_metrics' : 'single_metrics'
      this.$store.commit(viewType, { metricsLoading: true })
      this.getMetricData().then(() => {
        this.$store.commit(viewType, { metricsLoading: false })
      })
    },
    downloadAll() {
      customAPI.downloadAllData(this)
    },
    updatePeerGroup(peerGroupID) {
      let viewType = this.isMultiMetricView ? 'multi_metrics' : 'single_metrics'
      if (this.isCustomReport) {
        viewType = this.reportView + '_metrics'
      }
      this.$store.commit(viewType, { metricsLoading: true })
      this.$store.commit('setPeerGroupId', peerGroupID)
      this.createReportAndGetMetrics(peerGroupID)
    },
    setReportID(peerGroup) {
      // let peerGroup = this.peer_groups.find(pg => pg.id === peerGroupID)

      this.$store.commit('updateReportId', peerGroup.report_id)
    },
    createReportAndGetMetrics(peerGroupID) {
      this.$http
        .post(
          'api/reports',
          {
            bank_id: this.selectedInstitution.id,
            peer_group_id: peerGroupID
          },
          {
            handleErrors: true
          }
        )
        .then(res => {
          this.$store.commit('metric_args', res.data.reports.report_id)
          this.$store.commit('updateReportId', res.data.reports.report_id)
          this.$store.commit('setReportToken', res.data.reports.report_token)
          this.$store.commit(
            'setBreakPointProgress',
            res.data.reports.breakpoint_progress
          )
          this.$store.commit(
            'setReportProgress',
            res.data.reports.report_progress
          )
          if (res.data.reports.report_progress !== 100) {
            this.updateProgress()
          }
          this.customReports = res.data.reports.custom_reports
          this.navigatorReports = res.data.reports.finavigator_reports
          this.fiType = res.data.reports.fi_type
        })
        .then(() => {
          if (this.reportProgress === 100) this.getMetricData()
        })
        .then(() => {
          if (this.reportProgress === 100) {
            // let viewType = this.isMultiMetricView
            //   ? 'multi_metrics'
            //   : 'single_metrics'
            // if (this.isCustomReport) {
            //   viewType = this.reportView + '_metrics'
            // }
            //this.$store.commit(viewType, { metricsLoading: false })
            this.$ahoy.track('report_view', {
              fi_id: this.selectedInstitution.id,
              report_id: this.metric_args.reportId,
              report_type: 'FIN-R'
            })
          }
        })
    },
    updateProgress() {
      this.$store.dispatch('updateBreakPointProgress')
      this.$store.dispatch('updateReportProgressAndGetMetrics').then(() => {
        let viewType = this.isMultiMetricView
          ? 'multi_metrics'
          : 'single_metrics'
        if (this.isCustomReport) {
          viewType = this.reportView + '_metrics'
        }
        this.$store.commit(viewType, { metricsLoading: false })
        this.$ahoy.track('report_view', {
          fi_id: this.selectedInstitution.id,
          report_id: this.metric_args.reportId,
          report_type: 'FIN-R'
        })
      })
    },
    setReportView(view_name) {
      this.$store.dispatch('setReportView', view_name)
    },
    togglePeers: function(showPeer) {
      this.$store.dispatch('togglePeerGroup', showPeer)
    },
    toggleSparkGraph: function(showGraph) {
      this.$store.dispatch('toggleSparkGraph', showGraph)
    },
    getMetricData() {
      if (this.reportView == 'period') {
        return customAPI.getPeriodMetricData(this)
      }
      return this.isMultiMetricView
        ? customAPI.getMultiMetricData(this)
        : customAPI.getSingleMetricData(this)
    }
  }
}
</script>

<style lang="scss" scoped>
.card-body {
  padding: 0.5rem;
}

.disable {
  opacity: 0.5;
}
</style>

<style lang="scss">
.chart-type-select {
  &.custom-select {
    padding-right: 10px;
  }
}
</style>
