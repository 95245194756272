<template>
  <PageCard pageName="FIN Apps" :iconClass="['fa', 'fa-home', 'fa-lg']">
    <template slot="page_content">
      <b-row>
        <b-col>
          <div>
            FI Navigator provides financial institution executives with
            unprecedented data and analytics on every institution’s
            profitability, growth, risk, customer offering and technologies
            deployed.
          </div>
          <div class="my-3">
            Instantly leverage the data & analytics to:
          </div>
          <div>
            <ul>
              <li>Bolster your strategic planning model and process.</li>
              <li>Drive performance enhancement and benchmarking.</li>
              <li>Compare your retail and commercial offering to peers.</li>
              <li>
                Assess best technology vendor/products fits for your
                institution.
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
      <b-row class="about-row">
        <b-col cols="3" class="px-1">
          <b-card
            class="std-border mb-0 pb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">Strategy</div>
            <b-card-body class="p-0 flex-card-body ">
              <div class="content-div">
                Leverage unprecedented data & analytics to align your team’s
                resourcing to enhance franchise value.
              </div>
              <b-card-img
                src="../../../static/img/Strategy.png"
                class="rounded-0 my-2"
              />
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="3" class="px-1">
          <b-card
            class="std-border mb-0 pb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">Performance</div>
            <b-card-body class="p-0 flex-card-body ">
              <div class="content-div">
                Instantly generate strengths/weaknesses, primary themes and
                recommendations for performance enhancement.
              </div>
              <b-card-img
                src="../../../static/img/Performance.png"
                class="rounded-0 my-2"
              />
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="3" class="px-1">
          <b-card
            class="std-border mb-0 pb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">Offering</div>
            <b-card-body class="p-0 flex-card-body ">
              <div class="content-div">
                Easily compare your retail and commercial offering to peer
                institutions to identify next-most-likely solution additions and
                examine peer website messaging.
              </div>
              <b-card-img
                src="../../../static/img/Offering.png"
                class="rounded-0 my-2"
              />
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="3" class="px-1">
          <b-card
            class="std-border mb-0 pb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">Technology</div>
            <b-card-body class="p-0 flex-card-body ">
              <div class="content-div">
                Identify the best technology vendor-product fits for your
                institution.
              </div>
              <b-card-img
                src="../../../static/img/Technology.png"
                class="rounded-0 my-2"
              />
              <!-- <div class="about-footer">
                <div class="about-footer-content"></div>
              </div> -->
            </b-card-body>
          </b-card>
        </b-col>
      </b-row>
      <br />
      <!--
      <b-row class="about-row" style="justify-content: center;">
        <b-col cols="3" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">Customer Offering</div>
            <b-card-body class="p-0">
              <div>
                Identify your next-most-likely retail, business or operating
                solutions based on peer analytics.
              </div>
              <div class="about-footer">
                <div class="about-footer-content">
                  <router-link
                    tag="button"
                    :to="{ path: '/analytics/analytics/about' }"
                    class="btn btn-secondary"
                    >View now</router-link
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="3" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">Performance Planning</div>
            <b-card-body class="p-0">
              <div>
                Generate an instant consultative assessment of your
                institution’s opportunities.
              </div>
              <div class="about-footer">
                <div class="about-footer-content">
                  <router-link
                    tag="button"
                    :to="{ path: '/analytics/analytics/about' }"
                    class="btn btn-secondary"
                    >View now</router-link
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
        <b-col cols="3" class="px-1">
          <b-card
            class="std-border mb-0"
            header-bg-variant="success"
            header-text-variant="white"
          >
            <div slot="header">Performance Reporting</div>
            <b-card-body class="p-0">
              <div>Instantly create reports to match any meeting’s focus.</div>
              <div class="about-footer">
                <div class="about-footer-content">
                  <router-link
                    tag="button"
                    :to="{ path: '/analytics/analytics/about' }"
                    class="btn btn-secondary"
                    >View now</router-link
                  >
                </div>
              </div>
            </b-card-body>
          </b-card>
        </b-col>
      </b-row> -->
    </template>
  </PageCard>
</template>

<script>
import PageCard from '@/modules/core/components/layouts/PageCard'

export default {
  name: 'AboutDashboard',
  components: {
    PageCard
  }
}
</script>

<style lang="scss" scoped>
.about-footer {
  margin-top: 0.5rem;
}
.flex-card-body {
  display: flex;
  flex-direction: column;
  .content-div {
    flex-grow: 1;
  }
}
</style>
