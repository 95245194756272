import ahoy from 'ahoy.js'
import Vue from 'vue'

if (window.location.host === process.env.VUE_APP_ETHOS_URL_NAME) {
  ahoy.configure({
    withCredentials: true,
    crossDomain: true,
    urlPrefix:
      process.env.NODE_ENV === 'development'
        ? process.env.VUE_APP_ETHOS_API_URL
        : process.env.VUE_APP_ETHOS_AHOY_URL_NAME
  })
} else {
  ahoy.configure({
    withCredentials: true,
    crossDomain: true,
    urlPrefix:
      process.env.NODE_ENV === 'development'
        ? process.env.VUE_APP_API_URL
        : process.env.VUE_APP_URL_NAME
  })
}

Vue.prototype.$ahoy = ahoy
