// api
import downloadsAPI from '@/modules/core/api/downloads'
import digitalChannelsAPI from '@/api/finapps/digital.channels'
// utils
import _ from 'lodash'
import Excel from 'exceljs'
import downloadStyle from '@/mixins/ExcelDownloadStyle'

export default {
  loadReportData({ state, getters, commit, dispatch }) {
    commit('setReportLoadingStatus', true)
    dispatch('resetTopVendorsIfZero')
    dispatch('resetTopFeaturesIfZero')
    return digitalChannelsAPI
      .reportData(state.selectedReport, getters.selectedReportFilters)
      .then(res => {
        if (res.report_id === state.selectedReport.id) {
          dispatch('prepareReportData', res.report_data)
          dispatch('setTopVendorsData', res.total_vendors)
          commit('setReportHeaders', res.headers)
          commit('setDataFormat', res.data_format)
          commit('setSelectedReportFilters', res.filters)

          commit('setReportLoadingStatus', false)
        }
      })
  },
  resetTopVendorsIfZero({ state, commit }) {
    if (isNaN(state.topVendors) || parseInt(state.topVendors) === 0)
      commit('resetTopVendors')
  },
  resetTopFeaturesIfZero({ state, commit }) {
    if (isNaN(state.topFeatures) || parseInt(state.topFeatures) === 0)
      commit('resetTopFeatures')
  },
  prepareReportData({ commit }, reportData) {
    let reportTotal = _.remove(reportData, rd => {
      return rd.vendor_name === 'Grand Total'
    })[0]

    let reportOtherData = _.remove(reportData, rd => {
      return rd.vendor_name === 'Others'
    })[0]

    if (reportTotal && reportTotal.product_name === '0')
      reportTotal.product_name = ''
    if (reportOtherData && reportOtherData.product_name === '-1')
      reportOtherData.product_name = ''
    commit('setReportTotal', reportTotal)
    commit('setReportOtherData', reportOtherData)
    commit('setReportData', reportData)
  },
  setTopVendorsData({ state, getters, commit }, totalVendors) {
    let validFilters = getters.getValidFilters
    if (validFilters && validFilters.some(rf => rf.name === 'Top Vendors')) {
      let receivedVendors = state.reportData ? state.reportData.length : 0
      if (receivedVendors >= totalVendors) {
        commit('updateTopVendors', totalVendors)
        commit('setShowAllVendors', true)
      } else if (state.showAllVendors && receivedVendors < totalVendors) {
        commit('setShowAllVendors', false)
        commit('updateTopVendors', receivedVendors)
      } else if (receivedVendors < state.topVendors)
        commit('updateTopVendors', receivedVendors)

      commit('setTopVendorsMax', totalVendors)
    }
  },
  downloadReport({ state }) {
    const workbook = new Excel.Workbook()
    const sheet = workbook.addWorksheet(
      state.selectedReport.name.replace('/', '')
    )
    sheet.addRow([
      'Copyright ' +
        new Date().getFullYear() +
        " FI Navigator Corporation. Any data, files, or documents downloaded or obtained from FI Navigator is a component of the Licensee's Licensed Materials and deemed as Confidential Information. Licensee may only distribute Licensed Materials internally, either in original or modified form. Any other form of distribution is not permitted without the prior written consent of, and with express attribution to, FI Navigator Corporation. Unauthorized distribution of copyrighted material by any intentional or unintentional recipient is subject to civil and criminal liabilities. NOTICE: FI Navigator has embedded data fingerprinting unique to each data export and components of the data itself to source any unauthorized distribution."
    ])
    sheet.addRow()
    const nameRow = sheet.addRow([state.downloadDCReportData.name])
    sheet.mergeCells(nameRow.number, 1, nameRow.number, 10)
    const subTitleRow = sheet.addRow([state.downloadDCReportData.subTitle])
    sheet.mergeCells(subTitleRow.number, 1, subTitleRow.number, 10)
    const filterTextRow = sheet.addRow([state.downloadDCReportData.filterText])
    sheet.mergeCells(filterTextRow.number, 1, filterTextRow.number, 10)
    sheet.addRow()
    const xlsx_formats = {
      P0: '#,##0%',
      P1: '#,##0.0%',
      P2: '#,##0.00%',
      N2: '#,##0.00',
      N1: '#,##0.0',
      C2: '$#,###0.00',
      C0: '#,###0.00000',
      N0: '#,###0.00000',
      '#,###': '#,###0',
      '$#,###': '$#,###0',
      '$#,###/M': '$#,###0',
      '$#,###0.0/M': '$#,###0.0',
      '$#,###0.0': '$#,###0.0'
    }
    if (state.downloadDCReportData.yearHeaders.length > 0) {
      const yearHeaders = state.downloadDCReportData.yearHeaders
      const row = sheet.addRow()
      let cellIndex = 2
      for (let col of yearHeaders) {
        if (col.value !== '') {
          const cell = row.getCell(cellIndex)
          cell.value = col.value
          cell.alignment = { horizontal: 'center' }
          sheet.mergeCells(
            row.number,
            cellIndex,
            row.number,
            cellIndex + col.colSpan - 1
          )
        }
        cellIndex = cellIndex + col.colSpan
      }
    }
    state.downloadDCReportData.data.forEach(rowData => {
      let reportData = rowData.map(rd => {
        return rd.format ? (rd.value || rd.value === 0 ? +rd.value : 'NA') : rd
      })
      let sheetRow = sheet.addRow(reportData)

      sheetRow.eachCell((cell, cellIndex) => {
        let rowCellData = rowData[cellIndex - 1]
        if (rowCellData.format) {
          if (rowCellData.format.alignment) {
            cell.value = rowCellData.value
            cell.alignment = { horizontal: rowCellData.format.alignment }
          } else {
            cell.numFmt = xlsx_formats[rowCellData.format]
            cell.alignment = { horizontal: 'right' }
          }
        } else if (
          state.selectedReport.report_type === 'Institution Analytics' &&
          cellIndex !== 1
        ) {
          cell.alignment = { horizontal: 'right' }
        }
      })
    })
    //Set column width, excluding first 5 rows
    sheet.columns.forEach(function(column) {
      var maxLength = 0

      column['eachCell'](function(cell, index) {
        if (index > 5) {
          var columnLength = cell.value ? cell.value.length : 1
          if (columnLength > maxLength) {
            maxLength = columnLength + 5
          }
        }
      })
      column.width = maxLength < 10 ? 10 : maxLength
    })
    //Highlight rows
    downloadStyle.yearHeaderStyle(sheet, 3) // Report name
    if (state.downloadDCReportData.yearHeaders.length > 0) {
      downloadStyle.yearHeaderStyle(sheet, 7) // Year header
      downloadStyle.highlightedRowStyle(sheet, 8) // Report header
    } else {
      downloadStyle.highlightedRowStyle(sheet, 7) // Report header
    }
    if (state.selectedReport.name === 'Features')
      downloadStyle.highlightedRowStyle(sheet, 9)
    else if (
      ![
        'Feature Leaderboard',
        'Feature Leaderboard Lift',
        'Feature Select Enroll Lift',
        'Feature Select Sat Lift',
        'Churn Enrollment'
      ].includes(state.selectedReport.name)
    )
      downloadStyle.highlightedRowStyle(sheet, sheet.rowCount) // Report Footer
    downloadsAPI.downloadAsExcel(workbook, state.downloadDCReportData.name)
  }
}
