// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import 'babel-polyfill'
import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import Gravatar from 'vue-gravatar'
import Vue2Filters from 'vue2-filters'
import VueMatchHeights from 'vue-match-heights'
import VueContentPlaceholders from 'vue-content-placeholders'
import Transitions from 'vue2-transitions'
import App from './App'
import router from './router'
import store from './store/store'
import VueWait from 'vue-wait'
import axios from 'axios'
import VueAsyncManager from 'vue-async-manager'

import './mixins/filters'
import './mixins/directives'
import './plugins/toastedConfig'
import './plugins/progressBarConfig'
import './plugins/axiosConfig'
import './plugins/firebase.config'
import './plugins/honeybadgerConfig'
import './plugins/googleMapConfig'
import './plugins/highcharts.config'
import './plugins/events'
import apolloProvider from './plugins/apollo.client.config'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import './plugins/ahoy.config'
import './plugins/dialogConfig'
import './plugins/idle.config'

Vue.use(BootstrapVue)
Vue.use(Vuelidate)
Vue.use(Vue2Filters)
Vue.use(VueMatchHeights, {})
Vue.use(VueContentPlaceholders)
Vue.use(Transitions)
Vue.use(VueWait)
Vue.use(VueAsyncManager)

Vue.prototype.$http = axios

// TODO: This is a temporary implementation
Vue.component('v-gravatar', Gravatar)

/* Application initialize */
const vueApp = new Vue({
  router,
  store,
  apolloProvider,
  wait: new VueWait(),
  render: h => h(App)
}).$mount('#app')

if (process.env.NODE_ENV === 'production') {
  Vue.config.devtools = false
  Vue.config.debug = false
  Vue.config.silent = true
}

export default { vueApp }
