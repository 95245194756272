import axios from 'axios'

const downloadResult = (response, fileName) => {
  const url = window.URL.createObjectURL(new Blob([response.data]))
  const link = document.createElement('a')
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
}

const downloadCSV = (csvData, fileName) => {
  const link = document.createElement('a')
  const url = 'data:text/csv;charset=utf-8,' + encodeURI(csvData)
  link.href = url
  link.setAttribute('download', fileName)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

const downloadWorkbook = (workbook, fileName) => {
  workbook.xlsx.writeBuffer().then(function(data) {
    var blob = new Blob([data], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    })
    var url = window.URL.createObjectURL(blob)
    var anchor = document.createElement('a')

    anchor.href = url
    anchor.download = fileName
    anchor.click()

    window.URL.revokeObjectURL(url)
  })
}

export default {
  xlsxDownload: params => {
    return axios
      .get('/downloads/custom_profile.xlsx', {
        params: {
          institution_id: params.fiId,
          market_share_fi_type: params.marketShareFIType
        },
        handleErrors: true,
        responseType: 'blob'
      })
      .then(response => {
        downloadResult(response, 'profile.xlsx')
      })
      .then(() => {})
  },
  contactsDownload: params => {
    return axios
      .get('/api/advisors/contacts_download.xlsx', {
        params: {
          institution_id: params.fiId,
          has_email: params.hasEmail,
          titles: params.titles,
          roles: params.roles,
          filter: params.filter
        },
        handleErrors: true,
        responseType: 'blob'
      })
      .then(response => {
        downloadResult(response, 'contacts.xlsx')
      })
  },
  downloadResult: downloadResult,
  downloadCSVResult: downloadCSV,
  downloadAsExcel: downloadWorkbook
}
