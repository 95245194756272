export default {
  setSelectedGroups(state, groups) {
    state.selectedGroups = groups
  },
  setSelectedReport(state, report) {
    state.selectedReport = report
  },
  setSelectedSpan(state, span) {
    state.selectedSpan = span
  },
  setSelectedScope(state, scope) {
    state.selectedScope = scope
  },
  setSelectedSegment(state, segment) {
    state.selectedSegment = segment
  },
  setMetricMode(state, payload) {
    state.metricMode = payload
  },
  setShowSegments(state, payload) {
    state.showSegment = payload
  },
  setShowTotals(state, payload) {
    state.showTotals = payload
  },
  setShowGraph(state, payload) {
    state.showGraph = payload
  },
  setMergerRestated(state, payload) {
    state.mergerRestated = payload
  },
  resetMergerRestated(state) {
    state.mergerRestated = false
  },
  setFIGroups(state, payload) {
    state.fiGroups = payload
  },
  setMetrics(state, payload) {
    state.metrics = payload.map(metric => {
      metric.type = 'metrics'
      return metric
    })
  },
  setFinancialStatements(state, payload) {
    state.financialStatements = payload.map(statement => {
      statement.type = 'statements'
      return statement
    })
  },
  setPeriodItems(state, payload) {
    let periodItems = payload.reduce((items, pi) => {
      items.push(
        pi.items.map(i => {
          i.name = i.statement_name
          i.type = pi.type
          return i
        })
      )
      return items
    }, [])
    state.periodItems = periodItems.flatten()
  },
  setPeriods(state, payload) {
    state.periods = payload
  },
  setSegmentDetails(state, payload) {
    state.segmentDetails = payload
  },
  setSegmentSets(state, payload) {
    state.segmentSets = payload
  },
  setTableData(state, payload) {
    state.tableData = payload
  },
  setBankServices(state, payload) {
    state.bankServices = payload
  },
  setBankServicesData(state, payload) {
    state.bankServicesData = payload
  },
  setSelectedMetric(state, payload) {
    state.selectedMetric = payload
  },
  setSelectedPeriod(state, period) {
    state.selectedPeriod = period
  },
  setLatestPeriod(state, payload) {
    state.latestPeriod = payload
  },
  setEnableTotal(state, payload) {
    state.enableTotal = payload
  },
  setEnableGraph(state, payload) {
    state.enableGraph = payload
  },
  setDisplayType(state, payload) {
    state.displayType = payload
  },
  setSingleMetricOptions(state) {
    let mappedItems = state.periodItems.map(pi => {
      return {
        id: pi.id,
        name: pi.statement_name,
        type: pi.type,
        format: pi.format
      }
    })
    let bankServicesMetrics = state.bankServices.map(bs => {
      return { id: bs.id, name: bs.name, type: 'bankService', format: 'P2' }
    })
    state.singleMetricOptions = [...mappedItems, ...bankServicesMetrics]
  },
  setPrintWithSingleMetricsData(state, payload) {
    state.printWithSingleMetricsData = payload
  },
  setDownloadReportData(state, payload) {
    state.downloadReportData = payload
  },
  setSegmentRules(state, payload) {
    let queryTreeRules = state.treeRules.map(rule => {
      return {
        id: rule.id,
        label: rule.label,
        component: null,
        operators: [],
        children: []
      }
    })

    state.segmentRules = [...payload, ...queryTreeRules]
  },
  setTotalFICount(state, payload) {
    state.totalFICount = payload
  },
  addQueryToGroup(state, query) {
    state.selectedGroups.push(query)
  },
  setSegmentValidity(state, payload) {
    state.validSegment = payload
  },
  setSegmentSetValidity(state, payload) {
    state.validSet = payload
  },
  setSegmentSetDetailsValidity(state, payload) {
    state.validSetDetails = payload
  },
  setReportOutdatedStatus(state, payload) {
    state.reportOutdated = payload
  },
  setReportRerunStatus(state, payload) {
    state.rerunReport = payload
  },
  updateSelectedGroup(state, query) {
    let selectedQueryIndex = state.selectedGroups.findIndex(
      sg => sg.id === query.id
    )
    state.selectedGroups.splice(selectedQueryIndex, 1, query)
  },
  removeQueryFromGroup(state, query) {
    let queryIndex = state.selectedGroups.findIndex(sg => sg.id === query.id)
    state.selectedGroups.splice(queryIndex, 1)
  }
}
