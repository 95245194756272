import * as getters from './admin.getters'
import * as mutations from './admin.mutations'
import actions from './admin.actions'

export default {
  namespaced: true,
  state: {
    selectedUser: {},
    selectedGroup: {}
  },
  mutations,
  actions,
  getters
}
