import store from '@/store/store.js'

class ReportTransformer {
  constructor(addedElements) {
    this.reportElements = store.getters['ReportWriter/customElements']
    this.reportElsFormattedForSave = this.transformForSave(addedElements)
    this.customizedElements =
      store.getters['ReportWriter/customizedReportElements']
  }

  transformForSave(addedElements) {
    let elements = addedElements || this.reportElements
    let globalSettings = store.getters['ReportWriter/globalSettings']
    let globalFinMetrics = store.getters['ReportWriter/globalFinMetrics']
    let globalFinStatements = store.getters['ReportWriter/globalFinStatements']
    let globalTechnographics =
      store.getters['ReportWriter/globalTechnographics']

    return elements.map(re => {
      let el = {
        element_id: re.id,
        element_type: re.type
      }
      if (re.set) {
        if (
          ['metrics', 'common_metric'].includes(re.type) &&
          globalSettings['Financial Metrics'] == 'true'
        ) {
          el.element_properties = {
            set: globalFinMetrics.set.id,
            span: globalFinMetrics.span.id,
            scope: globalFinMetrics.scope.id,
            element_property: re.property,
            is_global_setting: true
          }
        } else if (
          ['financial_statements', 'common_financial_statements'].includes(
            re.type
          ) &&
          globalSettings['Financial Statements'] == 'true'
        ) {
          el.element_properties = {
            set: globalFinStatements.set.id,
            span: globalFinStatements.span.id,
            scope: globalFinStatements.scope.id,
            element_property: re.property,
            is_global_setting: true
          }
        } else {
          el.element_properties = {
            set: re.set,
            span: re.span,
            scope: re.scope,
            element_property: re.property
          }
        }
      } else if (re.type === 'fi_contacts') {
        el.element_properties = {
          roles: re.roles,
          titles: re.titles,
          has_email: re.email,
          exclude_fi: re.excludeFI,
          element_property: re.property
        }
      } else if (re.type === 'vendor_fit_static') {
        el.element_properties = {
          leading: re.leading,
          element_property: re.property
        }
      } else if (re.type === 'firmographics' && re.actionTaken) {
        el.element_properties = {
          action_taken: re.actionTaken,
          element_option: re.elementOption,
          output_type: re.outputType,
          name: re.name,
          element_property: re.property
        }
      } else if (re.type === 'mortgage_lending') {
        el.element_properties = {
          rule_id: re.ruleId,
          action_taken: re.actionTaken,
          output_type: re.outputType,
          name: re.name,
          element_property: re.property,
          filter_on: re.filterOn,
          filter_operator: re.filterOperator,
          filters: this.transformFilterElements(re.filters)
        }
      } else if (
        re.type === 'technographics' &&
        globalSettings['Technographics'] == 'true'
      ) {
        el.element_property = globalTechnographics.property.map(prop => prop.id)
        el.is_global_setting = true
      } else {
        el.element_property = re.property
      }

      return {
        id: re.seqID,
        element: [el]
      }
    })
  }
  transformFilterElements(reportFilters) {
    let filtersElement = []
    reportFilters &&
      reportFilters.forEach(fe => {
        let e = {
          id: fe.childOperand ? fe.id : fe.filterObjId,
          element_id: fe.childOperand ? fe.childOperand.id : fe.elementID,
          element_name: fe.childOperand
            ? fe.childOperand.label
            : fe.elementName,
          filter_id: fe.filterId,
          element_type: fe.type,
          element_property: fe.childOperand
            ? fe.childOperand.property
            : fe.property,
          rule_id: fe.ruleId,
          operator: fe.operator,
          value: fe.value
        }
        filtersElement.push(e)
      })
    return filtersElement
  }
  basicCustomizedElFormat() {
    return this.reportElsFormattedForSave.map(re => {
      return {
        id: re.id,
        type: 'element'
      }
    })
  }

  formatCustomizedElToSave(customizedEl) {
    let el = {
      id: customizedEl.id,
      type: customizedEl.type
    }

    if (customizedEl.type === 'header') {
      el.text = customizedEl.text
    }

    if (customizedEl.type === 'graph') {
      el.metrics = customizedEl.data.metrics.map(m => {
        return { id: m.id, type: m.type }
      })
    }

    if (customizedEl.children) {
      el.children = []

      customizedEl.children.forEach(elChild =>
        el.children.push(this.formatCustomizedElToSave(elChild))
      )
    }

    return el
  }

  get formatCustomizedElements() {
    if (this.customizedElements.length) {
      return this.customizedElements.map(ce => {
        return this.formatCustomizedElToSave(ce)
      })
    } else {
      return this.basicCustomizedElFormat()
    }
  }

  get elementsToSave() {
    return { elements: this.reportElsFormattedForSave }
  }
}

export default ReportTransformer
