// global
import Vue from 'vue'
// general utilities
import deepClone from '@/utilities.js'
//api
import metricsAPI from '@/api/finapps/metrics'
import reportWriterElementsAPI from '@/api/finapps/report_writer_elements'
import financialStatementsAPI from '@/api/finapps/financial_statements'
import metricTreeRelationshipsAPI from '@/api/finapps/metric_tree_relationships'
import commonMetricsMappingsAPI from '@/api/finapps/common_metrics_mappings'
import selfServeReportsAPI from '@/api/finapps/self_serve_reports'
//module utilities
import { formattedQueryElementsToCopyToReporter } from '../../advanced_query/helpers/utilities.js'

const validQueryElements = (queryElements, defaultOptions) => {
  let defaultElements = defaultOptions.map(deo => deo.property)

  return queryElements.filter(qe => !defaultElements.includes(qe.property))
}

const collectStatementIDs = (statement, statementIDsSet) => {
  if (statement.children && statement.children.length) {
    if (statement.element_id) {
      statementIDsSet.add(statement.element_id)
    }
    statement.children.forEach(child =>
      collectStatementIDs(child, statementIDsSet)
    )
  } else if (statement.tree) {
    collectStatementIDs(statement.tree, statementIDsSet)
  } else {
    if (statement.element_id) {
      statementIDsSet.add(statement.element_id)
    }
  }
}

export default {
  removeDuplicateCustomElements({ commit }, queryElements) {
    commit('removeDuplicateElements', queryElements)
  },

  resetElementsAddedInPricing({ commit, state }) {
    commit('setElementsAddedInPricing', deepClone(state.reportElements))
  },

  finQueryElementsCount({ state, rootState }) {
    return (
      state.defaultOptions.length +
      state.reportElements.length +
      validQueryElements(
        rootState.AdvancedQuery.queryElements,
        state.defaultOptions
      ).length
    )
  },

  fetchElementsInfo({ state, commit }) {
    if (state.elementsInfo.length) {
      return Promise.resolve()
    }

    return reportWriterElementsAPI.info().then(res => {
      commit('setElementsInfo', res)
    })
  },

  fetchFirmographicMetrics({ state, commit }) {
    if (
      state.firmographicMetrics.length &&
      state.firmographicCommonMetrics.length
    ) {
      return Promise.resolve()
    }

    return metricsAPI.firmographicMetrics().then(res => {
      commit('setFirmographicMetrics', res.metrics)
      commit('setFirmographicCommonMetrics', res.common_metric)
    })
  },

  fetchFinancialStatements({ state, commit }) {
    if (
      state.financialStatementsData.length &&
      state.financialCommonStatementsData.length
    ) {
      return Promise.resolve()
    }

    return financialStatementsAPI.all().then(res => {
      commit('setFinancialCommonStatementsData', res.common_statements)
      commit('setFinancialStatementsData', res.statements)
    })
  },

  fetchMetricsData({ state, commit }) {
    if (Object.keys(state.metricTreeData).length) {
      return Promise.resolve()
    }

    return metricTreeRelationshipsAPI.treeRelationships().then(res => {
      commit('setMetricTreeData', res.relationships)
    })
  },

  fetchCommonMetricsData({ state, commit }) {
    if (state.commonMetricTreeData.length) {
      return Promise.resolve()
    }

    return commonMetricsMappingsAPI.metrics().then(res => {
      commit('setCommonMetricTreeData', res.common_metrics)
    })
  },

  fetchAllMetricAndStatementIDs({ state, dispatch, commit }) {
    if (Object.keys(state.allMetricAndStatementIDs).length) {
      return Promise.resolve()
    } else {
      return Promise.all([
        dispatch('fetchFirmographicMetrics'),
        dispatch('fetchFinancialStatements'),
        dispatch('fetchMetricsData'),
        dispatch('fetchCommonMetricsData')
      ]).then(() => {
        let metricIDs = new Set()
        let commonMetricIDs = new Set()
        let statementIDs = new Set()
        let commonStatementIDs = new Set()

        state.firmographicMetrics.forEach(metric => metricIDs.add(metric.id))
        state.firmographicCommonMetrics.forEach(cm =>
          commonMetricIDs.add(cm.id)
        )
        state.commonMetricTreeData.forEach(cm => commonMetricIDs.add(cm.id))

        state.metricTreeData[1].forEach(m => metricIDs.add(m.metric_id))
        state.metricTreeData[2].forEach(m => metricIDs.add(m.metric_id))
        state.metricTreeData[3].forEach(m => metricIDs.add(m.metric_id))

        state.financialStatementsData.forEach(fs =>
          collectStatementIDs(fs, statementIDs)
        )
        state.financialCommonStatementsData.forEach(fcs =>
          collectStatementIDs(fcs, commonStatementIDs)
        )

        commit('setAllMetricAndStatementIDs', {
          metrics: metricIDs,
          common_metric: commonMetricIDs,
          financial_statements: statementIDs,
          common_financial_statements: commonStatementIDs
        })
      })
    }
  },
  saveReport({ state, dispatch }) {
    // need to fix this - simply checking length is not sufficient
    if (state.elementsAddedInPricing.length !== state.reportElements.length) {
      dispatch('AdvancedQuery/runReport', null, { root: true }).then(() => {
        dispatch('createOrUpdateReport')
      })
    } else {
      dispatch('createOrUpdateReport')
    }
  },
  createOrUpdateReport({ state, dispatch }) {
    if (
      state.selectedReport.id &&
      state.selectedReport.name.trim() === state.selectedReportNameAsInDB.trim()
    ) {
      dispatch('updateReport')
    } else {
      dispatch('createReport')
    }
  },
  createReport({ state, commit, dispatch }) {
    let customElements = [
      ...formattedQueryElementsToCopyToReporter(),
      ...state.reportElements
    ]

    return selfServeReportsAPI
      .create(
        state.selectedReport.name,
        state.selectedReport.description,
        state.selectedReport.group_ids,
        state.selectedReport.fi_type,
        customElements
      )
      .then(res => {
        if (res) {
          commit('setUserCustomReports', [])
          commit('setCustomReport', res.report)
          commit('setReportElements', deepClone(customElements))
          commit('setReportActualFIType', res.report.fi_type)
          commit('setSavedReportElements', deepClone(customElements))
          dispatch('AdvancedQuery/fetchSubscription', null, { root: true })

          Vue.toasted.show('Report successfully created.', {
            icon: 'user-circle',
            type: 'success'
          })
        }
      })
  },
  updateReport({ state, commit }) {
    let customElements = [
      ...formattedQueryElementsToCopyToReporter(),
      ...state.reportElements
    ]
    return selfServeReportsAPI
      .update(
        state.selectedReport.id,
        state.selectedReport.name,
        state.selectedReport.description,
        state.selectedReport.group_ids,
        state.selectedReport.fi_type,
        customElements
      )
      .then(res => {
        if (res) {
          commit('setUserCustomReports', [])
          commit('setCustomReport', res.report)
          commit('setReportElements', deepClone(customElements))
          commit('setReportActualFIType', res.report.fi_type)
          commit('setSavedReportElements', deepClone(customElements))
          Vue.toasted.show('Report successfully updated.', {
            icon: 'user-circle',
            type: 'success'
          })
        }
      })
  }
}
