export default {
  path: '/fi_prospect/vendor_fit',
  name: 'VendorFit',
  meta: { requiredAuth: true },
  redirect: '/fi_prospect/vendor_fit/select_institution',
  component: () => import('./pages/VendorFit'),
  children: [
    {
      path: 'select_institution',
      name: 'SelectFitInstitution',
      component: () => import('./pages/SelectInstitution'),
      meta: { type: 'vendor_fit' }
    },
    {
      path: 'predictions',
      name: 'FitPredictions',
      component: () => import('./pages/FitPredictions'),
      meta: { type: 'vendor_fit' }
    }
  ]
}
