import Vue from 'vue'
import deepClone from '@/utilities.js'

const removeItem = (element, matchingElement) => {
  if (element.children && element.children.length) {
    let targetIndex = element.children.findIndex(
      ce => ce.id === matchingElement.seqID
    )

    if (targetIndex !== -1) {
      let targetElement = element.children[targetIndex]

      element.children.splice(targetIndex, 1)
      if (targetElement.children) {
        element.children.splice(targetIndex, 0, ...targetElement.children)
      }
    } else {
      element.children.forEach(ce => removeItem(ce, matchingElement))
    }
  }
}

export default {
  setCustomReport(state, payload) {
    state.selectedReport = payload
    state.selectedReportNameAsInDB = payload.name
  },
  setMetricMenuData(state, payload) {
    state.metricMenuData = payload
  },
  setMetricTreeData(state, payload) {
    state.metricTreeData = payload
  },
  setFinancialStatementsData(state, payload) {
    state.financialStatementsData = payload
  },
  setFinancialCommonStatementsData(state, payload) {
    state.financialCommonStatementsData = payload
  },
  addReportElement(state, element) {
    if (
      !state.reportElements.find(
        re => re.id == element.id && re.name == element.name
      )
    )
      state.reportElements.push(element)
  },
  addCustomizedElement(state, element) {
    let el = {
      id: element.seqID,
      type: 'element',
      text: element.name,
      data: element,
      children: []
    }
    if (
      !state.customizedReportElements.find(
        re => re.data.id == el.data.id && re.text == el.text
      )
    ) {
      state.customizedReportElements.push(el)
    }
  },
  updateCustomizedElements(state, elements) {
    state.customizedReportElements = elements
  },
  removeReportElement(state, payload) {
    let elementIndex = state.reportElements
      .map(re => `${re.id}_${re.type}`)
      .indexOf(`${payload.id}_${payload.type}`)
    state.reportElements.splice(elementIndex, 1)
  },
  removeElementByProperty(state, payload) {
    let elementIndex = state.reportElements
      .map(re => `${re.type}_${re.property}`)
      .indexOf(`${payload.type}_${payload.property}`)
    state.reportElements.splice(elementIndex, 1)
  },
  removeReportElementByName(state, payload) {
    let elementIndex = state.reportElements
      .map(re => `${re.type}_${re.name}`)
      .indexOf(`${payload.type}_${payload.name}`)
    state.reportElements.splice(elementIndex, 1)
  },
  removeCustomizedElement(state, payload) {
    let matchingReportElement
    let elementsTree = { children: deepClone(state.customizedReportElements) }

    if (payload.id) {
      matchingReportElement = state.reportElements.find(
        re => re.id === payload.id && re.type === payload.type
      )
    } else if (payload.name) {
      matchingReportElement = state.reportElements.find(
        re => re.name === payload.name && re.type === payload.type
      )
    } else {
      matchingReportElement = state.reportElements.find(
        re => re.property === payload.property && re.type === payload.type
      )
    }

    removeItem(elementsTree, matchingReportElement)
    state.customizedReportElements = elementsTree.children
  },
  removeMetricFromGraphEl(state, payload) {
    let graphEls = state.customizedReportElements.filter(
      ce => ce.type === 'graph'
    )
    graphEls.forEach(ge => {
      if (ge.data.metrics) {
        let removedMetricIndex = ge.data.metrics.findIndex(
          m => m.id === payload.id && m.type === payload.type
        )
        ge.data.metrics.splice(removedMetricIndex, 1)
      }
    })
  },
  updateReportElement(state, payload) {
    let elementIndex = state.reportElements.findIndex(
      el => el.id === payload.elementId && el.type === payload.type
    )
    let element = state.reportElements[elementIndex]
    let updatedElement = Object.assign({}, element, {
      [payload.property]: payload.id
    })

    Vue.set(state.reportElements, elementIndex, updatedElement)
  },
  updateReportElementByProperty(state, payload) {
    let elementIndex = state.reportElements
      .map(re => `${re.type}_${re.property}`)
      .indexOf(`${payload.type}_${payload.property}`)
    let element = state.reportElements[elementIndex]
    let updatedElement = Object.assign({}, element, {
      email: payload.email,
      roles: payload.roles,
      titles: payload.titles,
      excludeFI: payload.excludeFI
    })
    Vue.set(state.reportElements, elementIndex, updatedElement)
  },
  setBankServices(state, payload) {
    state.bankServices = payload
  },
  setVendorProducts(state, payload) {
    state.vendorProducts = payload
  },
  setReportElements(state, payload) {
    state.reportElements = payload
  },
  setOutputView(state, payload) {
    state.showOutput = payload
  },
  setReportFIType(state, payload) {
    state.selectedReport.fi_type = payload || 'both'
  },
  setReportGroupIDs(state, payload) {
    Vue.set(state.selectedReport, 'group_ids', payload)
  },
  setAllMetricAndStatementIDs(state, payload) {
    state.allMetricAndStatementIDs = payload
  },
  setElementSequenceID(state, payload) {
    state.elementSequenceID = payload
  },
  updateSequenceID(state) {
    state.elementSequenceID = state.elementSequenceID + 1
  },
  clearSelectedReport(state) {
    state.selectedReport = {
      id: null,
      name: null,
      group_ids: [],
      description: null,
      report_elements: null,
      element_format: null,
      fi_type: 'both'
    }
    state.selectedReportNameAsInDB = null
  },
  clearGarbageData(state) {
    state.reportElements = []
    state.customizedReportElements = []
    state.bankServices = []
    state.metricMenuData = []
    state.metricTreeData = []
    state.elementsInfo = []
  },
  setSavedReportElements(state, payload) {
    state.savedReportElements = payload
  },
  removeDuplicateElements(state) {
    let uniqElements = []

    state.reportElements.forEach(ce => {
      let isDefault

      switch (ce.type) {
        case 'firmographics':
        case 'segmentation':
        case 'market_area':
        case 'digital_channels':
          isDefault = state.defaultOptions.find(
            de => de.property === ce.property
          )
          if (!isDefault) {
            uniqElements.push(ce)
          }
          break
        case 'metrics':
        case 'common_metric':
        case 'technographics':
        case 'performographics':
        case 'financial_statements':
        case 'common_financial_statements':
        case 'fi_contacts':
          uniqElements.push(ce)
          break
        default:
          break
      }
    })

    state.reportElements = uniqElements
  },
  setUserCustomReports(state, payload) {
    state.userCustomReports = payload
  },
  setCommonMetricTreeData(state, payload) {
    state.commonMetricTreeData = payload
  },
  setActiveSegment(state, payload) {
    state.activeSegment = payload
  },
  setElementsAddedInPricing(state, payload) {
    state.elementsAddedInPricing = payload
  },
  setReportActualFIType(state, payload) {
    state.actualFIType = payload
  },
  setFirmographicMetrics(state, payload) {
    state.firmographicMetrics = payload
  },
  setFirmographicCommonMetrics(state, payload) {
    state.firmographicCommonMetrics = payload
  },
  setDigitalChannelBankServices(state, payload) {
    state.digitalChannelBankServices = payload
  },
  setElementsInfo(state, payload) {
    state.elementsInfo = payload
  },
  setContactTitles(state, payload) {
    state.contactTitles = payload
  },
  setContactRoles(state, payload) {
    state.contactRoles = payload
  },
  setLoadingContactCount(state, payload) {
    state.loadingContactCount = payload
  },
  removeDeletedReport(state, deletedReport) {
    state.userCustomReports = state.userCustomReports.filter(
      cr => cr.id !== deletedReport.id
    )
  },
  clearReportDataForEditor(state) {
    state.activeSegment = null
    state.reportElements = []
    state.customizedReportElements = []
    state.elementsAddedInPricing = []
    state.elementSequenceID = 0
    state.globalSettings = {
      'Financial Metrics': 'false',
      'Financial Statements': 'false',
      Technographics: 'false'
    }
    state.globalFinMetrics = {
      set: { id: 1, text: 'As Reported' },
      span: { id: 2, text: 'Trailing-12 Months' },
      scope: { id: 'single', text: 'Current Period' }
    }
    state.globalFinStatements = {
      set: { id: 1, text: 'As Reported' },
      span: { id: 2, text: 'Trailing-12 Months' },
      scope: { id: 'single', text: 'Current Period' }
    }
    state.globalTechnographics = {
      property: [{ id: 'default', text: 'Default' }]
    }
  },
  clearReportData(state) {
    state.activeSegment = null
    state.reportElements = []
    state.customizedReportElements = []
    state.elementsAddedInPricing = []
    state.savedReportElements = []
    state.selectedReport = {
      id: null,
      name: null,
      group_ids: [],
      description: null,
      report_elements: null,
      element_format: null,
      fi_type: 'both'
    }
    state.selectedReportNameAsInDB = null
    state.loadingContactCount = false
    state.elementSequenceID = 0
    state.mlElementList = [
      {
        id: 1,
        operand: null,
        operandLabel: null,
        actionTaken: [],
        outputType: null,
        filterOperator: 'AND',
        filterOn: false,

        filters: [
          {
            id: '1_1_1',
            ruleId: '1_1',
            filterId: null,
            type: 'filter',
            childOperand: null,
            value: [],
            count: null,
            error: false,
            operand: null,
            operator: null,
            loading: false
          }
        ]
      }
    ]
    state.globalSettings = {
      'Financial Metrics': 'false',
      'Financial Statements': 'false',
      Technographics: 'false'
    }
    state.globalFinMetrics = {
      set: { id: 1, text: 'As Reported' },
      span: { id: 2, text: 'Trailing-12 Months' },
      scope: { id: 'single', text: 'Current Period' }
    }
    state.globalFinStatements = {
      set: { id: 1, text: 'As Reported' },
      span: { id: 2, text: 'Trailing-12 Months' },
      scope: { id: 'single', text: 'Current Period' }
    }
    state.globalTechnographics = {
      property: [{ id: 'default', text: 'Default' }]
    }
  },
  clearReportDataForIndustryReporter(state) {
    state.bankServices = []
    state.showOutput = true
    state.activeSegment = null
    state.metricMenuData = []
    state.metricTreeData = []
    state.commonMetricTreeData = []
    state.reportElements = []
    state.customizedReportElements = []
    state.elementsAddedInPricing = []
    state.savedReportElements = []
    state.firmographicMetrics = []
    state.firmographicCommonMetrics = []
    state.contactTitles = []
    state.contactRoles = []
    state.allMetricAndStatementIDs = {}
    state.loadingContactCount = false
    state.elementSequenceID = 0
    state.globalSettings = {
      'Financial Metrics': 'false',
      'Financial Statements': 'false',
      Technographics: 'false'
    }
    state.globalFinMetrics = {
      set: { id: 1, text: 'As Reported' },
      span: { id: 2, text: 'Trailing-12 Months' },
      scope: { id: 'single', text: 'Current Period' }
    }
    state.globalFinStatements = {
      set: { id: 1, text: 'As Reported' },
      span: { id: 2, text: 'Trailing-12 Months' },
      scope: { id: 'single', text: 'Current Period' }
    }
    state.globalTechnographics = {
      property: [{ id: 'default', text: 'Default' }]
    }
  },
  saveFirmographicsElementList(state, list) {
    state.firmographicsElementList = list
  },
  saveMlElementList(state, list) {
    state.mlElementList = list
  },
  setGlobalSettings(state, payload) {
    state.globalSettings = payload
  },
  setGlobalFinMetrics(state, payload) {
    state.globalFinMetrics = payload
  },
  setGlobalFinStatements(state, payload) {
    state.globalFinStatements = payload
  },
  setGlobalTechnographics(state, payload) {
    state.globalTechnographics = payload
  }
}
