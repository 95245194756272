export default {
  usersSelectList(state) {
    return state.users.map(user => {
      return {
        text: `${user.name} - ${user.email} - ${user.company}`,
        value: user.id
      }
    })
  },
  groupsSelectList(state) {
    return state.groups.map(group => {
      return {
        text: `${group.name} - ${group.group_type}`,
        value: group.id
      }
    })
  }
}
