import getters from './online.edits.getters'
import mutations from './online.edits.mutations'
import actions from './online.edits.actions'

const initialState = () => ({
  showInstructions: true
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    ...getters
  },
  mutations: {
    ...mutations,
    resetOnlineEditsState(state) {
      let baseState = initialState()

      Object.keys(baseState).forEach(key => {
        state[key] = baseState[key]
      })
    }
  },
  actions
}
