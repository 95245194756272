import axios from 'axios'

export default {
  treeRelationships(treeId, reportId) {
    return axios
      .get('/api/metric_tree_relationships/tree_relationships', {
        handleErrors: true,
        params: {
          tree_id: treeId,
          report_id: reportId
        }
      })
      .then(res => {
        return res.data
      })
  },
  metricDescriptions() {
    return axios
      .get('/api/metric_tree_relationships/tree_descriptions', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
