import Vue from 'vue'

export default {
  setQueryRules(state, generalRules) {
    let queryTreeRules = state.treeRules.map(rule => {
      return {
        id: rule.id,
        label: rule.label,
        component: null,
        operators: [],
        children: []
      }
    })

    state.queryRules = [...generalRules, ...queryTreeRules]
  },
  setFINQuery(state, query) {
    state.finQuery = query
  },
  setPersistedQuery(state, query) {
    state.persistedQuery = query
  },
  setFIType(state, payload) {
    state.fiType = payload
  },
  setFIResultType(state, type) {
    state.fiResultType = type
  },
  setSavedQueries(state, queries) {
    state.savedQueryList = queries
  },
  removeDeletedQuery(state, query) {
    state.savedQueryList = state.savedQueryList.filter(
      savedQuery => savedQuery.id !== query.id
    )
  },
  updatePriceData(state, payload) {
    state.totalPrice = payload.total_price
    state.encryptedPrice = payload.encrypted_price
    state.tokens = payload.tokens
    state.costPerFIPerElement = payload.cost_per_fi_per_element
    state.minimumPrice = payload.minimum_price
    state.priceWithoutDiscounts = payload.price_without_discounts
    state.numericFields = payload.number_fields
    state.keyLabels = payload.key_labels
    state.downloadableFormats = payload.downloadable
  },
  setTokens(state, payload) {
    state.tokens = payload
  },
  setFICount(state, payload) {
    state.fiCount = payload
  },
  setFIContactCount(state, payload) {
    state.fiContactCount = payload
  },
  setFIsWithContactCount(state, payload) {
    state.fisWithContactsCount = payload
  },
  setIndividualCount(state, payload) {
    state.individualCount = payload
  },
  setSampleFIs(state, payload) {
    state.sampleFIs = payload
  },
  setProcessingStatus(state, payload) {
    state.fetchingFIs = payload
  },
  setPriceLoadingStatus(state, payload) {
    state.fetchingPriceData = payload
  },
  setSubscriptionLoadingStatus(state, payload) {
    state.fetchingSubscription = payload
  },
  setQueryElements(state, payload) {
    state.queryElements = payload
  },
  setQueryValidity(state, payload) {
    state.isValidQuery = payload
  },
  saveCurrentQuery(state, payload) {
    state.currentQuery = payload
  },
  setBaseQueryAsCurrentQuery(state) {
    state.currentQuery = state.baseQuery
  },
  setSelectedQuery(state, payload) {
    state.selectedQuery = payload
    state.selectedQueryNameAsInDB = payload.name
    state.fiResultType = payload.fi_type
  },
  setQueryGroupIDs(state, payload) {
    Vue.set(state.selectedQuery, 'group_ids', payload)
  },
  setSubscription(state, payload) {
    state.subscription = payload
  },
  setFIsWOCExcluded(state, query) {
    let contactElement = query.elements.find(
      el => el.element[0].element_type === 'fi_contacts'
    )
    let fisExcluded = contactElement
      ? contactElement.element[0].element_properties[0].exclude_fi
      : false

    state.fisWOCExcluded = fisExcluded
  },
  resetSelectedQuery(state) {
    state.selectedQuery = {
      id: null,
      name: null,
      description: null,
      group_ids: [],
      query: null
    }
  },
  resetFICountAndPriceData(state) {
    state.fiCount = null
    state.fiContactCount = null
    state.fisWithContactsCount = null
    state.fisWOCExcluded = false
    state.individualCount = []
    state.encryptedPrice = null
    state.tokens = 0
    state.costPerFIPerElement = 0
    state.totalPrice = null
    state.minimumPrice = 0
    state.priceWithoutDiscounts = 0
    state.sampleFIs = []
    state.numericFields = []
    state.keyLabels = []
    state.downloadableFormats = []
  },
  resetQueryData(state) {
    state.queryElements = []
    state.currentQuery = state.baseQuery
    state.isValidQuery = false
    state.fisWOCExcluded = false
    state.isMortgageLender = false
    state.selectedQuery = {
      id: null,
      name: null,
      description: null,
      group_ids: [],
      query: null
    }
    state.selectedQueryNameAsInDB = null
    state.subscription = {
      schedule: 'monthly',
      delivery_mode: 'app_link',
      delivery_format: 'csv'
    }
  },
  setEnterprisePermissions(state, payload) {
    state.enterprisePermissions = payload
  },
  setIsMortgageLender(state, payload) {
    state.isMortgageLender = payload
  }
}
