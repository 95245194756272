import Vue from 'vue'
import VueProgressBar from 'vue-progressbar'

const options = {
  color: '#79c447',
  failedColor: '#f44336',
  thickness: '3px',
  transition: {
    speed: '0.1s',
    opacity: '0.6s',
    termination: 300
  },
  autoRevert: false,
  location: 'top',
  inverse: false
}

Vue.use(VueProgressBar, options)
