// global
import Vue from 'vue'
// api
import advancedQueryAPI from '@/modules/advanced_query/api/advanced.query'
import queryRulesAPI from '@/api/finapps/query_rules'
import subscriptionAPI from '@/api/finapps/subscription'
//import userAPI from '@/api/finapps/user'
import QueryTransformer from '../helpers/query.transformer.js'
// utilities
import deepClone from '@/utilities.js'

export default {
  fetchSavedQueries({ commit, getters, rootGetters }) {
    return advancedQueryAPI.savedQueries(getters.isMortgageLender).then(res => {
      let queries = res.queries

      if (!rootGetters['Ability/canVendorFit']) {
        queries = queries.filter(query => {
          return !(query.query.elements || []).find(
            el => el.element[0].element_type === 'vendor_fit'
          )
        })
      }

      commit('setSavedQueries', queries)
    })
  },
  fetchQueryRules({ commit, getters }) {
    return queryRulesAPI.rules(getters.isMortgageLender).then(res => {
      if (getters.isMortgageLender.toString() === res.is_mbc)
        commit('setQueryRules', res.rules)
    })
  },
  checkQueryValidity({ dispatch, commit }, queryRules) {
    for (let rule of queryRules) {
      if (rule.children) {
        dispatch('checkQueryValidity', rule.children)
      } else {
        if (
          rule.value.length === 0 ||
          (rule.value.length > 0 && [undefined, ''].includes(rule.value[0])) ||
          (Array.isArray(rule.value[0]) && !rule.value[0].length)
        ) {
          commit('setQueryValidity', false)
        }
      }
    }
  },
  fetchFIInfo({ commit, getters }, transformedQuery) {
    commit('setProcessingStatus', true)
    return advancedQueryAPI
      .execute(transformedQuery, getters.isMortgageLender)
      .then(res => {
        commit('setFICount', res.count)
        commit('setFIContactCount', res.contact_count)
        commit('setFIsWithContactCount', res.count_fi_with_contacts)
        commit('setIndividualCount', res.individual_count)
        commit('ReportWriter/setReportFIType', res.fi_type, { root: true })
        commit('setFIType', res.fi_type)
        commit('setFIResultType', res.query_type)
        commit('setFIsWOCExcluded', transformedQuery)
      })
      .finally(() => {
        commit('setProcessingStatus', false)
      })
  },
  fetchPriceInfo({ commit, getters }, transformedQuery) {
    commit('setPriceLoadingStatus', true)
    return advancedQueryAPI
      .pricing(transformedQuery, getters.isMortgageLender)
      .then(res => {
        commit('updatePriceData', res)
        commit('setSampleFIs', res.samples)
      })
      .finally(() => {
        commit('setPriceLoadingStatus', false)
      })
  },
  async runQuery({ dispatch, commit, state }, query = null) {
    if (query) {
      commit('saveCurrentQuery', query)
    }

    let queryTransformer = new QueryTransformer()

    commit('setQueryElements', queryTransformer.finQueryElements)

    dispatch('ReportWriter/resetElementsAddedInPricing', null, { root: true })

    commit('setFINQuery', queryTransformer.transform)
    dispatch('fetchPriceInfo', state.finQuery)
    await dispatch('fetchFIInfo', state.finQuery)
  },
  runReport({ dispatch, commit, state }) {
    commit('setQueryValidity', true)
    dispatch('checkQueryValidity', state.currentQuery.children)

    if (!state.currentQuery) {
      commit('setBaseQueryAsCurrentQuery')
    }

    return new Promise((resolve, reject) => {
      if (state.isValidQuery) {
        dispatch('runQuery').then(
          () => {
            resolve('executedQuery')
          },
          err => {
            reject(err)
          }
        )
      } else {
        reject('invalidQuery')
      }
    })
  },
  checkQueryValidityForRun({ dispatch, commit, state }) {
    commit('setQueryValidity', true)

    dispatch('checkQueryValidity', state.currentQuery.children)

    if (!state.currentQuery) {
      commit('setBaseQueryAsCurrentQuery')
    }

    if (state.isValidQuery) {
      return Promise.resolve()
    } else {
      return Promise.reject('invalidQuery')
    }
  },
  fetchFICount({ commit }, query) {
    let transformedQuery = new QueryTransformer(query).transform

    commit('setProcessingStatus', true)

    return advancedQueryAPI
      .execute(transformedQuery)
      .then(res => {
        commit('setFICount', res.count)
        commit('setFIResultType', res.query_type)
        commit('setIndividualCount', res.individual_count)
      })
      .finally(() => {
        commit('setProcessingStatus', false)
      })
  },
  fetchSubscription({ state, commit, rootGetters }) {
    let reportID = rootGetters['ReportWriter/customReport'].id

    commit('setSubscriptionLoadingStatus', true)
    return subscriptionAPI.find(state.selectedQuery.id, reportID).then(res => {
      commit(
        'setSubscription',
        res.subscription || {
          schedule: 'monthly',
          delivery_mode: 'app_link',
          delivery_format: 'csv'
        }
      )
      commit('setSubscriptionLoadingStatus', false)
    })
  },
  saveQuery({ state, dispatch }) {
    return state.fiCount
      ? dispatch('createOrUpdateQuery')
      : dispatch('runQuery').then(() => {
          dispatch('createOrUpdateQuery')
        })
  },
  createOrUpdateQuery({ state, dispatch }) {
    if (
      state.selectedQuery.id &&
      state.selectedQuery.name.trim() === state.selectedQueryNameAsInDB.trim()
    ) {
      return dispatch('updateQuery')
    } else {
      return dispatch('createQuery')
    }
  },
  createQuery({ state, dispatch, commit }) {
    commit('setProcessingStatus', true)

    let query = new QueryTransformer().transformToSave
    return advancedQueryAPI
      .saveQuery(
        state.selectedQuery.name,
        state.selectedQuery.description,
        state.selectedQuery.group_ids,
        query,
        state.fiResultType
      )
      .then(res => {
        if (res && res.query) {
          commit('setSelectedQuery', res.query)
          commit('setPersistedQuery', deepClone(state.currentQuery))
          dispatch('fetchSubscription')

          Vue.toasted.show('Query successfully created.', {
            icon: 'user-circle',
            type: 'success'
          })
        }
      })
      .finally(() => {
        commit('setProcessingStatus', false)
      })
  },
  updateQuery({ state, commit }) {
    commit('setProcessingStatus', true)

    let query = new QueryTransformer().transformToSave

    return advancedQueryAPI
      .updateQuery(state.selectedQuery, query, state.fiResultType)
      .then(res => {
        if (res && res.success) {
          commit('setSelectedQuery', res.query)
          commit('setPersistedQuery', deepClone(state.currentQuery))
          commit('setProcessingStatus', false)
          Vue.toasted.show('Query successfully updated.', {
            icon: 'user-circle',
            type: 'success'
          })
        }
      })
  }
  // loadEnterprisePermissions({ commit }) {
  //   userAPI.enterprisePermissions().then(res => {
  //     commit('setEnterprisePermissions', res)
  //   })
  // }
}
