export default {
  namespaced: true,
  state: {},
  mutations: {},
  actions: {
    setUserDataToChameleon(context) {
      let data = context.rootState.Authentication
      let userGroup =
        context.rootGetters['Authentication/userClientGroup'] ||
        Object.create(null)
      let enabledPaths = context.rootGetters['Ability/uiModules']
      if (data.userId) {
        window.chmln.identify(data.userId, {
          name: data.userName,
          email: data.userEmail,
          role: data.userRole.id,
          created: data.userCreatedDate,
          project: data.isDemoUser ? 'Demo' : 'Live',
          company: {
            uid: userGroup.id,
            created: userGroup.created_at,
            name: userGroup.name,
            plan: data.companyType,
            enterprisePermissions: {
              isDigitalChannelsAvailable:
                context.rootGetters['Ability/isDigitalChannelsAvailable'],
              isFIContactsAvailable:
                context.rootGetters['Ability/isFIContactsAvailable'],
              isFinQueryAvailable:
                context.rootGetters['Ability/isFinQueryAvailable'],
              isMarketReportsAvailable:
                context.rootGetters['Ability/isMarketReportsAvailable'],
              isAnalyticsEnterpriseUser:
                context.rootGetters['Ability/isAnalyticsEnterpriseUser'],
              isFinApiEnterpriseUser:
                context.rootGetters['Ability/isFinApiEnterpriseUser'],
              isMbcAvailable: context.rootGetters['Ability/isMbcAvailable']
            },
            authorization: {
              finTechnographics: enabledPaths.some(
                path => path.name === 'FIN Technographics'
              ),
              finQuery: enabledPaths.some(path => path.name === 'FIN Query'),
              finCrm: enabledPaths.some(path => path.name === 'FIN CRM'),
              finVendorfit: enabledPaths.some(
                path => path.name === 'FIN VendorFit'
              ),
              finAdvisor: enabledPaths.some(
                path => path.name === 'FIN Advisor'
              ),
              finReporter: enabledPaths.some(
                path => path.name === 'FIN Reporter'
              ),
              finProfile: enabledPaths.some(
                path => path.name === 'FIN Profile'
              ),
              fintechReports: enabledPaths.some(
                path => path.name === 'FinTech Market Reports'
              ),
              finDigitalChannels: enabledPaths.some(
                path => path.name === 'FIN Digital Channels'
              ),
              finIndustryReporter: enabledPaths.some(
                path => path.name === 'FIN Industry Reporter'
              )
            }
          }
        })
      }
    }
  }
}
