export const setInstitution = function(state, institution) {
  state.selectedInstitution = institution
  state.selectedPeerGroup = {}
  state.reportId = null
  state.reportProgress = null
}

export const setPeerGroup = function(state, peerGroup) {
  state.selectedPeerGroup = peerGroup
  state.reportId = null
  state.reportProgress = null
}

export const setMetricId = function(state, metricId) {
  state.selectedMetricId = metricId
}

export const peerGroups = function(state, payload) {
  state.peerGroups = payload
}

export const setMenuData = function(state, payload) {
  state.menuData = payload.menu
  state.reportId = payload.report_id
  state.reportToken = payload.report_token
  state.reportProgress = payload.report_progress || 0
}

export const setReportID = function(state, payload) {
  state.reportId = payload.report_id
}

export const setSpanId = function(state, spanId) {
  state.spanId = spanId
}

export const toggleSet = function(state) {
  state.setId = state.setId === 1 ? 2 : 1
}

export const setPrimaryTheme = function(state, primaryTheme) {
  state.primaryTheme = primaryTheme
}

export const setChartType = function(state, type) {
  state.chartType = type
}

export const setStatusDisplay = function(state, type) {
  state.statusDisplay = type
}

export const setReportToken = function(state, payload) {
  state.reportToken = payload.report_id
}

export const setReportProgress = function(state, payload) {
  state.reportProgress = payload.report_progress
}

export const setDecisionTreeData = function(state, payload) {
  state.treeData = payload
}

export const setReportStatus = function(state, payload) {
  if (payload) {
    state.reportStatus = payload.status
    state.reportProgress = payload.progress
  } else {
    state.reportProgress = 0
    state.reportStatus = 'Preparing...'
  }
}
export const addBankKeyMetric = (state, payload) => {
  state.bankKeyMetrics.push(payload)
}
export const addCuKeyMetric = (state, payload) => {
  state.cuKeyMetrics.push(payload)
}
export const addBhcKeyMetric = (state, payload) => {
  state.bhcKeyMetrics.push(payload)
}
export const removeBankKeyMetric = (state, payload) => {
  state.bankKeyMetrics = state.bankKeyMetrics.filter(
    met => met.id != payload.id
  )
}
export const removeCuKeyMetric = (state, payload) => {
  state.cuKeyMetrics = state.cuKeyMetrics.filter(met => met.id != payload.id)
}
export const removeBhcKeyMetric = (state, payload) => {
  state.bhcKeyMetrics = state.bhcKeyMetrics.filter(met => met.id != payload.id)
}
export const setSelectingPeerGroup = function(state, payload) {
  state.selectingPeerGroup = payload
}
export const resetKeyMetrics = function(state) {
  state.cuKeyMetrics = []
  state.bankKeyMetrics = []
  state.bhcKeyMetrics = []
}
export const setProfitFlag = function(state, payload) {
  state.profitFlag = payload
}
export const resetData = function(state) {
  state.selectedInstitution = {}
  state.selectedPeerGroup = {}
  state.selectedPeerGroup = false
  state.peerGroups = []
  state.menuData = []
  state.reportId = null
  state.spanId = 2
  state.setId = 2
  state.reportToken = null
  state.reportProgress = 0
  state.cuKeyMetrics = []
  state.bankKeyMetrics = []
  state.bhcKeyMetrics = []
  state.profitFlag = true
  state.reportStatus = 'Preparing...'
}
