export default {
  path: '/super_admin/report_writer',
  name: 'ReportWriter',
  meta: { requiredAuth: true },
  redirect: '/report_writer/select_report',
  component: {
    render(c) {
      document.title = 'Report Writer'
      return c('router-view')
    }
  },
  children: [
    {
      path: 'select_report',
      name: 'UserCustomReports',
      component: () => import('./pages/UserCustomReports'),
      meta: {
        type: 'super_admin'
      }
    },
    {
      path: 'custom_report',
      name: 'CustomReport',
      component: () => import('./pages/ReportEditor'),
      meta: {
        type: 'super_admin'
      }
    }
  ]
}
