import Vue from 'vue'
import ApolloClient from 'apollo-boost'
import VueApollo from 'vue-apollo'
import 'isomorphic-unfetch'

const apolloClient = new ApolloClient({
  fetchOptions: { fetch },
  uri: process.env.VUE_APP_GRAPHQL_URL,
  credentials: 'include'
})

const ethosClient = new ApolloClient({
  fetchOptions: { fetch },
  uri: process.env.VUE_APP_ETHOS_GRAPHQL_URL,
  credentials: 'include'
})

const apolloProvider = new VueApollo({
  clients: {
    ethosClient,
    apolloClient
  },
  defaultClient: apolloClient,
  defaultOptions: {
    $query: {
      fetchPolicy: 'cache-and-network'
    }
  }
})

Vue.use(VueApollo)

export default apolloProvider
