import Vue from 'vue'
import axios from 'axios'
import Qs from 'qs'

export default {
  navigatorSegments() {
    return axios
      .get('/api/segmentations', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  savedSegments() {
    return axios
      .get('/api/segmentations/custom', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  segmentDetails(segmentID) {
    return axios
      .get(`/api/segmentation_sets/${segmentID}/set_details`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  segmentRules(groupIDs) {
    return axios
      .get('/api/segmentation_sets/query_rules', {
        params: {
          fi_group_ids: groupIDs
        },
        paramsSerializer: function(params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' })
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  setDetailCount(setElement, setDetail, groupIDs) {
    return axios
      .get('/api/segmentation_sets/set_detail_count', {
        params: {
          element: setElement,
          setDetail: setDetail,
          fi_group_ids: groupIDs
        },
        paramsSerializer: function(params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' })
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  editSegment(segmentID) {
    return axios
      .get(`/api/segmentation_sets/${segmentID}`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  saveSegment: (setName, description, setElement, setDetails) => {
    return axios
      .post(
        '/api/segmentation_sets',
        {
          segmentation_set: {
            name: setName.trim(),
            description: description,
            element: setElement,
            segmentation_set_details_attributes: setDetails
          }
        },
        {
          handleErrors: false
        }
      )
      .then(
        res => {
          return res.data
        },
        error => {
          if (error.response.status === 422) {
            Vue.toasted.show(
              'Validation failed. Segment name already exists.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
        }
      )
  },
  updateSegment: (segmentID, setName, description, setElement, setDetails) => {
    return axios
      .put(
        `/api/segmentation_sets/${segmentID}`,
        {
          segmentation_set: {
            name: setName.trim(),
            description: description,
            element: setElement,
            segmentation_set_details_attributes: setDetails
          }
        },
        {
          handleErrors: false
        }
      )
      .then(
        res => {
          return res.data
        },
        error => {
          if (error.response.status === 422) {
            Vue.toasted.show(
              'Validation failed. Segment name already exists.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
        }
      )
  },
  deleteSegment: id => {
    return axios
      .delete(`/api/segmentation_sets/${id}`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
