import * as getters from './authentication.getters'
import * as mutations from './authentication.mutations'
import * as actions from './authentication.actions'

export default {
  namespaced: true,
  state: {
    userId: null,
    userName: null,
    userEmail: null,
    userCompany: null,
    userCreatedDate: null,
    companyType: null,
    isDemoUser: false,
    isActive: false,
    userRole: {},
    userGroups: [],
    inactiveEmail: null,
    mockUser: false,
    isFiMenu: false,
    isDirectLogin: false
  },
  mutations,
  actions,
  getters
}
