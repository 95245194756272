const routes = {
  path: '/analytics/advisor',
  name: 'FINAdvisor',
  redirect: '/analytics/advisor/select_institution',
  meta: { requiredAuth: true },
  component: () => import('./pages/Advisor'),
  children: [
    {
      path: 'select_institution',
      name: 'SelectAdvisorInstitution',
      component: () => import('./pages/Institution'),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'select_peer_group',
      name: 'SelectAdvisorPeerGroup',
      component: () => import('./pages/PeerGroup'),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'profit/summary',
      name: 'ProfitExecutiveSummary',
      component: () => import('./pages/profit/ExecutiveSummary'),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'profit/macro_profitability',
      name: 'ProfitMacroProfitability',
      alias: 'key_metrics/profit/macro_profitability',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/profit/MacroProfitability'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'profit/efficiency_ratio',
      name: 'ProfitEfficiencyRatio',
      alias: 'key_metrics/profit/efficiency_ratio',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/profit/EfficiencyRatio'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'profit/net_interest_margin',
      name: 'ProfitNetInterestMargin',
      alias: 'key_metrics/profit/net_interest_margin',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/profit/NetInterestMargin'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'profit/non_interest_income',
      name: 'ProfitNonInterestIncome',
      alias: 'key_metrics/profit/non_interest_income',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/profit/NonInterestIncome'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'profit/non_interest_expense',
      name: 'ProfitNonInterestExpense',
      alias: 'key_metrics/profit/non_interest_expense',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/profit/NonInterestExpense'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'growth/summary',
      name: 'GrowthSummary',
      component: () => import('./pages/growth/ExecutiveSummary'),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'growth/franchise_growth',
      name: 'GrowthFranchise',
      alias: 'key_metrics/growth/franchise_growth',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/growth/FranchiseGrowth'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'growth/loan_growth',
      name: 'GrowthLoan',
      alias: 'key_metrics/growth/loan_growth',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/growth/LoanGrowth'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'growth/deposit_growth',
      name: 'GrowthDeposit',
      alias: 'key_metrics/growth/deposit_growth',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/growth/DepositGrowth'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'growth/earnings_growth',
      name: 'GrowthEarnings',
      alias: 'key_metrics/growth/earnings_growth',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/growth/EarningsGrowth'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'growth/share_growth',
      name: 'GrowthShare',
      alias: 'key_metrics/growth/share_growth',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/growth/ShareGrowth'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'growth/member_growth',
      name: 'GrowthMember',
      alias: 'key_metrics/growth/member_growth',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/growth/MemberGrowth'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'risk/summary',
      name: 'RiskSummary',
      component: () => import('./pages/risk/ExecutiveSummary'),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'risk/enterprise_risk',
      name: 'RiskEnterprise',
      alias: 'key_metrics/risk/enterprise_risk',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/risk/EnterpriseRisk'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'risk/capital_adequacy',
      name: 'RiskCapital',
      alias: 'key_metrics/risk/capital_adequacy',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/risk/CapitalAdequacy'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'risk/asset_quality',
      name: 'RiskAsset',
      alias: 'key_metrics/risk/asset_quality',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/risk/AssetQuality'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'risk/earnings_quality',
      name: 'RiskEarnings',
      alias: 'key_metrics/risk/earnings_quality',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/risk/EarningsQuality'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'risk/liquidity_risk',
      name: 'RiskLiquidity',
      alias: 'key_metrics/risk/liquidity_risk',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/risk/LiquidityRisk'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'offering/summary',
      name: 'OfferingSummary',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/offering/ExecutiveSummary'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'offering/retail_services',
      name: 'OfferingRetailServices',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/offering/RetailServices'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'offering/business_services',
      name: 'OfferingBusinesServices',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/offering/BusinessServices'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'offering/vendor_products',
      name: 'OfferingVendorProducts',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/offering/VendorsProducts'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    },
    {
      path: 'offering/top_technologies',
      name: 'OfferingTopTechnologies',
      component: () =>
        import(
          /* webpackChunkName: "group-gojs" */ './pages/offering/TopTechnologies'
        ),
      meta: {
        type: 'advisor',
        modulePath: '/analytics/advisor/select_institution'
      }
    }
  ]
}

export default routes
