import { render, staticRenderFns } from "./SidebarNavTitle.vue?vue&type=template&id=f484fac6&scoped=true&"
import script from "./SidebarNavTitle.vue?vue&type=script&lang=js&"
export * from "./SidebarNavTitle.vue?vue&type=script&lang=js&"
import style0 from "./SidebarNavTitle.vue?vue&type=style&index=0&id=f484fac6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f484fac6",
  null
  
)

export default component.exports