const routes = {
  path: '/reports/industry_reporter',
  name: 'FIN Industry Reporter',
  meta: { requiredAuth: true },
  component: () => import('./pages/IndustryReporter'),
  children: [
    {
      path: 'select_fi_group',
      name: 'IRSelectFIGroup',
      component: () => import('./pages/SelectFIGroup'),
      meta: {
        type: 'industry_report',
        modulePath: '/reports/industry_reporter/select_fi_group'
      }
    },
    {
      path: 'select_report',
      name: 'IRSelectReport',
      component: () => import('./pages/SelectReport'),
      meta: {
        type: 'industry_report',
        modulePath: '/reports/industry_reporter/select_fi_group'
      }
    },
    {
      path: 'segmentation',
      name: 'IRSegmentation',
      component: () => import('./pages/Segmentation'),
      meta: {
        type: 'industry_report',
        modulePath: '/reports/industry_reporter/select_fi_group'
      }
    },
    {
      path: 'report',
      name: 'IRReport',
      component: () => import('./pages/Report'),
      meta: {
        type: 'industry_report',
        modulePath: '/reports/industry_reporter/select_fi_group'
      }
    }
  ]
}

export default routes
