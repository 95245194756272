import _ from 'lodash'

export default {
  checkPeerName(state) {
    return state.savedPeerGroupInfo.name === (state.peerGroup.name || '')
  },

  checkPeerDescription(state) {
    return (
      state.savedPeerGroupInfo.description ===
      (state.peerGroup.description || '')
    )
  },

  checkPeerMembers(state) {
    let selectedInstitutionIds = state.selectedInstitutions.map(ins => ins.id)

    return _.isEqual(
      _.sortBy(state.savedPeerGroupInfo.memberIds),
      _.sortBy(selectedInstitutionIds)
    )
  }
}
