import toBeAdded from '@/views/pages/toBeAdded'

export default {
  path: '/super_admin/strategy',
  name: 'Strategy',
  redirect: '/strategy/segmentation',
  meta: { requiredAuth: true },
  component: {
    render(c) {
      return c('router-view')
    }
  },
  children: [
    {
      path: 'segmentation',
      name: 'Segmentation',
      component: toBeAdded,
      meta: { type: 'super_admin' }
    },
    {
      path: 'white_space_assessment',
      name: 'WhiteSpaceAssessment',
      component: toBeAdded,
      meta: { type: 'super_admin' }
    },
    {
      path: 'publications',
      name: 'Publications',
      component: toBeAdded,
      meta: { type: 'super_admin' }
    }
  ]
}
