import axios from 'axios'

const allGroups = function() {
  return axios
    .get('/api/super_admin/groups', {
      handleErrors: true
    })
    .then(res => {
      return res.data
    })
}

export default {
  all: () => {
    return axios
      .get('/api/groups', {
        params: { super_admin: true },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  userGroups: () => {
    return allGroups()
  }
}
