<template>
  <div class="sidebar">
    <SidebarForm />
    <nav class="sidebar-nav">
      <ul class="nav">
        <template v-for="(item, index) in navItems">
          <template v-if="item.title">
            <SidebarNavTitle
              :name="item.name"
              :classes="item.class"
              :wrapper="item.wrapper"
              :key="index"
            />
          </template>
          <template v-else-if="item.divider">
            <SidebarNavDivider :classes="item.class" :key="index" />
          </template>
          <template v-else-if="item.label">
            <SidebarNavLabel
              :name="item.name"
              :url="item.url"
              :icon="item.icon"
              :label="item.label"
              :classes="item.class"
              :key="index"
            />
          </template>
          <template v-else>
            <template v-if="item.children">
              <!-- First level dropdown -->
              <SidebarNavDropdown
                :name="item.name"
                :url="item.url"
                :icon="item.icon"
                :togglable="false"
                :key="index"
              >
                <template v-for="(childL1, index) in item.children">
                  <template>
                    <SidebarNavItem :classes="item.class" :key="index">
                      <SidebarNavDropdown
                        :name="childL1.name"
                        :url="childL1.url"
                        :icon="childL1.icon"
                        :children="childL1.children"
                        :togglable="true"
                        v-if="childL1.children"
                      >
                        <template v-for="(childL2, index) in childL1.children">
                          <template>
                            <SidebarNavItem :classes="item.class" :key="index">
                              <SidebarNavLink
                                :name="childL2.name"
                                :url="childL2.url"
                                :icon="childL2.icon"
                                :navType="childL2.type"
                                :badge="childL2.badge"
                                :variant="item.variant"
                                :parentItem="item"
                                :children="childL2.children"
                                :classes="childL2.class"
                              />
                            </SidebarNavItem>
                          </template>
                        </template>
                      </SidebarNavDropdown>
                      <SidebarNavLink
                        :name="childL1.name"
                        :url="childL1.url"
                        :icon="childL1.icon"
                        :badge="childL1.badge"
                        :variant="item.variant"
                        :parentItem="item"
                        :classes="childL1.class"
                        v-else
                      />
                    </SidebarNavItem>
                  </template>
                </template>
              </SidebarNavDropdown>
            </template>
            <template v-else>
              <SidebarNavItem
                :classes="item.class"
                :name="item.name"
                :key="index"
              >
                <SidebarNavLink
                  :name="item.name"
                  :url="item.url"
                  :icon="item.icon"
                  :badge="item.badge"
                  :variant="item.variant"
                />
              </SidebarNavItem>
            </template>
          </template>
        </template>
      </ul>
      <slot></slot>
    </nav>
    <SidebarFooter />
    <SidebarMinimizer />
  </div>
</template>
<script>
import SidebarFooter from './SidebarFooter'
import SidebarForm from './SidebarForm'
import SidebarMinimizer from './SidebarMinimizer'
import SidebarNavDivider from './SidebarNavDivider'
import SidebarNavDropdown from './SidebarNavDropdown'
import SidebarNavLink from './SidebarNavLink'
import SidebarNavTitle from './SidebarNavTitle'
import SidebarNavItem from './SidebarNavItem'
import SidebarNavLabel from './SidebarNavLabel'

export default {
  name: 'sidebar',
  props: {
    navItems: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  components: {
    SidebarFooter,
    SidebarForm,
    SidebarMinimizer,
    SidebarNavDivider,
    SidebarNavDropdown,
    SidebarNavLink,
    SidebarNavTitle,
    SidebarNavItem,
    SidebarNavLabel
  },
  computed: {},
  methods: {}
}
</script>

<style lang="scss">
.sidebar {
  .sidebar-nav {
    .nav-link {
      cursor: pointer;
    }
  }
}
</style>
