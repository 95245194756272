export default {
  selectedInstitution(state) {
    return state.selectedInstitution
  },

  isCreditUnionSelected(state) {
    return (
      state.selectedInstitution &&
      state.selectedInstitution.fiType === 'credit_union'
    )
  },

  reportProgress(state) {
    return state.reportProgress
  },

  reportId(state) {
    return state.reportId
  },

  s3LogoUrl(state) {
    if (state.selectedInstitution) {
      return (
        'https://finapps-logos.s3.amazonaws.com/logo_' +
        state.selectedInstitution.id +
        '.png'
      )
    } else {
      return '/'
    }
  },

  institutionType(state) {
    if (!state.selectedInstitution) {
      return ''
    }

    switch (state.selectedInstitution.fiType) {
      case 'credit_union':
        return 'CU'
      case 'bank':
        return 'Bank'
      case 'bhc':
        return 'BHC'
      default:
        return ''
    }
  },
  getContacts(state) {
    return state.contacts
  }
}
