import axios from 'axios'

export default {
  metrics(reportId) {
    return axios
      .get('/api/common_metrics_mappings', {
        handleErrors: true,
        params: {
          report_id: reportId
        }
      })
      .then(res => {
        return res.data
      })
  },
  metricDescriptions() {
    return axios
      .get('/api/common_metrics_mappings/tree_descriptions', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
