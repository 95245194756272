export const redirectUrl = state => {
  let url = state.redirectUrl || window.localStorage.getItem('redirectUrl')
  return url === 'null' ? null : url
}

export const latestRevision = state => {
  return state.latestRevision
}

export const companyTypes = state => {
  return state.companyTypes
}

export const isFinancialInstitution = state => {
  return state.isFinancialInstitution === null
    ? window.localStorage.getItem('isFinancialInstitution') === 'true'
    : state.isFinancialInstitution
}

export const fiTypeMapper = () => {
  return {
    bank: 'Bank',
    bhc: 'BHC',
    credit_union: 'CU'
  }
}

export const spanMapper = () => {
  return {
    1: 'Qtr',
    2: 'T12',
    3: 'YTD'
  }
}

export const sessionExpired = state => {
  return state.sessionExpired
}
