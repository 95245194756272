export default {
  path: '/partner_admin',
  redirect: '/partner_admin/dashboard',
  name: 'PartnerAdmin',
  meta: { requiredAuth: true },
  component: () => import('./pages/partner_admin.js'),
  children: [
    {
      path: 'dashboard',
      name: 'PartnerAdminDashboard',
      component: () => import('./pages/Dashboard'),
      meta: { type: 'partner_admin' }
    },
    {
      path: 'groups',
      name: 'PartnerGroups',
      component: () => import('./pages/Groups'),
      meta: { type: 'partner_admin' }
    },
    {
      path: 'groups/new',
      name: 'NewPartnerGroup',
      component: () => import('./pages/NewGroup'),
      meta: { type: 'partner_admin' }
    },
    {
      path: 'group/:id/edit',
      name: 'PartnerAdminEditGroup',
      component: () => import('./pages/EditGroup'),
      meta: { type: 'partner_admin' }
    },
    {
      path: 'users',
      name: 'PartnerUsers',
      component: () => import('./pages/Users'),
      meta: { type: 'partner_admin' }
    },
    {
      path: 'user/:id/edit',
      name: 'PartnerAdminEditUser',
      component: () => import('./pages/EditUser'),
      meta: { type: 'partner_admin' }
    },
    {
      path: 'user_activity',
      name: 'PartnerUserActivity',
      component: () => import('./pages/UserActivity'),
      meta: { type: 'partner_admin' }
    }
  ]
}
