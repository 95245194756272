import _ from 'lodash'
import store from '@/store/store.js'

export const getDisplayTextForFirmographicsElement = item => {
  let text = ''
  let eoList = item.elementOption.map(eo =>
    eo.label.replace(' (1-4 Units)', '').replace(' (5+ Units)', '')
  )
  text +=
    eoList.length == 1 ? eoList[0] + ': ' : '[' + eoList.join(', ') + ']: '

  let atList = item.actionTaken.map(at => at.label)
  text +=
    atList.length == 1 ? atList[0] + ': ' : '[' + atList.join(', ') + ']: '
  text += item.outputTypeLabel.replace(/Loan|Record/gi, '')
  //text += item.operandLabel + ': '

  return text
}
export const getDisplayTextForMLElement = item => {
  let queryRules = store.getters['AdvancedQuery/queryRules']
  let filterList = queryRules.find(rule => rule.label === 'Mortgage Lending')
    .filters
  let filterText = ''
  if (item.filters[0] && item.filters[0].filterId) {
    let fList = item.filters.map(f => getFilterText(f, filterList))
    filterText = '[' + fList.join(' ' + item.filterOperator + ' ') + ']'
  }
  let text = ''
  let atList = item.actionTaken.map(at => at.label)
  text += '[' + atList.join(', ') + ']: '
  text += item.outputTypeLabel.replace(/Loan|Record/gi, '') + ': '
  text += filterText
  return text
}
export const getFilterText = (filter, filterList) => {
  let operators = {
    equal: '=',
    less: '<',
    'less or equal': '<=',
    greater: '>',
    'greater or equal': '>=',
    between: 'BETWEEN',
    'not between': 'NOT BETWEEN'
  }
  let text = ''
  let selectedFilter = filterList.find(f => f.id === filter.filterId)
  if (selectedFilter) {
    text = '{'
    //text += selectedFilter.label + ': '
    text += selectedFilter.children.find(c => c.id === filter.childOperand.id)
      .label
    text += ' ' + operators[filter.operator]
    text +=
      ' (' + filter.value.map(v => (v.label ? v.label : v)).join(', ') + ')'
    text += '}'
  }
  return text
}
export const validQueryElements = (isMortgageLenderFromFINQuery = false) => {
  let defaultElements = isMortgageLenderFromFINQuery
    ? store.getters['ReportWriter/defaultMBCOptions']
    : store.getters['ReportWriter/defaultElements']
  let reportElements = store.getters['ReportWriter/customElements']
  let queryElements = store.getters['AdvancedQuery/queryElements']
  return queryElements.filter(qe => {
    let isDuplicate =
      qe.type === 'mortgage_lending'
        ? reportElements.find(re => qe.type === re.type && qe.name === re.name)
        : isMortgageLenderFromFINQuery &&
          qe.type === 'firmographics' &&
          qe.actionTaken
        ? reportElements.find(re => qe.type === re.type && qe.name === re.name)
        : qe.elementID
        ? reportElements.find(
            re =>
              parseInt(qe.elementID) === parseInt(re.id) && qe.type === re.type
          )
        : reportElements.find(
            re => re.type === qe.type && re.property === qe.property
          )
    return (
      !defaultElements.includes(qe.property) &&
      !isDuplicate &&
      qe.type !== 'vendor_fit'
    )
  })
}

export const elementsToMoveFromQueryToReporter = () => {
  let matchedElements = []
  let validElements = validQueryElements()
  let metricStatementsTypes = [
    'metrics',
    'common_metric',
    'financial_statements',
    'common_financial_statements'
  ]
  let hasMetricOrStatement = validElements.find(ve =>
    metricStatementsTypes.includes(ve.type)
  )

  if (hasMetricOrStatement) {
    return store
      .dispatch('ReportWriter/fetchAllMetricAndStatementIDs')
      .then(() => {
        let allMetricAndStatementIDs =
          store.getters['ReportWriter/allMetricAndStatementIDs']
        matchedElements = validElements.filter(ve => {
          if (metricStatementsTypes.includes(ve.type)) {
            return allMetricAndStatementIDs[ve.type].has(parseInt(ve.elementID))
          } else {
            return true
          }
        })
        return matchedElements
      })
  } else {
    return Promise.resolve(validElements)
  }
}
export const formattedQueryElementsToCopyToReporter = (
  isMortgageLender = false
) => {
  return validQueryElements(isMortgageLender).map(qe => {
    store.commit('ReportWriter/updateSequenceID')

    let element = {
      name: qe.name,
      type: qe.type,
      property: qe.property,
      seqID: store.getters['ReportWriter/elementSequenceID']
    }

    switch (element.type) {
      case 'metrics':
      case 'common_metric':
      case 'financial_statements':
      case 'common_financial_statements':
        element.id = parseInt(qe.elementID)
        element.set = 1
        element.span = 2
        element.scope = 'single'
        break
      case 'technographics':
        element.id = parseInt(qe.elementID)
        break
      case 'performographics':
        element.id = qe.elementID
        break
      case 'mortgage_lending':
        element.actionTaken = qe.actionTaken
        element.outputType = qe.outputType
        element.filters = qe.filters
        element.filterOperator = qe.filterOperator
        element.ruleId = qe.ruleId
        break
      case 'firmographics':
        if (qe.actionTaken) {
          element.actionTaken = qe.actionTaken[0].value
          element.elementOption = qe.elementOption
          element.outputType = qe.outputType.value
          element.ruleId = qe.ruleId
        }
        break
      default:
        break
    }

    store.commit('ReportWriter/addCustomizedElement', element)
    return element
  })
}

export const elementCount = (isMortgageLender = false) => {
  let defaultElements = isMortgageLender
    ? store.getters['ReportWriter/defaultMBCOptions']
    : store.getters['ReportWriter/defaultOptions']
  return (
    defaultElements.length +
    store.getters['ReportWriter/customElements'].length +
    validQueryElements(isMortgageLender).length
  )
}

export const areReporterElementsModified = (
  currentElements,
  includedElements
) => {
  if (currentElements.length !== includedElements.length) {
    return true
  }

  return currentElements.some(currentEl => {
    if (['metrics', 'common_metric'].includes(currentEl.type)) {
      return !includedElements.find(ie => {
        return (
          currentEl.type === ie.type &&
          currentEl.id === ie.id &&
          currentEl.property === ie.property &&
          currentEl.scope === ie.scope &&
          currentEl.set === ie.set &&
          currentEl.span === ie.span
        )
      })
    } else if (currentEl.type === 'technographics') {
      return !includedElements.find(
        ie =>
          ie.type === currentEl.type &&
          currentEl.id === ie.id &&
          _.isEqual(currentEl.property.sort(), ie.property.sort())
      )
    } else if (currentEl.type === 'fi_contacts') {
      return !includedElements.find(ie => {
        return (
          currentEl.type === ie.type &&
          currentEl.property === ie.property &&
          currentEl.excludeFI === ie.excludeFI &&
          currentEl.email === ie.email
        )
      })
    } else {
      return !includedElements.find(
        ie => ie.type === currentEl.type && ie.property === currentEl.property
      )
    }
  })
}

export default elementCount
