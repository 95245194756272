import getters from './profile.getters'
import mutations from './profile.mutations'
import actions from './profile.actions'

import { getField, updateField } from 'vuex-map-fields'

const initialState = () => ({
  selectedInstitution: {},
  peerGroup: null,
  span: null,
  currentPeriod: null,
  reportId: null,
  reportProgress: null,
  reportStatus: 'Preparing...',
  userSections: [],
  sectionConfigs: {},
  profileDetail: {},
  profitCards: [],
  digitalChannelsCards: [],
  growthCards: [],
  riskCards: [],
  offeringCards: [],
  strategyCards: [],
  marketCards: [],
  technographicCards: [],
  mergerHistory: [],
  marketShareFIType: 'both',
  contacts: [],
  hasEmail: 'Yes',
  titles: [],
  roles: [],
  isMobile: false
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    ...getters,
    getField
  },
  mutations: {
    ...mutations,
    resetProfileState(state) {
      let baseState = initialState()

      Object.keys(baseState).forEach(key => {
        state[key] = baseState[key]
      })
    },
    updateField
  },
  actions
}
