export default {
  methods: {
    showSettings(e) {
      e.preventDefault()
      document.body.classList.remove('aside-menu-hidden')
      this.$store.dispatch('setAsideToogle', true)
    },
    closeSettings(e) {
      e.preventDefault()
      document.body.classList.add('aside-menu-hidden')
      this.$store.dispatch('setAsideToogle', false)
    }
  }
}
