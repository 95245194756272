import toBeAdded from '@/views/pages/toBeAdded'

const routes = {
  path: '/analytics/reporter',
  name: 'FIN Reporter',
  redirect: '/analytics/reporter/select_institution',
  meta: { requiredAuth: true },
  component: {
    render(c) {
      return c('router-view')
    }
  },
  children: [
    {
      path: 'about',
      name: 'About',
      component: toBeAdded
    },
    {
      path: 'select_institution',
      name: 'Select Institution',
      component: () => import('@/views/reporter/institution'),
      meta: {
        type: 'reporter',
        modulePath: '/analytics/reporter/select_institution'
      }
    },
    {
      path: 'order_institution',
      name: 'Order Institution',
      component: () => import('@/views/reporter/orderInstitution'),
      meta: {
        type: 'reporter',
        modulePath: '/analytics/reporter/select_institution'
      }
    },
    {
      path: 'select_peer_group',
      name: 'Select-Peer-Group',
      component: () => import('@/views/reporter/peerGroup'),
      meta: {
        type: 'reporter',
        modulePath: '/analytics/reporter/select_institution'
      }
    },
    {
      path: 'select_report',
      name: 'Select Report',
      component: () => import('@/views/reporter/report'),
      meta: {
        type: 'reporter',
        modulePath: '/analytics/reporter/select_institution'
      }
    },
    {
      path: 'metrics',
      name: 'Report View',
      component: () => import('@/modules/reporter/pages/metrics'),
      meta: {
        type: 'reporter',
        modulePath: '/analytics/reporter/select_institution'
      }
    }
  ]
}

export default routes
