export default {
  segmentRules(state) {
    return state.segmentRules
  },
  ruleID(state) {
    return option => {
      let rule = state.segmentRules.find(rule => rule.label === option)
      return rule && rule.id
    }
  },
  groupFIType(state) {
    return state.groupFIType
  },
  technographicRules(state) {
    return state.segmentRules.filter(qr => {
      return [
        'Retail Services',
        'Business Services',
        'Operating Solutions'
      ].includes(qr.label)
    })
  },
  baseSegmentOptions(state, getters, rootState, rootGetters) {
    const segOptions = [
      { value: null, text: 'Please Select', disabled: true },
      { value: getters.ruleID('Firmographics'), text: 'Firmographics' },
      { value: getters.ruleID('Market'), text: 'Market Area' },
      { value: getters.ruleID('Segmentation'), text: 'Segmentation' },
      { value: getters.ruleID('Technographics'), text: 'Technographics' },

      {
        label: 'Financial Data',
        options: [
          {
            value: getters.ruleID('Performographics'),
            text: 'Performographics'
          },
          {
            value: getters.ruleID('Common Metrics (Bank/CU)'),
            text: 'Common Metrics (Bank/CU)'
          },
          {
            value: getters.ruleID('Detailed Metrics'),
            text: 'Detailed Metrics'
          },
          {
            value: getters.ruleID('Common Statements (Bank/CU)'),
            text: 'Common Statements (Bank/CU)'
          },
          {
            value: getters.ruleID('Detailed Statements'),
            text: 'Detailed Statements'
          }
        ]
      }
    ]
    if (
      rootGetters['Ability/canShowDigitalChannels'] ||
      rootGetters['Ability/isDigitalChannelsAvailable']
    ) {
      segOptions.splice(5, 0, {
        value: getters.ruleID('Digital Channels'),
        text: 'Digital Channels'
      })
    }
    return segOptions
  }
}
