import Vue from 'vue'
import HoneybadgerVue from '@honeybadger-io/vue'

const config = {
  apiKey: 'e2283651',
  environment: process.env.NODE_ENV,
  revision: 'master'
}

if (process.env.NODE_ENV !== 'development') {
  Vue.use(HoneybadgerVue, config)
}
