import axios from 'axios'
import Qs from 'qs'

export default {
  reports() {
    return axios
      .get('/api/digital_channels/reports', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  reportFilters() {
    return axios
      .get(`/api/digital_channels/filters`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  reportData(selectedReport, filters) {
    return axios
      .get(`/api/digital_channels/${selectedReport.id}`, {
        params: {
          filters: filters
        },
        paramsSerializer: function(params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' })
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  overviewText(selectedReport) {
    return axios
      .get(`/api/digital_channels/${selectedReport.id}/overview`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
