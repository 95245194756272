export default {
  path: '/user',
  redirect: '/user/subscriptions',
  name: 'User',
  meta: { requiredAuth: true },
  component: () => import('./pages/user.js'),
  children: [
    {
      path: 'subscriptions',
      name: 'Subscription',
      component: () => import('./pages/Subscription'),
      meta: { type: 'user' }
    },
    {
      path: 'settings',
      name: 'Settings',
      component: () => import('./pages/Settings'),
      meta: { type: 'user' }
    }
  ]
}
