import Vue from 'vue'
import * as additionalGetters from './reporter.getters'
import * as additionalActions from './reporter.actions'
import * as additionalMutations from './reporter.mutations'

export default {
  state: {
    selectedInstitution: {},
    selected_peer_group: null,
    report_token: null,
    breakPointProgress: 0,
    reportProgress: 0,
    peer_groups: [],
    selected_span: 1,
    setId: 2,
    metric_args: {
      reportId: null,
      reportTypeId: null,
      reportType: null,
      metricsList: [],
      technographicReport: false
    },
    multi_metrics: {
      metrics: null,
      headers: null,
      peer_metrics: null,
      institutionName: null,
      metricsList: [],
      format: null,
      metricGroupId: null,
      metricsLoading: false
    },
    single_metrics: {
      metrics: null,
      headers: null,
      peer_metrics: null,
      institutionName: null,
      metricsList: [],
      individualMetricsList: [],
      metricGroupId: null,
      selectedMetric: null,
      metricsLoading: false,
      dataFormat: null,
      peerTableData: [],
      scoreMetricIds: []
    },
    period_metrics: {
      metrics: null,
      displayFormats: [],
      headers: null,
      peer_metrics: null,
      institutionName: null,
      metricsList: [],
      metricGroupId: null,
      metricsLoading: false,
      selectedPeriod: null,
      periodList: []
    },
    allSingleMetricsData: null,
    printWithSingleMetricsData: false
  },
  getters: {
    ...additionalGetters,
    peer_groups: state => {
      return state.peer_groups
    },
    selected_peer_group: state => {
      return state.selected_peer_group
    },
    report_token: state => {
      return state.report_token
    },
    breakPointProgress: state => {
      return state.breakPointProgress
    },
    reportProgress: state => {
      return state.reportProgress
    },
    selected_span: state => {
      return state.selected_span
    },
    metric_args: state => {
      return state.metric_args
    },
    multi_metrics: state => {
      return state.multi_metrics
    },
    single_metrics: state => {
      return state.single_metrics
    },
    period_metrics: state => {
      return state.period_metrics
    },
    single_metric_select_group: state => {
      return state.selectedSingleMetricGroup
    },
    single_metric_select_view: state => {
      return state.selectedSingleMetric
    },
    selectedInstitution: state => {
      return state.selectedInstitution
    },
    s3LogoUrl: state => {
      if (state.selectedInstitution) {
        return (
          'https://finapps-logos.s3.amazonaws.com/logo_' +
          state.selectedInstitution.id +
          '.png'
        )
      } else {
        return '/'
      }
    }
  },
  mutations: {
    ...additionalMutations,
    updateReportTypeId(state, reportTypeId) {
      Vue.set(state.metric_args, 'reportTypeId', reportTypeId)
    },

    updateReportId(state, reportId) {
      Vue.set(state.metric_args, 'reportId', reportId)
    },

    peer_groups(state, payload) {
      state.peer_groups = payload
    },

    selected_span(state, payload) {
      state.selected_span = payload
    },

    setPeerGroupId(state, peerGroupId) {
      state.selected_peer_group = peerGroupId
    },

    setReportToken(state, report_token) {
      state.report_token = report_token
    },

    metric_args(state, reportId) {
      Vue.set(state.metric_args, 'reportId', reportId)
    },

    update_metric_args(state, payload) {
      Object.entries(payload).forEach(function([key, value]) {
        Vue.set(state.metric_args, key, value)
      }, state)
    },

    multi_metrics(state, payload) {
      Object.entries(payload).forEach(function([key, value]) {
        this.multi_metrics[key] = value
      }, state)
    },

    single_metrics(state, payload) {
      Object.entries(payload).forEach(function([key, value]) {
        this.single_metrics[key] = value
      }, state)
    },

    period_metrics(state, payload) {
      Object.entries(payload).forEach(function([key, value]) {
        this.period_metrics[key] = value
      }, state)
    },

    singleMetricSelectGroup(state, metricGroup) {
      state.selectedSingleMetricGroup = metricGroup
    },

    singleMetricSelectView(state, metric) {
      state.selectedSingleMetric = metric
    },

    resetReporter(state) {
      state.selectedInstitution = {}
      state.selected_peer_group = null
      state.metric_args = {}
      state.breakPointProgress = 0
      state.reportProgress = 0
    },

    setInstitution(state, institution) {
      state.selectedInstitution = institution
      state.selected_peer_group = null
      state.metric_args = {}
      state.breakPointProgress = 0
      state.reportProgress = 0
    },

    setPeerGroup(state, peerGroupId) {
      state.selected_peer_group = peerGroupId
      state.metric_args = {}
      state.breakPointProgress = 0
      state.reportProgress = 0
    },
    setBreakPointProgress(state, payload) {
      state.breakPointProgress = payload
    },
    setReportProgress(state, payload) {
      state.reportProgress = payload
    }
  },
  actions: {
    ...additionalActions
  }
}
