<template>
  <div v-if="authorizedUser">
    <b-nav-item-dropdown right no-caret>
      <template slot="button-content">
        <v-gravatar
          :email="logedInUserEmail"
          class="img-avatar"
          :alt="logedInUserEmail"
          :title="logedInUserEmail"
          default-img="https%3A%2F%2Ffinapps-images.s3-us-west-2.amazonaws.com%2Fuser_default.png"
          v-if="currentUserEmail"
        />
      </template>

      <div v-if="manageSuperAdminBoard" class="super-admin-actions">
        <b-dropdown-header tag="div" class="text-center"
          ><strong>FIN Admin</strong></b-dropdown-header
        >
        <b-dropdown-item href="/super_admin/dashboard"
          ><i class="fa fa-columns"></i> Dashboard
        </b-dropdown-item>
      </div>

      <div v-if="manageAdminBoard" class="admin-actions">
        <b-dropdown-header tag="div" class="text-center"
          ><strong>Client Admin</strong></b-dropdown-header
        >
        <b-dropdown-item href="/admin/groups"
          ><i class="icon-layers"></i> Groups
        </b-dropdown-item>
        <b-dropdown-item href="/admin/users"
          ><i class="fa fa-users"></i> Users
        </b-dropdown-item>
        <b-dropdown-item href="/admin/group_profile_settings"
          ><i class="fa fa-user-secret"></i> FIN Profile Settings
        </b-dropdown-item>
        <b-dropdown-item href="/admin/user_activity"
          ><i class="fa fa-user-secret"></i> User Activity
        </b-dropdown-item>
        <b-dropdown-item href="/admin/user_order_report"
          ><i class="fa fa-shopping-cart"></i> User Orders
        </b-dropdown-item>
        <b-dropdown-item href="/admin/direct_access_defaults"
          ><i class="fa fa-sliders"></i> Single Click Defaults
        </b-dropdown-item>
      </div>

      <div v-if="managePartnerAdminBoard && partnerSite" class="admin-actions">
        <b-dropdown-header tag="div" class="text-center"
          ><strong>Partner Admin</strong></b-dropdown-header
        >
        <b-dropdown-item href="/partner_admin/groups"
          ><i class="icon-layers"></i> Groups
        </b-dropdown-item>
        <b-dropdown-item href="/partner_admin/users"
          ><i class="fa fa-users"></i> Users
        </b-dropdown-item>
        <b-dropdown-item href="/partner_admin/user_activity"
          ><i class="fa fa-user-secret"></i> User Activity
        </b-dropdown-item>
      </div>

      <b-dropdown-divider class="dd-divider"></b-dropdown-divider>

      <b-dropdown-item
        href="/user/subscriptions"
        v-if="currentUserEmail && !partnerSite"
        ><i class="fa fa-bell"></i> Subscriptions
      </b-dropdown-item>
      <b-dropdown-item
        href="/user/settings"
        v-if="currentUserEmail && manageUserSettings"
        ><i class="fa fa-cog"></i> Settings
      </b-dropdown-item>
      <b-dropdown-item href="https://fi-navigator.com/contact" target="_blank"
        ><i class="fa fa-envelope-open"></i> Contact Us
      </b-dropdown-item>
      <b-dropdown-item v-if="!partnerSite" @click="logOutUser"
        ><i class="fa fa-lock"></i> Logout
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>
  <div v-else class="login-link">
    <router-link
      tag="button"
      :to="{ path: '/sign_in' }"
      @click="logOutUser"
      class="btn btn-outline-info btn-sm"
    >
      SIGN IN
    </router-link>

    <b-dropdown-item href="https://fi-navigator.com/contact" target="_blank"
      ><i class="fa fa-envelope-open"></i> Contact Us
    </b-dropdown-item>
  </div>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'header-dropdown-accnt',
  methods: {
    ...mapActions('Authentication', ['logOut']),
    logOutUser: function() {
      this.logOut()
    }
  },
  computed: {
    ...mapState({
      partnerSite: state => state.partnerSite
    }),
    ...mapGetters('Authentication', ['currentUserEmail', 'authorizedUser']),
    ...mapGetters('Ability', [
      'manageAdminBoard',
      'manageUserSettings',
      'manageSuperAdminBoard',
      'managePartnerAdminBoard'
    ]),
    logedInUserEmail() {
      return this.currentUserEmail
    }
  }
}
</script>

<style lang="scss" scoped>
.login-link {
  margin: 0 10px;
  button {
    font-weight: bold;
  }
}

.dd-divider {
  hr {
    margin: 0;
    border: solid 1px grey;
  }
}

.img-avatar {
  border: solid 2px #e4e5e6;
}
.img-help {
  border: none;
  margin-right: 0rem;
}
</style>

<style lang="scss">
.dropdown-menu {
  &.dropdown-menu-right {
    min-width: 12rem;
    margin-top: 0.25rem;
    margin-right: 0.75rem;

    .dropdown-item:last-child {
      border-bottom: solid 1px #c2cfd6;
    }

    .super-admin-actions,
    .admin-actions {
      .dropdown-item {
        padding-top: 0.25rem;
        padding-bottom: 0.25rem;
      }
    }
  }
}
</style>
