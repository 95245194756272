export default {
  state: {
    showAside: false,
    asideOpened: false,
    multiMetricView: true,
    reportView: 'multi',
    showPeers: true,
    showSparkGraph: true,
    spans: [],
    peer_groups: [],
    chartType: 'bar'
  },
  mutations: {
    spans(state, payload) {
      state.spans = payload
    },
    peer_groups(state, payload) {
      state.peer_groups = payload
    },
    setReportView(state, value) {
      state.reportView = value
    },
    togglePeerGroup(state, value) {
      state.showPeers = value
    },
    toggleSparkGraph(state, value) {
      state.showSparkGraph = value
    },
    setAsideToogle(state, value) {
      state.asideOpened = value
    },
    setAside(state, value) {
      state.showAside = value
    },
    setChartType(state, value) {
      state.chartType = value
    }
  },
  actions: {
    setReportView({ commit }, value) {
      commit('setReportView', value)
    },
    togglePeerGroup({ commit }, value) {
      commit('togglePeerGroup', value)
    },
    toggleSparkGraph({ commit }, value) {
      commit('toggleSparkGraph', value)
    },
    setAside({ commit }, value) {
      commit('setAside', value)
    },
    setAsideToogle({ commit }, value) {
      commit('setAsideToogle', value)
    }
  },
  getters: {
    spans: state => {
      return state.spans
    },
    showAside: state => {
      return state.showAside
    },
    isAsideOpen: state => {
      return state.asideOpened
    },
    isMultiMetricView: state => {
      return state.reportView =='multi'
    },
    isSingleMetricView: state => {
      return state.reportView =='single'
    },
    isPeriodView: state => {
      return state.reportView =='period'
    },
    isPeerTableView: state => {
      return state.chartType == 'peer'
    },
    reportView: state => {
      return state.reportView
    },
    showPeers: state => {
      return state.showPeers
    },
    showSparkGraph: state => {
      return state.showSparkGraph
    },
    chartType: state => {
      return state.chartType
    }
  }
}
