<template>
  <div class="app">
    <AppHeader />
    <div class="app-body">
      <Sidebar :navItems="sidebarApps" />
      <main class="main">
        <div class="container-fluid">
          <router-view></router-view>
        </div>
      </main>
      <AppAside />
    </div>
    <AppFooter />
  </div>
</template>

<script>
import {
  Header as AppHeader,
  Aside as AppAside,
  Sidebar,
  Footer as AppFooter
} from '../components/'
import { mapState } from 'vuex'

export default {
  name: 'full',
  components: {
    AppHeader,
    AppAside,
    Sidebar,
    AppFooter
  },
  created() {},
  computed: {
    ...mapState({
      sidebarApps: state => {
        return state.Apps.sidebarApps
      }
    })
  },
  methods: {}
}
</script>

<style lang="scss">
.b-form-group.required .col-form-label:after {
  content: '*';
  color: red;
}

.app-body {
  .main {
    .container-fluid {
      padding: 10px 10px;
    }
  }
}
</style>
