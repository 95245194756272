//global
import store from '@/store/store.js'

class ReportRetransformer {
  constructor(report) {
    this.report = store.getters['ReportWriter/customReport']
    this.elementFormat = report.element_format
    this.reportElements = store.getters['ReportWriter/customElements']
  }

  basicCustomizedElFormat(reportEl) {
    return {
      id: reportEl.seqID,
      type: 'element',
      text: reportEl.name,
      data: reportEl,
      children: []
    }
  }

  matchingReportElement(elFormat) {
    return this.reportElements.find(rel => rel.seqID === elFormat.id)
  }

  matchingMetricElement(metric) {
    return this.reportElements.find(
      re => re.id === metric.id && re.type === metric.type
    )
  }

  prepareCustomizedElText(elFormat) {
    if (elFormat.type === 'element') {
      return this.matchingReportElement(elFormat)
        ? this.matchingReportElement(elFormat).name
        : ''
    } else {
      return elFormat.text || ''
    }
  }

  prepareCustomizedElData(elFormat) {
    if (elFormat.type === 'element') {
      return this.matchingReportElement(elFormat)
    } else if (elFormat.type === 'graph') {
      return {
        metrics: elFormat.metrics.map(metric => {
          return this.matchingMetricElement(metric)
        })
      }
    } else {
      return {}
    }
  }

  retransformElementFormat(elFormat) {
    return {
      id: elFormat.id,
      type: elFormat.type,
      text: this.prepareCustomizedElText(elFormat),
      data: this.prepareCustomizedElData(elFormat),
      editable: false,
      children: (elFormat.children || []).map(child =>
        this.retransformElementFormat(child)
      )
    }
  }

  prepareAndSetCustomizedElements() {
    let customizedElements

    if (this.elementFormat) {
      customizedElements = this.elementFormat.map(ef => {
        return this.retransformElementFormat(ef)
      })
    } else {
      customizedElements = this.reportElements.map(re => {
        return this.basicCustomizedElFormat(re)
      })
    }
    store.commit('ReportWriter/updateCustomizedElements', customizedElements)
  }

  getLatestSequenceID(element, latestSeqID) {
    if (element.id > latestSeqID) {
      latestSeqID = element.id
    }
    if (element.children && element.children.length) {
      element.children.forEach(childEl => {
        latestSeqID = this.getLatestSequenceID(childEl, latestSeqID)
      })
    }

    return latestSeqID
  }

  setElementSequenceID() {
    let latestSeqID = 0

    if (this.elementFormat) {
      this.elementFormat.forEach(
        ef =>
          (latestSeqID =
            this.getLatestSequenceID(ef, latestSeqID) || latestSeqID)
      )
    } else {
      this.reportElements.forEach(re => {
        if (re.seqID > latestSeqID) {
          latestSeqID = re.seqID
        }
      })
    }

    store.commit('ReportWriter/setElementSequenceID', latestSeqID)
  }

  retransform() {
    this.setElementSequenceID()
    this.prepareAndSetCustomizedElements()
  }
}

export default ReportRetransformer
