var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('router-link',{class:[
    'open',
    'nav-item',
    'nav-dropdown',
    _vm.togglable ? 'togglable-dd' : 'no-togglable-dd'
  ],attrs:{"tag":"li","to":_vm.url,"disabled":""}},[_c('div',{class:[
      'nav-link',
      'nav-dropdown-toggle',
      _vm.togglable ? 'togglable' : 'no-togglable'
    ],on:{"click":_vm.handleToggle}},[_c('i',{class:_vm.icon}),_vm._v(" "+_vm._s(_vm.name)+" ")]),_c('ul',{staticClass:"nav-dropdown-items"},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }