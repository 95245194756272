import axios from 'axios'

export default {
  subscriptions() {
    return axios
      .get('/api/users/subscriptions', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  enabledModules() {
    return axios
      .get('/api/users/enabled_modules', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  enterprisePermissions() {
    return axios
      .get('/api/users/enterprise_value', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  fin_api_key() {
    return axios
      .get(`/api/users/fin_api_key`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
