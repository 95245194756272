import getters from './digital.channels.getters'
import mutations from './digital.channels.mutations'
import actions from './digital.channels.actions'

import { getField, updateField } from 'vuex-map-fields'

const initialState = () => ({
  reportTypes: [],
  reportFilters: [],
  selectedReport: null,
  reportData: [],
  dataFormat: [],
  reportTotal: {},
  reportHeaders: [],
  reportOtherData: null,
  selectedReportFilters: null,
  loadingReport: false,
  currentPeriod: null,
  currentPeriodOptions: null,
  previousPeriod: null,
  previousPeriodOptions: null,
  beginningPeriod: null,
  beginningPeriodOptions: null,
  marketMeasure: null,
  marketMeasureOptions: null,
  olbMarketMeasure: null,
  olbMarketMeasureOptions: null,
  clientSizeMetric: null,
  clientSizeMetricOptions: null,
  olbClientSizeMetric: null,
  olbClientSizeMetricOptions: null,
  satisfactionMeasure: null,
  satisfactionMeasureOptions: null,
  topVendors: 15,
  topVendorsMax: 15,
  topVendorsOptions: null,
  showAllVendors: false,
  showAllFeatures: false,
  showMbProducts: null,
  showMbProductsOptions: null,
  fiType: null,
  fiTypeOptions: null,
  fiAssetGroup: null,
  fiAssetGroupOptions: null,
  mbAgeGroup: null,
  mbAgeGroupOptions: null,
  featureMbAgeGroup: null,
  featureMbAgeGroupOptions: null,
  mbVendor: null,
  mbVendorOptions: null,
  vendorBranded: null,
  vendorBrandedOptions: null,
  appType: null,
  appTypeOptions: null,
  knownVendor: null,
  knownVendorOptions: null,
  multiSelectFilterChanged: false,
  dateLevelOptions: null,
  dateLevel: null,
  downloadDCReportData: null,
  topFeatures: 5,
  topFeaturesMax: 10,
  name: null,
  selectedReportModule: 'Retail Mobile Banking',
  listenerList: [],
  reportIsPrintable: null
})

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters: {
    ...getters,
    getField
  },
  mutations: {
    ...mutations,
    resetDigitalChannelsState(state) {
      let baseState = initialState()

      Object.keys(baseState).forEach(key => {
        state[key] = baseState[key]
      })
    },
    updateField
  }
}
