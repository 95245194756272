const authRoutes = [
  {
    path: '/sign_in',
    name: 'SignIn',
    component: () => import('./pages/Login')
  },
  {
    path: '/sign_up',
    name: 'Register',
    component: () => import('./pages/Register')
  },
  {
    path: '/terms_of_service',
    name: 'TermsOfService',
    component: () => import('./pages/TermsOfServiceNoAuth')
  },
  {
    path: '/new_password/:id/edit',
    name: 'new_password',
    component: () => import('./pages/NewPassword')
  },
  {
    path: '/verify_email',
    name: 'VerifyEmail',
    component: () => import('./pages/VerifyEmail')
  },
  {
    path: '/sso',
    name: 'SSO',
    component: () => import('@/partners/ethos/pages/SSO')
  }
]

export default authRoutes
