import firebase from 'firebase/app'
import 'firebase/database'
import customAPI from '@/modules/reporter/api/metrics.custom.js'

export const updateBreakPointProgress = function({ commit, state }) {
  if (state.breakPointProgress === 100) {
    return true
  }

  var starCountRef = firebase
    .database()
    .ref('peer_group/' + state.selected_peer_group)
  starCountRef.on('value', function(snapshot) {
    let snap = snapshot.val() || { progress: 0 }
    commit('setBreakPointProgress', snap.progress)
  })
}

export const updateReportProgress = function({ commit, state }) {
  if (state.reportProgress === 100) {
    return true
  }

  var reportRef = firebase.database().ref(`reports/${state.report_token}`)
  reportRef.on('value', function(snapshot) {
    let snap = snapshot.val() || { progress: 0 }
    commit('setReportProgress', snap.progress)
  })
}

export const updateReportProgressAndGetMetrics = function(context) {
  if (context.state.reportProgress === 100) {
    return true
  }

  var reportRef = firebase
    .database()
    .ref(`reports/${context.state.report_token}`)
  reportRef.on('value', function(snapshot) {
    let snap = snapshot.val() || { progress: 0 }
    context.commit('setReportProgress', snap.progress)
    if (snap.progress === 100) {
      if (context.state.reportView == 'period') {
        return customAPI.getPeriodMetricDataWithContext(context)
      }
      return context.rootGetters['reportView']
        ? customAPI.getMultiMetricDataWithContext(context)
        : customAPI.getSingleMetricDataWithContext(context)
    }
  })
}
export const getAllSingleMetricsData = function({ commit }) {
  return customAPI.getAllSingleMetricsData().then(metricsData => {
    return commit('setAllSingleMetricsData', metricsData)
  })
}
