<template>
  <li :class="classList" @click="resetApp">
    <template v-if="wrapper && wrapper.element">
      <component v-bind:is="wrapper.element" v-bind="wrapper.attributes">
        {{ name }}
      </component>
    </template>
    <template v-else>
      {{ name }}
    </template>
  </li>
</template>

<script>
import router from '@/router'

export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    },
    wrapper: {
      type: Object,
      default: () => {}
    }
  },
  computed: {
    classList() {
      return ['nav-title', ...this.itemClasses]
    },
    itemClasses() {
      return this.classes ? this.classes.split(' ') : ''
    }
  },
  methods: {
    resetApp(e) {
      let classes = e.target.classList
      if ([...classes].includes('home-link')) {
        router.push('/')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.home-link {
  cursor: pointer;
  color: initial;
  font-size: 13px;
}
</style>
