export default {
  headerStyle: sheet => {
    sheet.getRow(2).eachCell(cell => {
      cell.style = {
        font: {
          name: 'Calibri',
          bold: true,
          size: 12
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFE4E5E6' }
        },
        border: {
          top: { style: 'double', color: { argb: 'FF525252' } },
          left: { style: 'double', color: { argb: 'FF525252' } },
          bottom: { style: 'double', color: { argb: 'FF525252' } },
          right: { style: 'double', color: { argb: 'FF525252' } }
        }
      }
    })
  },
  highlightedRowStyle: (sheet, row) => {
    sheet.getRow(row).eachCell(cell => {
      cell.style = {
        font: {
          name: 'Calibri',
          bold: true,
          size: 12
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFE4E5E6' }
        },
        border: {
          top: { style: 'double', color: { argb: 'FF525252' } },
          left: { style: 'double', color: { argb: 'FF525252' } },
          bottom: { style: 'double', color: { argb: 'FF525252' } },
          right: { style: 'double', color: { argb: 'FF525252' } }
        },
        numFmt: cell.numFmt,
        alignment: cell.alignment
      }
    })
  },
  yearHeaderStyle: (sheet, row) => {
    sheet.getRow(row).eachCell(cell => {
      cell.style = {
        font: {
          name: 'Calibri',
          bold: true,
          size: 12
        },
        fill: {
          type: 'pattern',
          pattern: 'solid',
          fgColor: { argb: 'FFE4E5E6' }
        },
        numFmt: cell.numFmt,
        alignment: cell.alignment
      }
    })
  }
}
