export default {
  state: {
    sidebarApps: []
  },
  mutations: {
    setSidebarApps(state, apps) {
      state.sidebarApps = apps
    }
  }
}
