import axios from 'axios'

export const latestRevision = async () => {
  try {
    let response = await axios.get('/api/status/latest_revision', {
      handleErrors: false
    })
    return Promise.resolve(response.data.revision)
  } catch (error) {
    return Promise.resolve(null)
  }
}
