export const setUserData = (state, userData) => {
  state.userId = userData.id
  state.userName = userData.user_name
  state.userEmail = userData.email
  state.userCompany = userData.company_type
  state.companyType = userData.company_type
  state.userCreatedDate = userData.created_date
  state.isDemoUser = userData.is_demo_user
  state.userRole = userData.role
  state.userGroups = userData.groups
  state.isActive = userData.is_active
  state.inactiveEmail = null
  state.mockUser = false
  state.isFiMenu = userData.is_fi_menu
}

export const resetAuthData = state => {
  state.userId = null
  state.userName = null
  state.userEmail = null
  state.userCompany = null
  state.companyType = null
  state.userCreatedDate = null
  state.isDemoUser = false
  state.isActive = false
  state.userRole = {}
  state.userGroups = []
  state.inactiveEmail = null
  state.mockUser = false
  state.isFiMenu = false
  state.isDirectLogin = false
}

export const setUserGroups = function(state, groups) {
  state.userGroups = groups
}

export const setIsUserActive = (state, payload) => {
  state.isActive = payload
}

export const setUserInactiveEmail = (state, payload) => {
  state.inactiveEmail = payload
}

export const setMockUser = (state, payload) => {
  state.mockUser = payload
}

export const setIsDirectLogin = (state, payload) => {
  state.isDirectLogin = payload
}
