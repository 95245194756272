import axios from 'axios'

export default {
  rules(isMbc) {
    return axios
      .get('/api/query_rules?is_mbc=' + isMbc, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
