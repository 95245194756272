import axios from 'axios'
import Qs from 'qs'
import * as moment from 'moment'

export default {
  all: () => {
    return axios
      .get('/api/users', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  groupUsers: groupIDs => {
    return axios
      .get('/api/super_admin/user_groups/users', {
        params: {
          user_groups: {
            group_ids: groupIDs
          }
        },
        paramsSerializer: function(params) {
          return Qs.stringify(params, {
            arrayFormat: 'brackets',
            serializeDate: date => moment(date).format('YYYY-MM-DDTHH:mm:ssZ')
          })
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
