import axios from 'axios'
import app from '../main'
import Vue from 'vue'
import router from '@/router'
import store from '@/store/store'

axios.defaults.baseURL = process.env.VUE_APP_API_URL
axios.defaults.withCredentials = true
axios.defaults.headers.Accept = 'application/json'

const renderLogin = () => {
  app.vueApp.$store.dispatch('Authentication/resetStoreData')
  app.vueApp.$store.dispatch('setRedirectUrl', app.vueApp.$route.fullPath)
  router.push('/sign_in')
}

const restrictedPage = () => {
  Vue.toasted.show('Page access restricted. Please contact admin.', {
    icon: 'chain-broken',
    type: 'error'
  })
  router.push('/dashboard')
}

const somethingWrong = () => {
  Vue.toasted.show(
    'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
    {
      icon: 'chain-broken',
      type: 'error'
    }
  )
}

const setSessionExpiration = sessionExpired => {
  store.commit('setSessionExpiry', sessionExpired)
}

axios.interceptors.request.use(config => {
  app.vueApp.$Progress.start()
  return config
})

axios.interceptors.response.use(
  response => {
    setSessionExpiration(response.headers.guest)
    app.vueApp.$Progress.finish()
    return Promise.resolve(response)
  },
  error => {
    app.vueApp.$Progress.fail()
    if (error.config.handleErrors) {
      error.response.status === 401
        ? renderLogin()
        : error.response.status === 403
        ? restrictedPage()
        : somethingWrong()
    }
    return Promise.reject(error)
  }
)
