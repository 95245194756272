export default {
  customReport(state) {
    return state.selectedReport
  },
  firmographicsOptions(state) {
    return state.firmographicOptions
  },
  segmentationOptions(state) {
    return state.segmentationOptions
  },
  performographicsOptions(state) {
    return state.performographicOptions
  },
  marketAreaOptions(state) {
    return state.marketAreaOptions
  },
  digitalChannelsOptions(state) {
    return state.digitalChannelOptions
  },
  mortgageLendingOptions() {
    return []
  },
  financialStatementsOptions() {
    return []
  },
  commonFinancialStatementsOptions() {
    return []
  },
  technographicsOptions() {
    return []
  },
  metricsOptions() {
    return []
  },
  commonMetricOptions() {
    return []
  },
  vendorFitStaticOptions(state) {
    return state.vendorFitOptions
  },
  fiContactsOptions(state) {
    return state.fiContactsOptions
  },
  defaultOptions(state) {
    return state.defaultOptions
  },
  defaultElements(state) {
    return state.defaultOptions.map(deo => deo.property)
  },
  defaultMBCOptions(state) {
    return state.firmographicMBCDefaultOptions.map(deo => deo.property)
  },
  customElements(state) {
    return state.reportElements
  },
  customizedReportElements(state) {
    return state.customizedReportElements
  },
  allMetricAndStatementIDs(state) {
    return state.allMetricAndStatementIDs
  },
  elementSequenceID(state) {
    return state.elementSequenceID
  },
  reportElementsWithNoDefaults(state) {
    return state.reportElements.filter(re => {
      return !state.defaultOptions.find(de => de.property === re.property)
    })
  },
  accessibleSegments(state, getters, rootState, rootGetters) {
    //console.log(rootState.Ability.canPurchase)

    let validSegments = state.segments

    if (!rootGetters['Ability/canVendorFit']) {
      validSegments = validSegments.filter(segment => segment !== 'VendorFit')
    }

    return validSegments
  },
  accessibleSegmentsForMBC(state) {
    return state.segmentsForMBC
  },
  isFIContactsFiltered(state) {
    return state.reportElements.find(
      re =>
        re.type === 'fi_contacts' &&
        re.property === 'include_contacts' &&
        re.excludeFI === true
    )
  },
  globalSettings(state) {
    return state.globalSettings
  },
  globalFinMetrics(state) {
    return state.globalFinMetrics
  },
  globalFinStatements(state) {
    return state.globalFinStatements
  },
  globalTechnographics(state) {
    return state.globalTechnographics
  }
}
