// utils
import { s3URLFor } from '@/utilities'

export default {
  selectedInstitution(state) {
    return state.selectedInstitution
  },
  selectedSolution(state) {
    return state.selectedSolution
  },
  fiLogoURL(state) {
    return state.selectedInstitution
      ? s3URLFor('fi', state.selectedInstitution.id)
      : '/'
  },
  vendorLogoURL(state) {
    return state.selectedVendor
      ? s3URLFor('vendor', state.selectedVendor.vendor_id)
      : '/'
  }
}
