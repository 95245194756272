import axios from 'axios'

export default {
  decisionTree: (reportId, options) => {
    return axios
      .get('/api/metrics/' + options.metricId + '/decision_tree', {
        params: {
          set_id: options.setId,
          tree_id: options.treeId,
          product_id: options.productId,
          bank_service_id: options.bankServiceId,
          report_id: reportId
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
