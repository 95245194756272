import api from '../api/user.profile'
import firebase from 'firebase/app'
import ahoy from 'ahoy.js'

const fbReportListener = (reportToken, commit) => {
  let reportReference = firebase.database().ref(`reports/${reportToken}`)
  return reportReference.on('value', snapshot => {
    let report = snapshot.val()
    if (report) {
      commit('setReportProgress', report.progress)
      commit('setReportStatus', report.status)
    } else {
      commit('setReportProgress', 0)
      commit('setReportStatus', 'Record not found')
    }
  })
}

export default {
  fetchReportProgress({ state, commit, rootGetters }) {
    if (rootGetters['Ability/isProfilePartiallyEnabled']) {
      commit('setReportId', '')
      commit('setReportProgress', 100)
      return
    } else {
      return api.reportDetails(state.selectedInstitution.id).then(details => {
        commit('setReportId', details.report.id)
        commit('setReportProgress', details.report.progress)
        if (details.report.progress !== 100) {
          fbReportListener(details.report_token, commit)
        }
      })
    }
  },

  fetchUserSections({ state, commit, rootGetters }) {
    return api.userSections().then(sections => {
      if (rootGetters['Ability/isProfilePartiallyEnabled']) {
        sections = sections.filter(s => {
          return s.name == 'Digital Channels' || s.name == 'Bank Profile'
        })
      }
      if (
        !rootGetters['Ability/canShowDigitalChannels'] ||
        state.selectedInstitution.fiType === 'bhc'
      ) {
        sections = sections.filter(s => {
          return s.name != 'Digital Channels'
        })
      }
      if (
        !rootGetters['Ability/isFIContactsAvailable'] ||
        state.selectedInstitution.fiType === 'bhc'
      ) {
        sections = sections.filter(s => {
          return s.name != 'FI Contacts'
        })
      }
      // console.log(sections)
      commit('setUserSections', sections)
    })
  },

  fetchSectionConfigs({ state, commit }) {
    return api
      .sectionConfigs(state.selectedInstitution.id, state.reportId)
      .then(configs => {
        commit('setSectionConfigs', configs)
      })
  },

  resetReportProgress({ commit }) {
    commit('setReportProgress', 0)
  },

  updateSectionConfigs({ state, commit, rootGetters }, payload) {
    let reportIdBeforeUpdate = state.reportId

    return api
      .updateSectionConfigs(
        payload,
        state.selectedInstitution.id,
        state.reportId
      )
      .then(configs => {
        commit('resetProfileData')
        commit('setSectionConfigs', configs)
        if (rootGetters['Ability/isProfilePartiallyEnabled']) {
          commit('setReportId', '')
          commit('setReportProgress', 100)
        } else {
          api.reportDetails(state.selectedInstitution.id).then(details => {
            commit('setReportId', details.report.id)
            commit('setReportProgress', details.report.progress)

            if (reportIdBeforeUpdate !== state.reportId) {
              if (state.reportId != '') {
                ahoy.track('report_view', {
                  fi_id: state.selectedInstitution.id,
                  report_id: state.reportId,
                  report_type: 'FIN-P'
                })
              } else {
                ahoy.track('report_view', {
                  fi_id: state.selectedInstitution.id,
                  report_type: 'FIN-P'
                })
              }
            }

            if (details.report.progress !== 100) {
              fbReportListener(details.report_token, commit)
            }
          })
        }
      })
  },

  fetchBankProfileDetails({ state, commit }, options) {
    if (Object.keys(state.profileDetail).length) {
      return Promise.resolve()
    }

    return api.fiProfileDetails(options.fiId).then(res => {
      commit('setFIProfileDetails', res)
    })
  },

  fetchProfitDetails({ state, commit }, options) {
    if (state.profitCards.length) {
      return Promise.resolve()
    }
    return api.metricDetails(options.fiId, options.sectionId).then(res => {
      commit('setMetricBaseInfo', {
        span: res.span,
        peerGroup: res.peer_group,
        currentPeriod: res.current_period
      })
      commit('setProfitCardDetails', res.cards)
    })
  },
  fetchDigitalChannelsDetails({ state, commit }, options) {
    if (state.digitalChannelsCards.length) {
      return Promise.resolve()
    }
    //pass peer group id here?
    return api.metricDetails(options.fiId, options.sectionId).then(res => {
      commit('setMetricBaseInfo', {
        span: res.span,
        //different peer group -change key name
        peerGroup: res.peer_group,
        currentPeriod: res.current_period
      })
      commit('setDigitalChannelsCards', res.cards)
    })
  },
  fetchContactDetails({ state, commit }, options) {
    if (state.contacts.length) {
      return Promise.resolve()
    }
    return api
      .contactDetails(
        options.fiId,
        options.hasEmail,
        options.titles,
        options.roles
      )
      .then(res => {
        commit('setContacts', res)
      })
  },
  fetchFiteredContactDetails({ commit }, options) {
    return api
      .contactDetails(
        options.fiId,
        options.hasEmail,
        options.titles,
        options.roles
      )
      .then(res => {
        commit('setContacts', res)
      })
  },
  fetchGrowthDetails({ state, commit }, options) {
    if (state.growthCards.length) {
      return Promise.resolve()
    }
    return api.metricDetails(options.fiId, options.sectionId).then(res => {
      commit('setMetricBaseInfo', {
        span: res.span,
        peerGroup: res.peer_group,
        currentPeriod: res.current_period
      })
      commit('setGrowthCardDetails', res.cards)
    })
  },

  fetchRiskDetails({ state, commit }, options) {
    if (state.riskCards.length) {
      return Promise.resolve()
    }
    return api.metricDetails(options.fiId, options.sectionId).then(res => {
      commit('setMetricBaseInfo', {
        span: res.span,
        peerGroup: res.peer_group,
        currentPeriod: res.current_period
      })
      commit('setRiskCardDetails', res.cards)
    })
  },

  fetchOfferingDetails({ state, commit }, options) {
    if (state.offeringCards.length) {
      return Promise.resolve()
    }

    return api.offeringServices(options.fiId).then(res => {
      commit('setPeerGroup', res.peer_group)
      commit('setOfferingDetails', res.cards)
    })
  },

  fetchStrategyDetails({ state, commit }, options) {
    if (state.strategyCards.length) {
      return Promise.resolve()
    }

    return api.metricDetails(options.fiId, options.sectionId).then(res => {
      commit('setStrategyDetails', res.cards)
    })
  },

  fetchMarketDetails({ state, commit }, options) {
    if (state.marketCards.length) {
      return Promise.resolve()
    }

    return api.metricDetails(options.fiId, options.sectionId).then(res => {
      commit('setMarketDetails', res.cards)
    })
  },

  fetchMarketDepositShare({ commit }, options) {
    return api.marketDepositShare(options.fiId, options.fiType).then(res => {
      commit('setMarketDepositShare', res)
    })
  },

  fetchTechnographicVendorsDetails({ state, commit }, options) {
    if (state.technographicCards.length) {
      return Promise.resolve()
    }

    return api.technographicDetails(options.fiId).then(offerings => {
      commit('setTechnographicDetails', offerings)
    })
  },

  fetchMergerHistoryDetails({ state, commit }, options) {
    if (state.mergerHistory.length) {
      return Promise.resolve()
    }

    return api.mergerHistory(options.fiId).then(history => {
      commit('setMergerHistoryDetails', history)
    })
  },

  fetchSegmentationInsightsDetails() {
    return Promise.resolve()
  },

  updateUserSections({ commit }, payload) {
    return api.updateUserSections(payload).then(() => {
      return commit('updateUserSections', payload)
    })
  }
}
