import Vue from 'vue'
import Toasted from 'vue-toasted'
import router from '../router'

const toasterOptions = {
  position: 'top-right',
  iconPack: 'fontawesome',
  duration: 3000,
  icon: 'check-circle-o',
  type: 'success',
  singleton: true
}

Vue.use(Toasted, toasterOptions)
Vue.use(Toasted, { router })

//Global Toasters
Vue.toasted.register(
  'action_success',
  message => {
    return message || 'Action successfully performed'
  },
  {
    type: 'success',
    icon: 'fa-check'
  }
)

Vue.toasted.register(
  'action_401',
  'You are not authorized. Please contact admin.',
  {
    type: 'error',
    icon: 'fa-exclamation'
  }
)

Vue.toasted.register(
  'invalid',
  message => {
    return message || 'Action invalid. Please check'
  },
  { type: 'error', icon: 'chain-broken' }
)

Vue.toasted.register(
  'validation_error',
  message => {
    return message || 'Validation failed. Please check'
  },
  { type: 'error', icon: 'chain-broken' }
)
