export const setFIInstitutionsList = function(state, institutions) {
  state.fiInstitutionsList = institutions
}

export const setQSInstitutions = function(state, institutions) {
  state.qsInstitutions = institutions
}

export const setASInstitutions = function(state, institutions) {
  state.asInstitutions = institutions
}

export const setFIStatesList = function(state, states) {
  state.fiStatesList = states
}

export const setSharedGroups = function(state, groups) {
  state.sharedGroups = groups
}

export const setSelectedInstitutions = function(state, selectedInstitutions) {
  state.selectedInstitutions = selectedInstitutions
}

export const setPeerGroup = function(state, payload) {
  Object.entries(payload).forEach(function([key, value]) {
    this.peerGroup[key] = value
  }, state)
}

export const setSavedPeerData = function(state, payload) {
  state.savedPeerGroupInfo.name = payload.peer_group.name || ''
  state.savedPeerGroupInfo.description = payload.peer_group.description || ''
  state.savedPeerGroupInfo.memberIds = payload.banks.map(bank => bank.id)
}

export const setQSSizeMetrics = function(state, payload) {
  state.qsSizeMetricsList = payload
}

export const setQSProfitabilityMetrics = function(state, payload) {
  state.qsProfitabilityMetricsList = payload
}

export const setQSStatesList = function(state, payload) {
  state.qsStatesList = payload
}

export const setQSRegionsList = function(state, payload) {
  state.qsRegionsList = payload
}

export const setFISizeDetail = function(state, payload) {
  state.qsFISizeDetail = payload
}

export const setFIProfitabilityDetail = function(state, payload) {
  state.qsFIProfitabilityDetail = payload
}

export const setFIGeoDetail = function(state, payload) {
  state.qsFIGeoDetail = payload
}

export const setAdvancedQueryFields = function(state, payload) {
  state.asCharterList = payload.fi_profile.charter
  state.asSubCharterList = payload.fi_profile.subchapters
  state.asAgeGroupList = payload.fi_profile.age_and_age_group
  state.asStateList = payload.fi_profile.geography.states
  state.asRegionList = payload.fi_profile.geography.regions
  state.asTypeOfMembershipsList = payload.fi_profile.type_of_memberships
  state.asLowIncomeDesignationsList = payload.fi_profile.low_income_designation
  state.asLoanMixList = payload.strategic_focus.loan_mix
  state.asDepositMixList = payload.strategic_focus.deposit_mix
  state.asFeeMixList = payload.strategic_focus.fee_mix
  state.asLoanFocusList = payload.strategic_focus.strategic_focus.loan_focus
  state.asFundingFocusList =
    payload.strategic_focus.strategic_focus.funding_focus
  state.asOBSFocusList = payload.strategic_focus.strategic_focus.obs_focus
  state.asProfitList = payload.performance.profitability
  state.asGrowthList = payload.performance.growth
  state.asRiskList = payload.performance.risk
  state.asProfitOpportunityList = payload.performance.primary_profit_opportunity
  state.asProfitStrengthList = payload.performance.primary_profit_strength
  state.asGrowthOpportunityList = payload.performance.primary_growth_opportunity
  state.asGrowthStrengthList = payload.performance.primary_growth_strength
  state.asRiskOpportunityList = payload.performance.primary_risk_opportunity
  state.asRiskStrengthList = payload.performance.primary_risk_strength
  state.asTechnographicsList = payload.offering
  state.asMarketSizeList = payload.fi_profile.market_size.map(ms => ms.name)
  state.asMarketCoverageList = payload.fi_profile.market_coverage
  state.asFiSpecialtyTypeList = payload.fi_profile.specialty_types
  state.asVendorDiversityList = payload.segmentations.vendor_diversity
  state.asRetailServiceInnovationList =
    payload.segmentations.innovation_score_retail
  state.asBusinessServiceInnovationList =
    payload.segmentations.innovation_score_business
  state.asMergerRiskList = payload.segmentations.merger_risk_score
  state.asFailureRiskList = payload.segmentations.failure_risk
  state.asOrganicAccountGrowthList =
    payload.segmentations.org_acct_growth_5_yr_score
  state.asReportedAccountGrowthList =
    payload.segmentations.acq_acct_growth_5_yr_score
  state.asAcquisitiveScoreList = payload.segmentations.acquisitive_score
}

export const setAdvancedQueryLabelValues = function(state, payload) {
  state.asProfileValues = payload.fi_profile
  state.asOfferingValues = payload.offering
  state.asPerformanceValues = payload.performance
  state.asStrategyValues = payload.strategic_focus
  state.asSegmentationValues = payload.segmentations
}

export const setASProfileFormValidity = function(state, isValid) {
  state.isASProfileFormValid = isValid
}

export const setASStrategyFormValidity = function(state, isValid) {
  state.isASStrategyFormValid = isValid
}

export const setASPerformanceFormValidity = function(state, isValid) {
  state.isASPerformanceFormValid = isValid
}

export const setASOfferingVendors = function(state, payload) {
  state.asOfferingVendorList[payload.index] = payload.vendors
}

export const setASOfferingProducts = function(state, payload) {
  state.asOfferingProductList[payload.index] = payload.products
}

export const resetASOfferingVendorList = function(state, index) {
  state.asOfferingVendorList[index] = []
}

export const resetASOfferingProductList = function(state, index) {
  state.asOfferingProductList[index] = []
}

export const setASOfferingFormValidity = function(state, isValid) {
  state.isASOfferingFormValid = isValid
}

export const setQSSelectAllResults = function(state, enabled) {
  state.qsSelectAllResults = enabled
}

export const setASSelectAllResults = function(state, enabled) {
  state.asSelectAllResults = enabled
}

export const resetQSSizeMetricData = function(state) {
  state.qsSizeMetric.min = null
  state.qsSizeMetric.max = null
}

export const resetQSProfitabilityMetricData = function(state) {
  state.qsProfitabilityMetric.min = null
  state.qsProfitabilityMetric.max = null
}

export const resetASLoanMetricData = function(state, index) {
  state.asLoanMix[index].min = null
  state.asLoanMix[index].max = null
}

export const resetASDepositMetricData = function(state, index) {
  state.asDepositMix[index].min = null
  state.asDepositMix[index].max = null
}

export const resetASFeeMetricData = function(state, index) {
  state.asFeeMix[index].min = null
  state.asFeeMix[index].max = null
}

export const resetASProfitMetricData = function(state, index) {
  state.asProfit[index].min = null
  state.asProfit[index].max = null
}

export const resetASGrowthMetricData = function(state, index) {
  state.asGrowth[index].min = null
  state.asGrowth[index].max = null
}

export const resetASRiskMetricData = function(state, index) {
  state.asRisk[index].min = null
  state.asRisk[index].max = null
}

export const resetOfferingVendor = function(state, index) {
  state.asOffering[index].vendor_id = null
}

export const resetOfferingProduct = function(state, index) {
  state.asOffering[index].product_id = null
}

export const resetPeerGroupFromUserInputs = function(state) {
  state.peerGroup = { id: null, name: null, description: null }
  state.selectedInstitutions = []
  state.qsInstitutions = []
  state.asInstitutions = []
}

export const resetQuickSearchUserInputs = function(state) {
  state.qsSizeMetric = { id: null, min: null, max: null }
  state.qsProfitabilityMetric = { id: null, min: null, max: null }
  state.qsStates = []
  state.qsRegions = []
}

export const resetAdvancedSearchUserInputs = function(state) {
  state.asTotalAsset = { min: null, max: null }
  state.asDepositAccount = { min: null, max: null }
  state.asBranches = { min: null, max: null }
  state.asCharters = []
  state.asSubCharter = null
  state.asMinorityOwned = null
  state.asGeographyType = 'regions'
  state.asStates = []
  state.asRegions = []
  state.asMarketCoverage = null
  state.asFiSpecialtyTypes = []
  state.asMarketSize = null
  state.asMarketShare = { min: null, max: null }
  state.asMarketRank = { min: null, max: null }
  state.asMarketGrowth = { min: null, max: null }
  state.asAge = { min: null, max: null }
  state.asAgeGroup = null
  state.asTypeOfMembership = ''
  state.asLowIncomeDesignation = null
  state.asLoanFocus = []
  state.asFundingFocus = []
  state.asOBSFocus = []
  state.asLoanMix = [
    { id: null, min: null, max: null },
    { id: null, min: null, max: null },
    { id: null, min: null, max: null }
  ]
  state.asDepositMix = [
    { id: null, min: null, max: null },
    { id: null, min: null, max: null },
    { id: null, min: null, max: null }
  ]
  state.asFeeMix = [
    { id: null, min: null, max: null },
    { id: null, min: null, max: null },
    { id: null, min: null, max: null }
  ]
  state.asProfit = [
    { id: null, min: null, max: null },
    { id: null, min: null, max: null },
    { id: null, min: null, max: null }
  ]
  state.asGrowth = [
    { id: null, min: null, max: null },
    { id: null, min: null, max: null },
    { id: null, min: null, max: null }
  ]
  state.asRisk = [
    { id: null, min: null, max: null },
    { id: null, min: null, max: null },
    { id: null, min: null, max: null }
  ]
  state.asProfitOpportunity = null
  state.asProfitStrength = null
  state.asGrowthOpportunity = null
  state.asGrowthStrength = null
  state.asRiskOpportunity = null
  state.asRiskStrength = null
  state.asOffering = [
    { bank_service_id: null, vendor_id: null, product_id: null },
    { bank_service_id: null, vendor_id: null, product_id: null },
    { bank_service_id: null, vendor_id: null, product_id: null }
  ]
  state.asVendorDiversity = []
  state.asRetailServiceInnovation = []
  state.asBusinessServiceInnovation = []
  state.asMergerRisk = []
  state.asFailureRisk = []
  state.asOrganicAccountGrowth = []
  state.asReportedAccountGrowth = []
  state.asAcquisitiveScore = []
}

export const resetAdvancedSearchFormValidity = function(state) {
  state.isASProfileFormValid = true
  state.isASStrategyFormValid = true
  state.isASPerformanceFormValid = true
  state.isASOfferingFormValid = true
}

export const resetCustomPeerGroupDetails = function(state) {
  state.fiStatesList = []
  state.userGroups = []
  state.sharedGroups = []
  state.fiInstitutionsList = []
  state.selectedInstitutions = []
  state.qsFISizeDetail = {}
  state.qsFIProfitabilityDetail = {}
  state.qsFIGeoDetail = { state: null, regions: null }
  state.asCharterList = []
  state.asSubCharterList = []
  state.asAgeGroupList = []
  state.asStateList = []
  state.asRegionList = []
  state.asTypeOfMembershipsList = []
  state.asLowIncomeDesignationsList = []
  state.asLoanMixList = []
  state.asDepositMixList = []
  state.asFeeMixList = []
  state.asLoanFocusList = []
  state.asFundingFocusList = []
  state.asOBSFocusList = []
  state.asProfitList = []
  state.asGrowthList = []
  state.asRiskList = []
  state.asProfitOpportunityList = []
  state.asProfitStrengthList = []
  state.asGrowthOpportunityList = []
  state.asGrowthStrengthList = []
  state.asRiskOpportunityList = []
  state.asRiskStrengthList = []
  state.asTechnographicsList = []
  state.asProfileValues = {}
  state.asOfferingValues = {}
  state.asPerformanceValues = {}
  state.asStrategyValues = {}
  state.asSegmentationValues = {}
  state.asMarketSizeList = []
  state.asMarketCoverageList = []
  state.asFiSpecialtyTypeList = []
}
