import axios from 'axios'
import store from '@/store/store.js'
import Excel from 'exceljs'
import downloadsAPI from '@/modules/core/api/downloads'
import downloadStyle from '@/mixins/ExcelDownloadStyle'
import { METRICVALUEFORMAT } from '@/utilities.js'
import MetricFormatter from '../helpers/metric.formatter.js'
const PAGE_SIZE = 500
const getSingleMetricDataWithStore = store => {
  if (
    store.getters.metric_args.reportId &&
    store.getters.single_metrics.metricGroupId
  ) {
    return axios
      .get('/api/self_serve_reports/view', {
        params: {
          id: store.getters.metric_args.reportId,
          report_type: store.getters.metric_args.reportType,
          report_id: store.getters.single_metrics.metricGroupId,
          report_view: 'single',
          metric_id: store.getters.single_metrics.selectedMetric,
          span_id: store.getters.selected_span,
          set_id: store.getters.setId,
          peer_group_id: store.getters.selected_peer_group
        },
        handleErrors: true
      })
      .then(res => {
        if (res.data.report_id) {
          store.commit('updateReportId', res.data.report_id)
        }
        store.commit('single_metrics', {
          metrics: res.data.metrics,
          headers: res.data.headers,
          peer_metrics: res.data.peer_metrics,
          peerTableData: res.data.peer_table,
          scoreMetricIds: res.data.score_metric_ids,
          institutionName: res.data.bank_title,
          individualMetricsList: res.data.metrics_list,
          selectedMetric: res.data.metric_id,
          metricsLoading: false,
          dataFormat: res.data.format
        })
      })
  }
}
const getMultiMetricDataWithStore = store => {
  if (store.getters.metric_args.reportId) {
    return axios
      .get('/api/self_serve_reports/view', {
        params: {
          id: store.getters.metric_args.reportId,
          report_type: store.getters.metric_args.reportType,
          report_id: store.getters.multi_metrics.metricGroupId,
          report_view: 'multi',
          span_id: store.getters.selected_span,
          set_id: store.getters.setId,
          peer_group_id: store.getters.selected_peer_group
        },
        handleErrors: true
      })
      .then(res => {
        if (res.data.report_id) {
          store.commit('updateReportId', res.data.report_id)
        }
        store.commit('multi_metrics', {
          institutionName: res.data.bank_title,
          headers: res.data.headers,
          metrics: res.data.metrics,
          format: res.data.element_format,
          metricsLoading: false
        })
      })
  }
}
const getPeriodMetricDataWithStore = store => {
  if (
    store.getters.metric_args.reportId &&
    store.getters.period_metrics.metricGroupId
  ) {
    let selectedGroup = store.getters.peer_groups.filter(
      pg => pg.id === store.getters.selected_peer_group
    )
    const peerCount = selectedGroup[0] ? selectedGroup[0].peer_count : 0
    let pages = peerCount / PAGE_SIZE
    let metrics = []
    let headers, periodList, displayFormats, selectedPeriod
    let promiseArray = []
    for (let i = 0; i < pages; i++) {
      promiseArray[i] = new Promise(resolve => {
        return axios
          .get('/api/self_serve_reports/view', {
            params: {
              id: store.getters.metric_args.reportId,
              report_type: store.getters.metric_args.reportType,
              report_id: store.getters.period_metrics.metricGroupId,
              report_view: 'period',
              period_id: store.getters.period_metrics.selectedPeriod,
              span_id: store.getters.selected_span,
              set_id: store.getters.setId,
              peer_group_id: store.getters.selected_peer_group,
              page_no: i
            },
            handleErrors: true
          })
          .then(res => {
            if (res.data.report_id) {
              store.commit('updateReportId', res.data.report_id)
            }
            ;(metrics = metrics.concat(res.data.metrics)),
              (headers = res.data.headers),
              (periodList = res.data.periodList),
              (displayFormats = res.data.display_formats),
              (selectedPeriod = res.data.period_id)

            resolve()
          })
      })
    }
    return Promise.all(promiseArray).then(() => {
      store.commit('period_metrics', {
        metrics: metrics,
        headers: headers,
        periodList: periodList,
        displayFormats: displayFormats,
        metricsLoading: false,
        selectedPeriod: selectedPeriod
      })
    })
  }
}
export default {
  getCustomReportList: vm => {
    return axios
      .get('/api/self_serve_reports', {
        params: {
          report_id: vm.$store.getters.metric_args.reportId,
          report_type: vm.$store.getters.metric_args.reportType,
          bank_id: vm.$store.getters.selectedInstitution.id
        },
        handleErrors: true
      })
      .then(res => {
        res.data.reports.forEach(rep => {
          rep['showShared'] =
            rep.category !== 'fin_reports' &&
            rep.group_ids &&
            rep.group_ids.length
              ? ''
              : 'd-none text-muted '
        })
        vm.$store.commit('multi_metrics', {
          metricsList: res.data.reports
        })
        vm.$store.commit('single_metrics', {
          metricsList: res.data.reports.map(data => {
            return {
              group_id: data.id,
              group_name: data.name,
              category: data.category,
              showShared: data.showShared,
              technographicReport: data.is_technographics_only || false
            }
          })
        })
        vm.$store.commit('period_metrics', {
          metricsList: res.data.reports
        })
        vm.$store.commit('spans', res.data.spans)
      })
  },
  downloadAllData: vm => {
    vm.$http
      .get('/api/self_serve_reports/download_all.csv', {
        params: {
          id: vm.$store.getters.metric_args.reportId,
          report_id: vm.$store.getters.multi_metrics.metricGroupId,
          peer_group_id: vm.$store.getters.selected_peer_group,
          span_id: vm.$store.getters.selected_span,
          set_id: vm.$store.getters.setId,
          report_view: vm.$store.getters.report_view
        },
        handleErrors: true,
        responseType: 'blob'
      })
      .then(response => {
        downloadsAPI.downloadResult(response, 'custom_report.csv')
      })
      .then(() => {})
  },
  downloadMultiMetricReport: vm => {
    const row_index = { 1: 'B', 2: 'C', 3: 'D', 4: 'E', 5: 'F' }
    const workbook = new Excel.Workbook()
    const sheet = workbook.addWorksheet('Multi Metric Report')
    let columns = [
      {
        header: vm.$store.getters.multi_metrics.metrics[0].group_name,
        key: 'name',
        width: 30
      }
    ]
    for (var i = 0; i < 5; i++) {
      let key = 'value' + (i + 1)
      let span = vm.$store.getters.multi_metrics.headers[key][0]
      let qtr = vm.$store.getters.multi_metrics.headers[key][1]
      columns.push({ header: span + ' \n' + qtr, key: key, width: 30 })
    }
    if (vm.$store.getters.showPeers) {
      let lst_qtr = vm.$store.getters.multi_metrics.headers['value5'][1]
      columns.push({ header: 'Peer\n' + lst_qtr, key: 'peer', width: 30 })
      columns.push({
        header: 'National Percentile',
        key: 'national_rank',
        width: 30
      })
    }
    sheet.columns = columns

    let downloadElements = new MetricFormatter().downloadReportFormat

    downloadElements.forEach((mv, idx) => {
      let data = [mv.name]
      if (mv.excel_values == undefined || mv.excel_values.length == 0) {
        data = [mv.name, null, null, null, null, mv.value5 ? mv.value5 : 'NA']
      } else {
        data = [mv.name, ...mv.excel_values]
      }
      if (vm.$store.getters.showPeers) {
        if (mv.peer == 'NA') {
          data.push(mv.peer)
        } else if (['P1', 'P2', undefined].includes(mv.format)) {
          data.push(parseFloat(mv.peer) / 100)
        } else {
          data.push(parseFloat(mv.peer.replace('$', '').replace(',', '')))
        }
        if (mv.national_rank == 'NA') {
          data.push('NA')
        } else if (mv.national_rank == '') {
          data.push(null)
        } else {
          data.push(parseInt(mv.national_rank))
        }
      }
      sheet.addRow(data)
      for (var i = 1; i <= 5; i++) {
        sheet.getCell(
          row_index[i] + (idx + 2).toString()
        ).numFmt = METRICVALUEFORMAT()[mv.format]
      }
      if (vm.$store.getters.showPeers) {
        let mv_format = mv.format == undefined ? 'P2' : mv.format
        if (mv.peer != 'NA') {
          sheet.getCell(
            'G' + (idx + 2).toString()
          ).numFmt = METRICVALUEFORMAT()[mv_format]
        }
        if (mv.national_rank == 'NA') {
          sheet.getCell('H' + (idx + 2).toString()).alignment = {
            horizontal: 'right'
          }
        }
      }
    })
    sheet.duplicateRow(1, 1, true)
    sheet.getRow(1).values = [
      'Copyright ' +
        new Date().getFullYear() +
        " FI Navigator Corporation. Any data, files, or documents downloaded or obtained from FI Navigator is a component of the Licensee's Licensed Materials and deemed as Confidential Information. Licensee may only distribute Licensed Materials internally, either in original or modified form. Any other form of distribution is not permitted without the prior written consent of, and with express attribution to, FI Navigator Corporation. Unauthorized distribution of copyrighted material by any intentional or unintentional recipient is subject to civil and criminal liabilities. NOTICE: FI Navigator has embedded data fingerprinting unique to each data export and components of the data itself to source any unauthorized distribution."
    ]
    downloadStyle.headerStyle(sheet)
    downloadsAPI.downloadAsExcel(
      workbook,
      `${vm.$store.getters.multi_metrics.institutionName} ${vm.$store.getters.multi_metrics.metrics[0].group_name}`
    )
  },
  downloadMultiServiceReport: vm => {
    const workbook = new Excel.Workbook()
    const sheet = workbook.addWorksheet('Multi Service Report')

    let columns = [
      {
        header: vm.$store.getters.multi_metrics.metrics[0].group_name,
        key: 'name',
        width: 50
      }
    ]

    if (vm.$store.getters.showPeers) {
      columns.push({
        header: 'Peers',
        key: 'peer',
        width: 10
      })

      columns.push({
        key: 'help_text',
        width: 30
      })
    }

    sheet.columns = columns

    let tableData = new MetricFormatter().downloadTechnographicReportFormat

    tableData.forEach(service => {
      let serviceData = [service.name]

      if (vm.$store.getters.showPeers) {
        serviceData.push(service.peer)

        serviceData.push(
          service.type === 'service'
            ? 'Peers with Service'
            : service.type === 'vendor'
            ? 'Peers with Vendor Product'
            : ''
        )
      }

      let sheetRow = sheet.addRow(serviceData)

      sheetRow.eachCell((cell, cellIndex) => {
        if (service.type === 'service') {
          cell.style = {
            font: {
              name: 'Calibri',
              bold: true,
              size: 12
            }
          }
        }

        if (cellIndex === 2) {
          cell.numFmt = '#,##0.00%'
          cell.alignment = { horizontal: 'right' }

          cell.fill = {
            type: 'pattern',
            pattern: 'solid',
            fgColor: { argb: 'FFF0F0F0' }
          }

          cell.border = {
            bottom: { style: 'double', color: { argb: 'FFA0A0A0' } },
            right: { style: 'double', color: { argb: 'FFA0A0A0' } },
            left: { style: 'double', color: { argb: 'FFA0A0A0' } }
          }
        }

        if (cellIndex === 3) {
          cell.font = {
            size: 10
          }
        }
      })
    })

    sheet.duplicateRow(1, 1, true)
    sheet.getRow(1).values = [
      'Copyright ' +
        new Date().getFullYear() +
        " FI Navigator Corporation. Any data, files, or documents downloaded or obtained from FI Navigator is a component of the Licensee's Licensed Materials and deemed as Confidential Information. Licensee may only distribute Licensed Materials internally, either in original or modified form. Any other form of distribution is not permitted without the prior written consent of, and with express attribution to, FI Navigator Corporation. Unauthorized distribution of copyrighted material by any intentional or unintentional recipient is subject to civil and criminal liabilities. NOTICE: FI Navigator has embedded data fingerprinting unique to each data export and components of the data itself to source any unauthorized distribution."
    ]

    downloadStyle.headerStyle(sheet)

    sheet.getCell('B2').alignment = { vertical: 'middle', horizontal: 'center' }

    downloadsAPI.downloadAsExcel(
      workbook,
      `${vm.$store.getters.multi_metrics.institutionName} ${vm.$store.getters.multi_metrics.metrics[0].group_name}`
    )
  },
  downloadSingleServiceReport: vm => {
    const metric = vm.$store.getters.single_metrics.selectedMetric
    const service = vm.$store.getters.single_metrics.individualMetricsList.find(
      ml => ml.metric_id === metric
    )

    const workbook = new Excel.Workbook()
    const sheet = workbook.addWorksheet(
      service && service.metric_name
        ? service.metric_name
        : 'Single Service View'
    )

    let columns = [
      { header: 'FI ID', key: 'bank_id', width: 10 },
      { header: 'Financial Institution', key: 'name', width: 30 },
      { header: 'City', key: 'city', width: 30 },
      { header: 'State', key: 'state', width: 30 },
      { header: 'Has Service', key: 'has_service', width: 30 },
      { header: 'Vendor', key: 'vendor', width: 30 },
      { header: 'Product', key: 'product', width: 30 }
    ]

    sheet.columns = columns

    vm.$store.getters.single_metrics.peerTableData.forEach(mv => {
      sheet.addRow([
        mv.bank_id,
        mv.bank_name,
        mv.bank_city,
        mv.bank_state,
        mv.value5.has_service,
        mv.value5.vendor,
        mv.value5.product
      ])
    })

    sheet.duplicateRow(1, 1, true)
    sheet.getRow(1).values = [
      'Copyright ' +
        new Date().getFullYear() +
        " FI Navigator Corporation. Any data, files, or documents downloaded or obtained from FI Navigator is a component of the Licensee's Licensed Materials and deemed as Confidential Information. Licensee may only distribute Licensed Materials internally, either in original or modified form. Any other form of distribution is not permitted without the prior written consent of, and with express attribution to, FI Navigator Corporation. Unauthorized distribution of copyrighted material by any intentional or unintentional recipient is subject to civil and criminal liabilities. NOTICE: FI Navigator has embedded data fingerprinting unique to each data export and components of the data itself to source any unauthorized distribution."
    ]
    downloadStyle.headerStyle(sheet)
    downloadsAPI.downloadAsExcel(
      workbook,
      `${vm.$store.getters.single_metrics.institutionName} ${vm.$store.getters.multi_metrics.metrics[0].group_name}`
    )
  },
  downloadPeriodReport: vm => {
    const workbook = new Excel.Workbook()
    const sheet = workbook.addWorksheet('Period View')
    let columns = [
      { header: 'FI ID', key: 'bank_id', width: 10 },
      { header: 'Financial Institution', key: 'bank_name', width: 30 },
      { header: 'City', key: 'bank_city', width: 30 },
      { header: 'State', key: 'bank_state', width: 30 }
    ]
    vm.$store.getters.period_metrics.headers.forEach(colName => {
      columns.push({ header: colName, width: 30 })
    })
    sheet.columns = columns
    let displayFormats = vm.$store.getters.period_metrics.displayFormats
    vm.$store.getters.period_metrics.metrics.forEach((row, row_idx) => {
      sheet.addRow([
        row.bank_id,
        row.bank_name,
        row.bank_city,
        row.bank_state,
        ...row.unformated_values.map(val => {
          return val
        })
      ])
      sheet.getRow(row_idx + 2).eachCell((cell, cellIndex) => {
        if (cellIndex >= 5) {
          cell.numFmt = METRICVALUEFORMAT()[displayFormats[cellIndex - 5]]
        }
      })
    })
    sheet.duplicateRow(1, 1, true)
    sheet.getRow(1).values = [
      'Copyright ' +
        new Date().getFullYear() +
        " FI Navigator Corporation. Any data, files, or documents downloaded or obtained from FI Navigator is a component of the Licensee's Licensed Materials and deemed as Confidential Information. Licensee may only distribute Licensed Materials internally, either in original or modified form. Any other form of distribution is not permitted without the prior written consent of, and with express attribution to, FI Navigator Corporation. Unauthorized distribution of copyrighted material by any intentional or unintentional recipient is subject to civil and criminal liabilities. NOTICE: FI Navigator has embedded data fingerprinting unique to each data export and components of the data itself to source any unauthorized distribution."
    ]
    downloadStyle.headerStyle(sheet)
    downloadsAPI.downloadAsExcel(
      workbook,
      `${vm.$store.getters.multi_metrics.institutionName} ${vm.$store.getters.multi_metrics.metrics[0].group_name}`
    )
  },
  downloadSingleMetricReport: vm => {
    const workbook = new Excel.Workbook()
    const sheet = workbook.addWorksheet('Single Metric View')
    let metric_format = vm.$store.getters.single_metrics.dataFormat
    const metric = vm.$store.getters.single_metrics.selectedMetric
    let columns = [
      { header: 'FI ID', key: 'bank_id', width: 10 },
      { header: 'Financial Institution', key: 'name', width: 30 },
      { header: 'City', key: 'city', width: 30 },
      { header: 'State', key: 'state', width: 30 }
    ]
    if (typeof metric == 'string' && metric.match(/non_metric_id/)) {
      let key = 'value5'
      let span = vm.$store.getters.single_metrics.headers[key][0]
      let qtr = vm.$store.getters.single_metrics.headers[key][1]
      columns.push({ header: span + ' \n' + qtr, key: key, width: 30 })
    } else {
      for (var i = 0; i < 5; i++) {
        let key = 'value' + (i + 1)
        let span = vm.$store.getters.single_metrics.headers[key][0]
        let qtr = vm.$store.getters.single_metrics.headers[key][1]
        columns.push({ header: span + ' \n' + qtr, key: key, width: 30 })
      }
    }
    sheet.columns = columns
    vm.$store.getters.single_metrics.peerTableData.forEach((mv, row_idx) => {
      if (typeof metric == 'string' && metric.match(/non_metric_id/)) {
        sheet.addRow([
          mv.bank_id,
          mv.bank_name,
          mv.bank_city,
          mv.bank_state,
          mv.raw_value != null
            ? mv.raw_value
            : mv.value5 == null
            ? 'NA'
            : mv.value5
        ])
      } else {
        sheet.addRow([
          mv.bank_id,
          mv.bank_name,
          mv.bank_city,
          mv.bank_state,
          ...mv.raw_values
        ])
      }
      if (metric_format) {
        sheet.getRow(row_idx + 2).eachCell((cell, cI) => {
          if (cI > 3) {
            cell.numFmt = METRICVALUEFORMAT()[metric_format]
          }
        })
      }
    })
    sheet.duplicateRow(1, 1, true)
    sheet.getRow(1).values = [
      'Copyright ' +
        new Date().getFullYear() +
        " FI Navigator Corporation. Any data, files, or documents downloaded or obtained from FI Navigator is a component of the Licensee's Licensed Materials and deemed as Confidential Information. Licensee may only distribute Licensed Materials internally, either in original or modified form. Any other form of distribution is not permitted without the prior written consent of, and with express attribution to, FI Navigator Corporation. Unauthorized distribution of copyrighted material by any intentional or unintentional recipient is subject to civil and criminal liabilities. NOTICE: FI Navigator has embedded data fingerprinting unique to each data export and components of the data itself to source any unauthorized distribution."
    ]
    downloadStyle.headerStyle(sheet)
    downloadsAPI.downloadAsExcel(
      workbook,
      `${vm.$store.getters.single_metrics.institutionName} ${vm.$store.getters.multi_metrics.metrics[0].group_name}`
    )
  },
  getMultiMetricData: vm => {
    return getMultiMetricDataWithStore(vm.$store)
  },
  getMultiMetricDataWithContext: context => {
    return getMultiMetricDataWithStore(context)
  },
  getSingleMetricData: vm => {
    return getSingleMetricDataWithStore(vm.$store)
  },
  getSingleMetricDataWithContext: context => {
    return getSingleMetricDataWithStore(context)
  },
  getPeriodMetricData: vm => {
    return getPeriodMetricDataWithStore(vm.$store)
  },
  getPeriodMetricDataWithContext: context => {
    return getPeriodMetricDataWithStore(context)
  },
  getAllSingleMetricsData: () => {
    return axios
      .get('/api/self_serve_reports/view', {
        params: {
          id: store.getters.metric_args.reportId,
          report_type: store.getters.metric_args.reportType,
          report_id: store.getters.multi_metrics.metricGroupId,
          span_id: store.getters.selected_span,
          set_id: store.getters.setId,
          peer_group_id: store.getters.selected_peer_group,
          report_view: 'multi',
          pdf: true
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
