<template>
  <div v-if="isExternalLink">
    <a :href="url" :class="classList">
      <i :class="icon"></i> {{ name }}
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{
        badge.text
      }}</b-badge>
    </a>
  </div>
  <div v-else-if="isAdvisorGraph">
    <router-link
      :to="url"
      :class="classList"
      event=""
      @click.native.prevent="navigateToReportPage"
      v-if="isKeyMetric"
      exact-active-class="open active"
      active-class=""
    >
      <i :class="icon"></i> {{ name }}
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{
        badge.text
      }}</b-badge>
    </router-link>
    <router-link
      :to="url"
      :class="classList"
      event=""
      @click.native.prevent="navigateToReportPage"
      v-else
    >
      <i :class="icon"></i> {{ name }}
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{
        badge.text
      }}</b-badge>
    </router-link>
  </div>
  <div v-else>
    <router-link :to="url" :class="classList">
      <i :class="icon"></i> {{ name }}
      <b-badge v-if="badge && badge.text" :variant="badge.variant">{{
        badge.text
      }}</b-badge>
    </router-link>
  </div>
</template>

<script>
import { EventBus } from '@/plugins/events.js'

export default {
  name: 'sidebar-nav-link',
  props: {
    name: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    badge: {
      type: Object,
      default: () => {}
    },
    variant: {
      type: String,
      default: ''
    },
    classes: {
      type: String,
      default: ''
    },
    parentItem: {
      default: null
    },
    navType: {
      default: null
    }
  },
  data() {
    return {
      linkActive: true
    }
  },
  computed: {
    classList() {
      return ['nav-link', this.linkVariant, ...this.itemClasses]
    },
    linkVariant() {
      return this.variant ? `nav-link-${this.variant}` : ''
    },
    itemClasses() {
      return this.classes ? this.classes.split(' ') : []
    },
    isExternalLink() {
      if (this.url.substring(0, 4) === 'http') {
        return true
      } else {
        return false
      }
    },
    isAdvisorGraph() {
      return this.navType === 'advisor-graph'
    },
    isKeyMetric() {
      return this.$route.path.includes('key_metrics')
    }
  },
  methods: {
    navigateToReportPage() {
      let path = this.$route.path.includes('key_metrics')
        ? this.$route.fullPath
        : this.$route.path
      if (this.linkActive && path === this.url) {
        this.linkActive = false
        if (!this.$route.path.includes('key_metrics'))
          EventBus.$emit('advisorRouteReActivated')
        this.activateLink()
      } else {
        this.$router.push(this.url)
      }
    },
    activateLink() {
      setTimeout(
        function() {
          this.linkActive = true
        }.bind(this),
        1000
      )
    }
  }
}
</script>

<style lang="scss">
.sidebar {
  a.nav-link {
    padding: 0.25rem 0.25rem;
  }
}

.sidebar-minimized {
  .sidebar {
    a.nav-link {
      padding-left: 0 !important;
    }
  }
}
</style>
