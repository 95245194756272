<template>
  <div>
    <ol class="breadcrumb">
      <li class="breadcrumb-item" v-for="(item, index) in list" :key="index">
        <span class="active" v-if="isLast(index)">{{ showName(item) }}</span>
        <router-link :to="item" v-else>{{ showName(item) }}</router-link>
      </li>
      <li class="breadcrumb-item page-title" v-if="pageTitle">
        <span class="active"> {{ pageTitle }} </span>
      </li>
      <li class="aside-toggler" v-if="showAside">
        <span
          class="fa fa-chevron-right"
          @click="asideToggle"
          v-if="isAsideOpen"
        ></span>
        <span class="fa fa-chevron-left" @click="asideToggle" v-else></span>
      </li>
    </ol>
  </div>
</template>

<script>
import _ from 'lodash'
import { mapGetters } from 'vuex'

export default {
  props: {
    list: {
      type: Array,
      required: true,
      default: () => []
    }
  },
  computed: {
    ...mapGetters(['pageTitle', 'showAside', 'isAsideOpen'])
  },
  methods: {
    isLast(index) {
      return index === this.list.length - 1
    },
    showName(item) {
      if (item.meta && item.meta.label) {
        item = item.meta && item.meta.label
      }
      if (item.name) {
        item = item.name
      }
      return item
    },
    asideToggle(e) {
      e.preventDefault()
      document.body.classList.toggle('aside-menu-hidden')
      this.setAsideToggler()
    },
    setAsideToggler() {
      let closed = _.includes(document.body.classList, 'aside-menu-hidden')
      this.$store.dispatch('setAsideToogle', !closed)
    }
  }
}
</script>

<style lang="scss">
li {
  &.aside-toggler {
    margin-left: auto !important;
    cursor: pointer;
  }
}
</style>
