import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import * as getters from './getters'
import * as actions from './actions'
import * as mutations from './mutations'

// modules
import Ability from './modules/ability.js'
import Admin from '@/modules/admin/store/admin.js'
import SuperAdmin from '@/modules/super_admin/store/super_admin.js'
import Advisor from './modules/advisor.js'
import Apps from './modules/Apps'
import Authentication from '@/modules/authentication/store/authentication.js'
import marketShareReport from './modules/marketShareReport'
import MetricsAside from './modules/MetricsAside'
import Profile from '@/modules/profile/store/profile.js'
import report from './modules/report'
import ThirdPartyServices from './modules/ThirdPartyServices'
import CustomPeerGroup from '@/modules/core/store/custom_peer_group.js'
import AdvancedQuery from '@/modules/advanced_query/store/advancedQuery.js'
import ReportWriter from '@/modules/report_writer/store/report_writer.js'
import IndustryReporter from '@/modules/industry_reporter/store/industry_reporter.js'
import VendorFit from '@/modules/vendor_fit/store/vendor.fit.js'
import OnlineEdits from '@/modules/online_edits/store/online.edits.js'
import DigitalChannels from '@/modules/digital_channels/store/digital.channels.js'

// partner sites
import Ethos from '@/partners/ethos/store/ethos.js'

Vue.use(Vuex)

const vendorCompanies = [
  'Advisor',
  'Analyst',
  'Consultant',
  'Media',
  'Research',
  'Vendor',
  'Other'
]

export default new Vuex.Store({
  plugins: [
    createPersistedState({
      paths: [
        'Ability',
        'Advisor',
        'Admin',
        'SuperAdmin',
        'Authentication',
        'MetricsAside',
        'Profile',
        'report',
        'ReportWriter',
        'AdvancedQuery',
        'IndustryReporter',
        'VendorFit',
        'OnlineEdits',
        'Ethos',
        'DigitalChannels'
      ]
    })
  ],
  state: {
    redirectUrl: null,
    latestRevision: null,
    revisionOutdated: false,
    isSidebarExpanded: true,
    isSidebarAutoMinimized: false,
    storedInstitutions: [],
    companyTypes: [
      {
        type: 'Vendors & Advisors',
        companies: vendorCompanies
      },
      {
        type: 'Financial Institutions',
        companies: ['Bank', 'Credit Union']
      }
    ],
    vendorsAndAdvisors: vendorCompanies,
    financialInstituions: ['Bank', 'Credit Union'],
    isFinancialInstitution: null,
    partnerSite: null,
    sessionExpired: false
  },
  mutations,
  actions,
  getters,
  modules: {
    Ability,
    Admin,
    SuperAdmin,
    Advisor,
    Apps,
    Authentication,
    marketShareReport,
    MetricsAside,
    Profile,
    report,
    ThirdPartyServices,
    CustomPeerGroup,
    AdvancedQuery,
    ReportWriter,
    IndustryReporter,
    VendorFit,
    OnlineEdits,
    DigitalChannels,
    Ethos
  }
})
