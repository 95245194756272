import Vue from 'vue'
import axios from 'axios'
import Qs from 'qs'

export default {
  savedQueries: (isMbc, query) => {
    return axios
      .get('/api/advanced_query/saved_queries', {
        params: {
          query: query,
          is_mbc: isMbc
        },
        paramsSerializer: function(params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' })
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  customFinGroups: query => {
    return axios
      .get('/api/advanced_query/custom_fin_groups', {
        params: {
          query: query
        },
        paramsSerializer: function(params) {
          return Qs.stringify(params, { arrayFormat: 'brackets' })
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  navigatorQueries: () => {
    return axios
      .get('/api/advanced_query/navigator_queries', { handleErrors: true })
      .then(res => {
        return res.data
      })
  },
  saveQuery: (
    name,
    description,
    groupIDs,
    query,
    type,
    saveAsPG,
    setAsGlobal,
    institutionId
  ) => {
    return axios
      .post(
        '/api/advanced_query/save_query',
        {
          advanced_query: {
            name: name.trim(),
            description: description,
            group_ids: groupIDs,
            query: JSON.stringify(query),
            query_type: type,
            save_as_peer_group: saveAsPG,
            is_global: setAsGlobal,
            institution_id: institutionId
          }
        },
        {
          handleErrors: false
        }
      )
      .then(
        res => {
          //res.data.query.group_ids = res.data.query_groups
          return res.data
        },
        error => {
          if (error.response.status === 422) {
            let msg = 'Validation failed. Query name already exists.'
            if (error.response.data.error)
              msg = 'Validation failed. ' + error.response.data.error
            Vue.toasted.show(msg, {
              icon: 'chain-broken',
              type: 'error'
            })
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
        }
      )
  },
  updateQuery: (
    advancedQuery,
    query,
    type,
    saveAsPG,
    setAsGlobal,
    institutionId
  ) => {
    return axios
      .put(
        `/api/advanced_query/${advancedQuery.id}`,
        {
          advanced_query: {
            name: advancedQuery.name.trim(),
            description: advancedQuery.description,
            group_ids: advancedQuery.group_ids,
            query: JSON.stringify(query),
            query_type: type,
            save_as_peer_group: saveAsPG,
            is_global: setAsGlobal,
            institution_id: institutionId
          }
        },
        {
          handleErrors: false
        }
      )
      .then(
        res => {
          return res.data
        },
        error => {
          if (error.response.status === 422) {
            let msg = 'Validation failed. Query name already exists.'
            if (error.response.data.error)
              msg = 'Validation failed. ' + error.response.data.error
            Vue.toasted.show(msg, {
              icon: 'chain-broken',
              type: 'error'
            })
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
        }
      )
  },
  deleteQuery: id => {
    return axios
      .delete(`/api/advanced_query/${id}`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  execute: (query, isMbc) => {
    return axios
      .post(
        '/api/advanced_query',
        { query: query, is_mbc: isMbc },
        { handleErrors: true }
      )
      .then(res => {
        return res.data
      })
  },
  pricing: (query, isMbc) => {
    return axios
      .post(
        '/api/advanced_query/pricing',
        { query: query, is_mbc: isMbc },
        { handleErrors: true }
      )
      .then(res => {
        return res.data
      })
  },
  downloadFIData: (query, isMbc) => {
    return axios
      .post(
        '/api/advanced_query/downloads',
        { query: JSON.stringify(query), is_mbc: isMbc },
        { handleErrors: false }
      )
      .then(res => {
        res.data
      })
  },
  queryDetails(queryID) {
    return axios
      .get(`/api/advanced_query/${queryID}/element_list`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  contactCount(query, isMbc) {
    return axios
      .post(
        '/api/advanced_query/contact_details',
        {
          query: query,
          is_mbc: isMbc
        },
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data
      })
  },
  saveConference(formData) {
    return axios
      .post('/api/conferences', formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        handleErrors: true
      })
      .then(
        res => {
          return res
        },
        error => {
          if (error.response.status === 422) {
            Vue.toasted.show(
              'Validation failed. Conference name already exists.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
        }
      )
  },
  fetchConferences() {
    return axios
      .get('/api/conferences', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  deleteConference(id) {
    return axios
      .delete('/api/conferences/' + id, {
        handleErrors: true
      })
      .then(
        res => {
          Vue.toasted.show('Conference successfully deleted.', {
            icon: 'user-circle',
            type: 'success'
          })
          return res
        },
        error => {
          if (error.response.status === 422) {
            Vue.toasted.show('Validation failed. Conference does not exist.', {
              icon: 'chain-broken',
              type: 'error'
            })
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
        }
      )
  }
}
