import getters from './ethos.getters'
import mutations from './ethos.mutations'
import actions from './ethos.actions'

const initialState = () => ({
  redirectURL: null
})

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters: {
    ...getters
  },
  mutations: {
    ...mutations,
    resetEthosState(state) {
      let baseState = initialState()

      Object.keys(baseState).forEach(key => {
        state[key] = baseState[key]
      })
    }
  }
}
