import axios from 'axios'

export default {
  userSections: () => {
    return axios
      .get('/api/user_sections', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  updateUserSections: sections => {
    return axios
      .put(
        '/api/user_sections/set',
        { user_sections: sections },
        { handleErrors: true }
      )
      .then(res => {
        return res.data
      })
  },
  sectionConfigs: (fiId, reportId) => {
    return axios
      .get('/api/user_section_configurations', {
        params: {
          financial_institution_id: fiId,
          report_id: reportId
        },
        handleErrors: true
      })
      .then(res => {
        return res.data.section_configs
      })
  },
  updateSectionConfigs: (sectionConfigs, fiId, reportId) => {
    return axios
      .put(
        '/api/user_section_configurations/set',
        {
          section_configs: sectionConfigs,
          financial_institution_id: fiId,
          report_id: reportId
        },
        {
          handleErrors: true
        }
      )
      .then(res => {
        return res.data.section_configs
      })
  },
  fiProfileDetails: fiId => {
    return axios
      .get('api/advisors/basic_profile', {
        params: {
          institution_id: fiId
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  metricDetails: (fiId, sectionId) => {
    return axios
      .get('/api/advisors/card_data', {
        params: {
          institution_id: fiId,
          peer_group_id: null,
          section_id: sectionId
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  offeringServices: fiId => {
    return axios
      .get('/api/advisors/profile_offerings', {
        params: {
          institution_id: fiId
        },
        handleErrors: true
      })
      .then(res => {
        return res.data.offerings
      })
  },
  technographicDetails: fiId => {
    return axios
      .get('/api/advisors/technographic_vendors', {
        params: {
          institution_id: fiId
        },
        handleErrors: true
      })
      .then(res => {
        return res.data.offering
      })
  },
  mergerHistory: fiId => {
    return axios
      .get('/api/advisors/merger_history', {
        params: {
          institution_id: fiId
        },
        handleErrors: true
      })
      .then(res => {
        return res.data.merger_histories
      })
  },
  marketDepositShare: (fiId, fiType) => {
    return axios
      .get('/api/advisors/deposit_share', {
        params: {
          institution_id: fiId,
          fi_type: fiType
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  reportDetails: fiId => {
    return axios
      .get('/api/fin_profile/report_progress', {
        params: {
          institution_id: fiId
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  updateCardPosition: payload => {
    return axios
      .put(
        '/api/user_section_configurations/update_card_position',
        {
          user_section_configurations: {
            cards: payload.cards,
            section_id: payload.section_id
          }
        },
        { handleErrors: true }
      )
      .then(res => {
        return res.data
      })
  },
  contactDetails: (fiId, has_email, titles, roles) => {
    return axios
      .get('/api/advisors/contacts', {
        params: {
          institution_id: fiId,
          has_email: has_email,
          titles: titles,
          roles: roles
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
