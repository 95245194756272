export default {
  path: '/reports',
  name: 'FintechVendorReports',
  redirect: '/reports/overview',
  meta: { requiredAuth: true },
  component: {
    render(c) {
      return c('router-view')
    }
  },
  children: [
    {
      path: 'market_reports',
      name: 'Market Reports',
      component: () => import('@/modules/market_reports/pages/Layout'),
      meta: { type: 'market_reports', modulePath: '/reports/market_reports' }
    }
  ]
}
