import getters from './advancedQuery.getters'
import mutations from './advancedQuery.mutations'
import actions from './advancedQuery.actions'

import { getField, updateField } from 'vuex-map-fields'

const initialState = {
  fiCount: null,
  fiContactCount: null,
  fisWithContactsCount: null,
  individualCount: [],
  finQuery: null, // excuted query in transformed state
  currentQuery: null, // state of art query in query builder format
  persistedQuery: null, // query saved in db (transformed state)
  isValidQuery: false,
  queryRules: null,
  encryptedPrice: null,
  tokens: 0,
  costPerFIPerElement: 0,
  totalPrice: null,
  minimumPrice: 0,
  priceWithoutDiscounts: 0,
  sampleFIs: null,
  numericFields: [],
  keyLabels: [],
  savedQueryList: [],
  queryElements: [],
  fetchingFIs: false,
  fetchingPriceData: false,
  fisWOCExcluded: false, // WOC - without contacts
  fiType: 'both',
  fiResultType: null,
  enterprisePermissions: null,
  downloadableFormats: [],
  selectedQuery: {
    id: null,
    name: null,
    description: null,
    group_ids: [],
    query: null
  },
  fiTypeOptions: [
    { value: 'bank', text: 'Bank' },
    { value: 'credit_union', text: 'Credit Union' },
    { value: 'both', text: 'Bank & Credit Union' }
  ],
  baseQuery: {
    id: '1',
    type: 'group',
    primary: 'true',
    operator: 'AND',
    children: [
      {
        id: '1_1',
        type: 'rule',
        ruleId: null,
        source: null,
        cuSource: null,
        operand: null,
        childOperand: null,
        fitType: null,
        operator: null,
        value: [],
        count: null,
        error: false,
        label: null,
        loading: false,
        operandLabel: null,
        outputTypeLabel: null,
        fiType: null,
        filterOperator: 'AND',
        filterOn: false,
        actionTaken: [],
        outputType: null,
        elementOption: [],
        filters: [
          {
            id: '1_1_1',
            ruleId: '1_1',
            filterId: null,
            type: 'filter',
            childOperand: null,
            value: [],
            count: null,
            error: false,
            operand: null,
            operator: null,
            loading: false
          }
        ]
      }
    ]
  },
  selectedQueryNameAsInDB: null,
  subscription: {
    schedule: 'monthly',
    delivery_mode: 'app_link',
    delivery_format: 'csv'
  },
  fetchingSubscription: false,
  treeRules: [
    { id: 'common-metrics', label: 'Common Metrics (Bank/CU)' },
    { id: 'detailed-metrics', label: 'Detailed Metrics' },
    { id: 'financial-statements', label: 'Common Statements (Bank/CU)' },
    { id: 'detailed-statements', label: 'Detailed Statements' },
    { id: 'technographics', label: 'Technographics' }
  ],
  isMortgageLender: false
}

export default {
  namespaced: true,
  state: initialState,
  actions,
  getters: {
    ...getters,
    getField
  },
  mutations: {
    ...mutations,
    resetState(state) {
      let baseState = initialState

      Object.keys(baseState).forEach(key => {
        state[key] = baseState[key]
      })
    },
    updateField
  }
}
