export const setRedirectUrl = (state, url) => {
  window.localStorage.setItem('redirectUrl', url)
  state.redirectUrl = url
}

export const setLatestRevision = (state, revision) => {
  state.latestRevision = revision
}

export const setRevisionOutdated = (state, payload) => {
  state.revisionOutdated = payload
}

export const setSidebarExpanded = (state, payload) => {
  state.isSidebarExpanded = payload
}
export const setSidebarAutoMinimized = (state, payload) => {
  state.isSidebarAutoMinimized = payload
}
export const setStoredInstitutions = (state, values) => {
  window.localStorage.setItem('storedInstitutions', JSON.stringify(values))
  state.storedInstitutions = values
}

export const clearStoredInstitutions = state => {
  state.storedInstitutions = []
}

export const setFinancialInstitutionBoolean = state => {
  let isFI = state.financialInstituions.includes(
    state.Authentication.companyType
  )
  window.localStorage.setItem('isFinancialInstitution', isFI)
  state.isFinancialInstitution = isFI
}

export const resetFinancialInstitutionBoolean = state => {
  window.localStorage.setItem('isFinancialInstitution', null)
  state.isFinancialInstitution = null
}

export const setPartnerSite = (state, payload) => {
  state.partnerSite = payload || null
}

export const setSessionExpiry = (state, payload) => {
  state.sessionExpired = payload === 'true' ? true : false
}
