<template>
  <footer class="app-footer d-flex justify-content-end">
    <span class="no-print"
      >&copy; {{ currentYear }}
      <a href="https://www.fi-navigator.com/" target="_blank"
        >FI Navigator Corporation</a
      ></span
    >
    <span class="d-none print-only py-2">
      Copyright {{ currentYear }} FI Navigator Corporation. Any data, files, or
      documents downloaded or obtained from FI Navigator is a component of the
      Licensee’s Licensed Materials and deemed as Confidential Information.
      Licensee may only distribute Licensed Materials internally, either in
      original or modified form. Any other form of distribution is not permitted
      without the prior written consent of, and with express attribution to, FI
      Navigator Corporation.
    </span>
  </footer>
</template>
<script>
export default {
  name: 'c-footer',
  computed: {
    currentYear: function() {
      return new Date().getFullYear()
    }
  }
}
</script>
