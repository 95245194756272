export const selectedPeerGroup = state => {
  return state.selectedPeerGroup
}

export const selectedInstitution = state => {
  return state.selectedInstitution
}

export const isSelectedCreditUnion = state => {
  let cuAliases = ['CU', 'credit_union']
  return (
    state.selectedInstitution.fi_type &&
    cuAliases.includes(state.selectedInstitution.fi_type)
  )
}

// export const isSelectedBank = state => {
//   let cuAliases = ['CU', 'credit_union']
//   return !(
//     state.selectedInstitution.fi_type &&
//     cuAliases.includes(state.selectedInstitution.fi_type)
//   )
// }

export const peerGroups = state => {
  return state.peerGroups
}

export const reportId = state => {
  return state.reportId
}

export const selectedMetricId = state => {
  return state.selectedMetricId
}

export const spanId = state => {
  return state.spanId
}

export const setId = state => {
  if (
    state.selectedInstitution &&
    state.selectedInstitution.fi_type === 'bank'
  ) {
    return state.setId
  } else {
    return 1
  }
}

export const primaryTheme = state => {
  return state.primaryTheme
}

export const chartType = state => {
  return state.chartType
}

export const statusDisplay = state => {
  return state.statusDisplay
}

export const reportToken = state => {
  return state.reportToken
}

export const reportProgress = state => {
  return state.reportProgress
}

export const reportStatus = state => {
  return state.reportStatus
}

export const s3LogoUrl = state => {
  if (state.selectedInstitution) {
    return (
      'https://finapps-logos.s3.amazonaws.com/logo_' +
      state.selectedInstitution.id +
      '.png'
    )
  } else {
    return '/'
  }
}

export const menuData = state => {
  return state.menuData
}

export const productId = state => type => {
  let menu = state.menuData.find(menu => menu.product === type)
  return menu && menu.product_id
}

export const treeId = state => type => {
  let menu = state.menuData.find(menu => menu.product === type)
  return menu && menu.tree_id
}

export const metricId = state => (type, displayType) => {
  let menu = state.menuData.filter(menu => menu.product === type)
  let display = menu.find(menu => menu.menu_display === displayType)
  return display && display.metric_id
}

export const bankServiceId = state => (type, displayType) => {
  let menu = state.menuData.filter(menu => menu.product === type)
  let display = menu.find(menu => menu.menu_display === displayType)
  return display && display.bank_service_id
}

export const routeMenuMapper = () => {
  return {
    net_interest_margin: 'net_interest_margin',
    enterprise_risk: 'enterprise_risk',
    capital_adequacy: 'capital_adequacy',
    asset_quality: 'asset_quality',
    earnings_quality: 'earnings_quality',
    liquidity_risk: 'liquidity_risk'
  }
}

export const isSelectedBhc = state => {
  return (
    state.selectedInstitution && state.selectedInstitution.fi_type === 'BHC'
  )
}
export const keyMetrics = state => {
  let cuAliases = ['CU', 'credit_union']

  let isCu =
    state.selectedInstitution.fi_type &&
    cuAliases.includes(state.selectedInstitution.fi_type)
  let isBhc = state.selectedInstitution.fi_type === 'BHC'
  return isCu
    ? state.cuKeyMetrics
    : isBhc
    ? state.bhcKeyMetrics
    : state.bankKeyMetrics
}
export const profitFlag = state => {
  return state.profitFlag
}
