import axios from 'axios'

export default {
  info() {
    return axios
      .get('/api/report_writer_elements', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  vendorProducts() {
    return axios
      .get('/api/report_writer_elements/vendor_products', {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  titlesAndRoles() {
    return axios
      .get('/api/report_writer_elements/titles_and_roles_new', {
        handleErrors: true
      })
      .then(res => {
        return res.data.result
      })
  }
}
