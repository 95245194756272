import getters from './super_admin.getters'
import mutations from './super_admin.mutations'
import actions from './super_admin.actions'

const initialState = () => ({
  users: [],
  groups: []
})

export default {
  namespaced: true,
  state: initialState(),
  mutations: {
    ...mutations,
    resetSuperAdminState(state) {
      let baseState = initialState()

      Object.keys(baseState).forEach(key => {
        state[key] = baseState[key]
      })
    }
  },
  actions,
  getters
}
