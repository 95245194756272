export const isScoreMetric = state => {
  return (
    state.single_metrics.selectedMetric &&
    state.single_metrics.scoreMetricIds.includes(
      state.single_metrics.selectedMetric
    )
  )
}

export const isCustomReport = state => {
  return state.metric_args.reportType === 'custom'
}

export const isTechnographicReport = state => {
  return state.metric_args.technographicReport || false
}

export const setId = state => {
  if (
    state.selectedInstitution &&
    state.selectedInstitution.fi_type === 'bank'
  ) {
    return state.setId
  } else {
    return 1
  }
}

export const printWithSingleMetricsData = state => {
  return state.printWithSingleMetricsData
}

export const allSingleMetricsData = state => {
  return state.allSingleMetricsData
}

export const multiMetrics = state => {
  return state.multi_metrics
}
