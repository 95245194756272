export const setUserAbility = state => {
  switch (state.userRole) {
    case 'FIN Admin': {
      state.managePartnerAdminBoard = false
      state.manageSuperAdminBoard = true
      state.manageAdminBoard = true
      state.editClientGroup = true
      state.canPurchase = true
      break
    }
    case 'Client Admin': {
      state.managePartnerAdminBoard = false
      state.manageSuperAdminBoard = false
      state.manageAdminBoard = true
      state.editClientGroup = false
      state.canPurchase = true
      break
    }
    case 'Partner Admin': {
      state.managePartnerAdminBoard = true
      state.manageSuperAdminBoard = false
      state.manageAdminBoard = false
      state.editClientGroup = false
      state.canPurchase = true
      break
    }
    case 'User': {
      state.managePartnerAdminBoard = false
      state.manageSuperAdminBoard = false
      state.manageAdminBoard = false
      state.editClientGroup = false
      state.canPurchase = true
      break
    }
    case 'Guest': {
      state.managePartnerAdminBoard = false
      state.manageSuperAdminBoard = false
      state.manageAdminBoard = false
      state.editClientGroup = false
      state.canPurchase = true
      break
    }
    default: {
      state.managePartnerAdminBoard = false
      state.manageSuperAdminBoard = false
      state.manageAdminBoard = false
      state.editClientGroup = false
      state.canPurchase = false
      break
    }
  }
}

export const setUserRole = function(state, userRole = 'Viewer') {
  state.userRole = userRole
}

export const resetData = state => {
  state.userRole = 'Viewer'
  state.manageAdminBoard = false
  state.manageSuperAdminBoard = false
  state.editClientGroup = false
  state.canPurchase = false
  state.enabledModules = []
  state.showPremiumContentAlert = true
}

export const setEnabledModules = (state, modules) => {
  modules.sort((m1, m2) => m1.display_order - m2.display_order)
  let parentModules = modules.filter(m => m.parent_id === null)
  let uiModules = parentModules.map(module => {
    return {
      id: module.id,
      name: module.name,
      icon: module.icon,
      url: module.path,
      type: module.display_type,
      children: [],
      partially_enabled: module.partially_enabled,
      user_guide_url: module.user_guide_url
    }
  })

  for (let module of uiModules) {
    let childModules = modules.filter(m => m.parent_id === module.id)

    module.children = childModules.map(module => {
      return {
        id: module.id,
        name: module.name,
        url: module.path,
        icon: module.icon,
        type: module.display_type,
        class: 'sub-nav',
        partially_enabled: module.partially_enabled,
        user_guide_url: module.user_guide_url
      }
    })
  }

  state.enabledModules = uiModules
}

export const setEnabledPaths = (state, modules) => {
  state.uiModules = modules
  state.enabledPaths = modules.map(module => module.path)
}

export const setShowPremiumContentAlert = (state, payload) => {
  state.showPremiumContentAlert = payload
}
export const setEnterprisePermissions = (state, payload) => {
  state.enterprisePermissions = payload
}
export const setActiveClientPricingAvailable = (state, payload) => {
  state.activeClientPricingAvailable = payload
}
export const setCurrentUserGuide = (state, payload) => {
  state.currentUserGuide = payload
}
