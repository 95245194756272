import getters from './custom_peer_group.getters'
import * as mutations from './custom_peer_group.mutations'
import * as actions from './custom_peer_group.actions'

import { getField, updateField } from 'vuex-map-fields'

export default {
  namespaced: true,
  state: {
    peerGroup: {
      id: null,
      name: null,
      description: null
    },
    savedPeerGroupInfo: {
      name: '',
      description: '',
      members: []
    },
    selectedInstitutions: [],
    fiStatesList: [],
    sharedGroups: [],
    fiInstitutionsList: [],
    qsInstitutions: [],
    qsSizeMetricsList: [],
    qsProfitabilityMetricsList: [],
    qsStatesList: [],
    qsRegionsList: [],
    qsSizeMetric: {
      id: null,
      min: null,
      max: null
    },
    qsProfitabilityMetric: {
      id: null,
      min: null,
      max: null
    },
    qsStates: [],
    qsRegions: [],
    qsFISizeDetail: {},
    qsFIProfitabilityDetail: {},
    qsFIGeoDetail: {
      state: null,
      regions: null
    },
    qsSelectAllResults: false,
    asSelectAllResults: false,
    asInstitutions: [],
    asCharterList: [],
    asSubCharterList: [
      { text: 'Yes', value: true },
      { text: 'No', value: false }
    ],
    asMinorityOwnedList: [
      { text: 'Yes', value: true },
      { text: 'No', value: false }
    ],
    asMarketCoverageList: [],
    asFiSpecialtyTypeList: [],
    asMarketSizeList: [],
    asTypeOfMembershipsList: [],
    asLowIncomeDesignationsList: [],
    asStateList: [],
    asRegionList: [],
    asAgeGroupList: [],
    asGeographyTypesList: [
      { value: 'regions', text: 'Region' },
      { value: 'states', text: 'State' }
    ],
    asTotalAsset: { min: null, max: null },
    asDepositAccount: { min: null, max: null },
    asBranches: { min: null, max: null },
    asCharters: [],
    asSubCharter: null,
    asMinorityOwned: null,
    asGeographyType: 'regions',
    asStates: [],
    asRegions: [],
    asMarketCoverage: null,
    asFiSpecialtyTypes: [],
    asMarketSize: null,
    asMarketShare: { min: null, max: null },
    asMarketRank: { min: null, max: null },
    asMarketGrowth: { min: null, max: null },
    asAge: { min: null, max: null },
    asAgeGroup: null,
    asTypeOfMembership: '',
    asLowIncomeDesignation: null,
    asLoanMixList: [],
    asDepositMixList: [],
    asFeeMixList: [],
    asLoanFocusList: [],
    asFundingFocusList: [],
    asOBSFocusList: [],
    asLoanFocus: [],
    asFundingFocus: [],
    asOBSFocus: [],
    asLoanMix: [
      { id: null, min: null, max: null },
      { id: null, min: null, max: null },
      { id: null, min: null, max: null }
    ],
    asDepositMix: [
      { id: null, min: null, max: null },
      { id: null, min: null, max: null },
      { id: null, min: null, max: null }
    ],
    asFeeMix: [
      { id: null, min: null, max: null },
      { id: null, min: null, max: null },
      { id: null, min: null, max: null }
    ],
    asProfitList: [],
    asGrowthList: [],
    asRiskList: [],
    asProfit: [
      { id: null, min: null, max: null },
      { id: null, min: null, max: null },
      { id: null, min: null, max: null }
    ],
    asGrowth: [
      { id: null, min: null, max: null },
      { id: null, min: null, max: null },
      { id: null, min: null, max: null }
    ],
    asRisk: [
      { id: null, min: null, max: null },
      { id: null, min: null, max: null },
      { id: null, min: null, max: null }
    ],
    asProfitOpportunityList: [],
    asProfitStrengthList: [],
    asGrowthOpportunityList: [],
    asGrowthStrengthList: [],
    asRiskOpportunityList: [],
    asRiskStrengthList: [],
    asTechnographicsList: [],
    asProfitOpportunity: null,
    asProfitStrength: null,
    asGrowthOpportunity: null,
    asGrowthStrength: null,
    asRiskOpportunity: null,
    asRiskStrength: null,
    asOffering: [
      { bank_service_id: null, vendor_id: null, product_id: null },
      { bank_service_id: null, vendor_id: null, product_id: null },
      { bank_service_id: null, vendor_id: null, product_id: null }
    ],
    asOfferingVendorList: [[], [], []],
    asOfferingProductList: [[], [], []],
    asVendorDiversity: [],
    asVendorDiversityList: [],
    asRetailServiceInnovation: [],
    asRetailServiceInnovationList: [],
    asBusinessServiceInnovation: [],
    asBusinessServiceInnovationList: [],
    asMergerRisk: [],
    asMergerRiskList: [],
    asFailureRisk: [],
    asFailureRiskList: [],
    asOrganicAccountGrowth: [],
    asOrganicAccountGrowthList: [],
    asReportedAccountGrowth: [],
    asReportedAccountGrowthList: [],
    asAcquisitiveScore: [],
    asAcquisitiveScoreList: [],
    asProfileValues: {},
    asOfferingValues: {},
    asPerformanceValues: {},
    asStrategyValues: {},
    asSegmentationValues: {},
    isASProfileFormValid: true,
    isASStrategyFormValid: true,
    isASPerformanceFormValid: true,
    isASOfferingFormValid: true
  },
  getters: {
    ...getters,
    getField
  },
  mutations: {
    ...mutations,
    updateField
  },
  actions
}
