import getters from './vendor.fit.getters'
import mutations from './vendor.fit.mutations'
import actions from './vendor.fit.actions'

import { getField, updateField } from 'vuex-map-fields'

const initialState = () => ({
  selectedInstitution: null,
  selectedSolution: null,
  selectedVendor: null,
  solutionDetail: {},
  profileDetail: {},
  vendorProducts: [],
  clientInstitutions: [],
  sizeElements: [],
  marketElements: [],
  strategyElements: [],
  profitElements: [],
  growthElements: [],
  riskElements: [],
  productivityElements: [],
  technologyElements: [],
  fiLimit: 20,
  vendorLimit: 5,
  showAllFIs: false,
  allInstitutions: [],
  sections: [
    {
      id: 1,
      name: 'Financial Institution',
      status: true,
      show_config: false,
      show_info: false,
      order: 1
    },
    {
      id: 2,
      name: 'Product Fit Ranking',
      status: true,
      show_config: false,
      show_info: true,
      order: 2
    },
    {
      id: 3,
      name: 'Size',
      type: 'category',
      status: true,
      show_config: false,
      show_info: true,
      order: 3
    },
    {
      id: 4,
      name: 'Market',
      type: 'category',
      status: true,
      show_config: false,
      show_info: true,
      order: 4
    },
    {
      id: 5,
      name: 'Strategy',
      type: 'category',
      status: true,
      show_config: false,
      show_info: true,
      order: 5
    },
    {
      id: 6,
      name: 'Profit',
      type: 'category',
      status: true,
      show_config: false,
      show_info: true,
      order: 6
    },
    {
      id: 7,
      name: 'Growth',
      type: 'category',
      status: true,
      show_config: false,
      show_info: true,
      order: 7
    },
    {
      id: 8,
      name: 'Risk',
      type: 'category',
      status: true,
      show_config: false,
      show_info: true,
      order: 8
    },
    {
      id: 9,
      name: 'Productivity',
      type: 'category',
      status: true,
      show_config: false,
      show_info: true,
      order: 9
    },
    {
      id: 10,
      name: 'Technology',
      type: 'category',
      status: true,
      show_config: false,
      show_info: true,
      order: 10
    }
  ]
})

export default {
  namespaced: true,
  state: initialState(),
  actions,
  getters: {
    ...getters,
    getField
  },
  mutations: {
    ...mutations,
    resetVendorFitState(state) {
      let baseState = initialState()

      Object.keys(baseState).forEach(key => {
        state[key] = baseState[key]
      })
    },
    updateField
  }
}
