import _ from 'lodash'

export default {
  setReportTypes(state, payload) {
    state.reportTypes = payload
  },
  setSelectedReport(state, payload) {
    state.selectedReport = payload
  },
  setReportFilters(state, payload) {
    state.reportFilters = payload

    // select list
    let selectableFilters = state.reportFilters.filter(
      filter => filter.ui_type === 'select'
    )

    selectableFilters.forEach(sf => {
      let camelCasedName = _.camelCase(sf.name)

      if (sf.name === 'MB Vendor') {
        state[camelCasedName + 'Options'] = _.keys(sf.options).map(key => {
          return {
            id: key,
            name: sf.options[key]
          }
        })
      } else {
        state[camelCasedName + 'Options'] = sf.options.map(opt => {
          return {
            id: opt,
            name: opt
          }
        })
      }
      if (
        [
          'FI Asset Group',
          'MB Age Group',
          'Feature MB Age Group',
          'MB Vendor'
        ].includes(sf.name)
      ) {
        state[camelCasedName] =
          sf.default_value && sf.default_value !== '(ALL)'
            ? state[camelCasedName + 'Options'].filter(
                opt => opt.id === sf.default_value
              )
            : state[camelCasedName + 'Options']
      } else {
        state[camelCasedName] =
          sf.default_value || state[camelCasedName + 'Options'][0].id
      }
    })

    // sliders
    let sliderFilters = state.reportFilters.filter(
      filter => filter.ui_type === 'slider'
    )

    sliderFilters.forEach(sf => {
      let camelCasedName = _.camelCase(sf.name)

      state[camelCasedName] = parseInt(sf.default_value)
    })

    // switch
    let switchFilters = state.reportFilters.filter(
      filter => filter.ui_type === 'switch'
    )

    switchFilters.forEach(sf => {
      let camelCasedName = _.camelCase(sf.name)

      state[camelCasedName + 'Options'] = sf.options.map(opt => {
        return {
          id: opt,
          name: opt
        }
      })

      state[camelCasedName] = sf.default_value
    })
  },
  updateTopVendors(state, payload) {
    state.topVendors = payload
  },
  setShowAllVendors(state, payload) {
    state.showAllVendors = payload
  },
  setShowAllFeatures(state, payload) {
    state.showAllFeatures = payload
  },
  updateTopFeatures(state, payload) {
    state.topFeatures = payload
  },
  resetTopFeatures(state) {
    state.topFeatures = 5
  },
  resetTopVendors(state) {
    state.topVendors = 15
  },
  updateShowMBProduct(state, payload) {
    state.showMbProducts = payload ? 'Yes' : 'No'
  },
  setReportLoadingStatus(state, payload) {
    state.loadingReport = payload
  },
  setSelectedReportFilters(state, payload) {
    state.selectedReportFilters = payload
  },
  setReportData(state, payload) {
    state.reportData = payload
  },
  setDataFormat(state, payload) {
    state.dataFormat = payload
  },
  setReportTotal(state, payload) {
    state.reportTotal = payload
  },
  setReportHeaders(state, payload) {
    state.reportHeaders = payload
  },
  setReportOtherData(state, payload) {
    state.reportOtherData = payload
  },
  setTopVendorsMax(state, payload) {
    state.topVendorsMax = payload
  },
  setTopVendors(state, payload) {
    state.topVendors = payload
  },
  setTopFeaturesMax(state, payload) {
    state.topFeaturesMax = payload
  },
  setFIAssetGroup(state, payload) {
    state.fiAssetGroup = payload
  },
  setMbAgeGroup(state, payload) {
    state.mbAgeGroup = payload
  },
  setFeatureMbAgeGroup(state, payload) {
    state.featureMbAgeGroup = payload
  },
  setMbVendor(state, payload) {
    state.mbVendor = payload
  },
  setMultiSelectFilterChanged(state, payload) {
    state.multiSelectFilterChanged = payload
  },
  setDownloadDCReportData(state, payload) {
    state.downloadDCReportData = payload
  },
  setSelectedReportModule(state, payload) {
    state.selectedReportModule = payload
  },
  addToListenerList(state, payload) {
    state.listenerList.push(payload)
  },
  setListenerList(state, payload) {
    state.listenerList = payload
  },
  setReportIsPrintable(state, payload) {
    state.reportIsPrintable = payload
  }
}
