<template>
  <div class="market-share-options">
    <b-card-header header-class="aside-header">
      <strong>Report Options</strong>
      <span
        class="fa fa-times-circle fa-lg close-icon"
        @click="closeSettings"
      ></span>
    </b-card-header>
    <b-card body-class="p-3">
      <b-form-group>
        <label for="market_measure">Market Measure:</label>
        <b-form-select
          :value="marketMeasure"
          :options="marketMeasures"
          value-field="id"
          text-field="value"
          @change="setMarketMeasure"
        ></b-form-select>
      </b-form-group>

      <b-row class="pb-4">
        <b-col cols="12">
          <b-row>
            <b-col cols="8">Top Vendors:</b-col>
            <b-col cols="4" class="text-right">
              <small
                class="cursor-pointer show-all"
                @click="updateShowAllVendors"
              >
                {{ showAllVendors ? 'Reset' : 'Show All' }}</small
              >
            </b-col>
          </b-row>
        </b-col>
        <b-col cols="12">
          <b-row class="justify-content-center pt-1">
            <div class="btn-group item1">
              <b-button
                variant="secondary"
                :disabled="reachedMinCount"
                @click="decrementVendorCount"
                ><i class="fa fa-minus fa-lg"></i
              ></b-button>
            </div>

            <div class="btn-group item2">
              <b-input-group>
                <b-form-input
                  class="text-right top-vendor-input"
                  id="top_Vendors"
                  :value="topVendors"
                  @change="setVendorCount"
                  ref="topVendorInput"
                  size="4"
                ></b-form-input>

                <b-input-group-append>
                  <b-input-group-text class="no-left-border">
                    <span
                      @click="focusTopVendor"
                      class="fa fa-pencil pr-2 cursor-pointer text-muted "
                    ></span>
                  </b-input-group-text>
                </b-input-group-append>
              </b-input-group>
            </div>

            <div class="btn-group item3">
              <b-button
                variant="secondary"
                :disabled="reachedMaxCount"
                @click="incrementVendorCount"
                ><i class="fa fa-plus fa-lg"></i
              ></b-button>
            </div>
          </b-row>
        </b-col>
      </b-row>

      <b-row class="pb-4">
        <b-col cols="8">Show Products:</b-col>
        <b-col cols="4">
          <c-switch
            type="text"
            variant="success"
            on="Yes"
            off="No"
            :pill="true"
            size="sm"
            :checked="showProduct"
            @change="toggleShowProduct($event)"
          />
        </b-col>
      </b-row>

      <b-form-group>
        <label for="fi_type">FI Type:</label>
        <b-form-select
          id="fi_type"
          :value="fiType"
          :options="fiTypes"
          value-field="id"
          text-field="value"
          @change="setFiType"
        ></b-form-select>
      </b-form-group>

      <b-form-group>
        <label for="fi_asset_group">FI Asset Group (000):</label>

        <multiselect
          v-model="assetGroup"
          :options="fiAssetGroups"
          :multiple="true"
          :close-on-select="false"
          label="text"
          track-by="value"
          :showLabels="false"
          placeholder=""
          class="simple-select"
          :searchable="false"
          @close="setFiAssetGroup"
        >
          <template slot="selection" slot-scope="{ values }">
            {{ values.length }}
            {{ values.length | pluralize('group') }} selected
          </template>

          <template slot="beforeList">
            <li class="multiselect__elemet p-2 border-bottom">
              <span @click="selectOrRemoveAll()" class="select-all-option">
                <b>{{ selectAllText() }}</b></span
              >
            </li>
          </template>
          <template slot="option" slot-scope="asset">
            <div>
              <input
                type="checkbox"
                :checked="isAssetSelected(asset.option)"
                class="mr-1"
              />
              {{ asset.option.text }}
            </div>
          </template>
        </multiselect>
      </b-form-group>

      <b-form-group>
        <label for="known_vendor">Known Vendor:</label>
        <b-form-select
          id="known_vendor"
          :value="knownVendor"
          :options="knownVendors"
          value-field="id"
          text-field="value"
          @change="setKnownVendor"
        ></b-form-select>
      </b-form-group>

      <b-form-group>
        <label for="core_vendor">Core Vendor Product:</label>
        <multiselect
          v-model="fiCoreVendor"
          :options="coreVendorProducts"
          :multiple="true"
          :close-on-select="false"
          placeholder=""
          label="name"
          track-by="name"
          group-values="products"
          group-label="name"
          class="simple-select group-select"
          :group-select="true"
          :showLabels="false"
          @close="setCoreVendor"
        >
          <template slot="beforeList">
            <li class="multiselect__elemet p-2 border-bottom">
              <span @click="clearAllCoreVendor()" class="select-all-option">
                <b> Clear All </b>
              </span>
            </li>
          </template>

          <template slot="selection" slot-scope="{ values }">
            {{ values.length }}
            {{ values.length | pluralize('product') }} selected
          </template>
        </multiselect>
      </b-form-group>
    </b-card>
  </div>
</template>

<script>
import cSwitch from '@/components/Switch'
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex'
import PageSettings from '@/mixins/PageSettings'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    cSwitch,
    Multiselect
  },
  mixins: [PageSettings],
  props: {
    reportType: {
      required: true,
      default: 'marketShare'
    }
  },
  created() {
    this.$store.dispatch('marketShareReport/getCoreVendorProducts')
  },
  data() {
    return {
      marketMeasures: [
        { id: 1, value: 'Client Count' },
        { id: 2, value: 'Assets' },
        { id: 3, value: 'Accounts' }
      ],
      fiTypes: [
        { id: 0, value: 'All' },
        { id: 1, value: 'Bank' },
        { id: 2, value: 'Credit Union' }
      ],
      fiCoreVendor: [],
      fiAssetGroups: [
        { value: '0-100000', text: '< 100,000' },
        { value: '100000-500000', text: '100,000 - 500,000' },
        { value: '500000-1000000', text: '500,000 - 1,000,000' },
        { value: '1000000-10000000', text: '1,000,000 - 10,000,000' },
        { value: '10000000-100000000', text: '10,000,000 - 100,000,000' },
        { value: '100000000-3000000000', text: '> 100,000,000' }
      ],
      knownVendors: [
        { id: 0, value: 'All' },
        { id: 1, value: 'Has Known Vendor' }
      ]
    }
  },
  computed: {
    ...mapState('marketShareReport', [
      'marketMeasure',
      'topVendors',
      'topVendorsMax',
      'showAllVendors',
      'fiAssetGroup',
      'fiType',
      'knownVendor',
      'showProduct',
      'coreVendorProducts',
      'selectedCoreVendorProducts',
      'selectedVendorReport'
    ]),
    ...mapGetters('marketShareReport', ['getTopVendors']),
    assetGroup: {
      get: function() {
        return this.fiAssetGroup
      },
      set: function(assetGroup) {
        this.updateFiAssetGroup(assetGroup)
      }
    },
    reachedMinCount() {
      return parseInt(this.topVendors) === 1
    },
    reachedMaxCount() {
      return parseInt(this.topVendors) === parseInt(this.topVendorsMax)
    },
    allAssetGroupSelected() {
      return this.assetGroup.length === this.fiAssetGroups.length
    }
  },
  mounted() {
    this.$nextTick(() => {
      this.fiCoreVendor = this.selectedCoreVendorProducts
    })
  },
  methods: {
    ...mapMutations('marketShareReport', [
      // 'updateTopVendorCount',
      'updateShowProduct',
      'updateMarketMeasure',
      'updateFiAssetGroup',
      'updateFiType',
      'updateKnownVendor',
      'updateCoreVendors',
      'updateTopVendors',
      //'setTopVendors',
      'setShowAllVendors'
    ]),
    ...mapActions('marketShareReport', [
      'getMarketShareReports',
      'getFirmographics'
    ]),
    updateReports() {
      this.selectedVendorReport === 'Market Share'
        ? this.getMarketShareReports()
        : this.getFirmographics()
    },
    setMarketMeasure(marketMeasure) {
      this.updateMarketMeasure(marketMeasure)
      this.updateReports()
    },
    setFiAssetGroup() {
      this.updateReports()
    },
    setCoreVendor() {
      this.updateCoreVendors(this.fiCoreVendor)
      this.updateReports()
    },
    setFiType(fiType) {
      this.updateFiType(fiType)
      this.updateReports()
    },
    setKnownVendor(knownVendor) {
      this.updateKnownVendor(knownVendor)
      this.updateReports()
    },
    focusTopVendor() {
      this.$refs.topVendorInput.focus()
    },
    setMaxVendorCount() {
      this.updateTopVendorCount(this.vendorCount)
    },
    toggleShowProduct(enableProduct) {
      this.updateShowProduct(enableProduct)
      this.updateReports()
    },
    incrementVendorCount() {
      this.updateTopVendors(parseInt(this.topVendors) + 1)
      this.setShowAllVendors(this.reachedMaxCount)

      this.updateReports()
    },
    decrementVendorCount() {
      this.updateTopVendors(parseInt(this.topVendors) - 1)
      this.setShowAllVendors(this.reachedMaxCount)

      this.updateReports()
    },
    setVendorCount(vendorCount) {
      this.updateTopVendors(vendorCount)
      if (this.topVendors < 0) this.topVendors = 0
      if (this.topVendors > this.topVendorsMax)
        this.topVendors = this.topVendorsMax
      this.setShowAllVendors(this.reachedMaxCount)
      this.updateReports()
    },
    updateShowAllVendors() {
      if (this.showAllVendors) {
        this.setShowAllVendors(false)
        this.updateTopVendors(15) //set to default value
      } else {
        this.setShowAllVendors(true)
        this.updateTopVendors(this.topVendorsMax)
      }
      this.updateReports()
    },
    selectAllText() {
      return this.allAssetGroupSelected ? 'Clear All' : 'Select All'
    },
    selectOrRemoveAll() {
      this.assetGroup = this.allAssetGroupSelected ? [] : this.fiAssetGroups
    },
    clearAllCoreVendor() {
      this.fiCoreVendor = []
    },
    isAssetSelected(option) {
      return this.assetGroup.map(ag => ag.value).includes(option.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.show-all {
  color: #20a8d8;
}
.no-left-border {
  background-color: white;
  border-left: none;
  padding-right: 0;
}
.top-vendor-input {
  border-right: none;
  padding-right: 2rem;
}
.btn-group.item1 {
  width: 15%;
}
.btn-group.item2 {
  width: 55%;
}
.btn-group.item3 {
  width: 15%;
}
.select-all-option {
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}
</style>
