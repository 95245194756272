import _ from 'lodash'
import * as moment from 'moment'

const addMemberText = (subTitle, fiType) => {
  if (fiType === 'Credit Unions')
    return subTitle.replace(' Enrollment ', ' Enrollment to Members ')
  else return subTitle.replace(' Enrollment ', ' Enrollment to Accounts ')
}
const isEnrollmentReport = reportName => {
  if (
    [
      'Customer Enrollment',
      'Enrollment Growth',
      'Enrollment Age',
      'Enrollment Trend',
      'Feature Select Enroll Lift',
      'Feature Seg Enrollment',
      'Feature Seg Enrollment Assets',
      'Feature Seg Enrollment Age',
      'Enrollment',
      'Churn Enrollment'
    ].includes(reportName)
  )
    return true
  else return false
}
const extractAttribute = selectedOption => {
  if (typeof selectedOption === 'object') {
    if (typeof selectedOption.map === 'function') {
      return selectedOption.map(opt => opt.id)
    } else {
      return selectedOption.id
    }
  } else {
    return selectedOption
  }
}
const isFilterEnabled = (validFilters, filterName) => {
  return validFilters && validFilters.some(rf => rf.name === filterName)
}
const getFormattedDate = dateStr => {
  return moment(String(dateStr)).format('MM/DD/YYYY')
}
const combineRange = rangeArray => {
  /*
  1. Split the array elements(ranges) to min and max values and add to an array
  2. Sort them within that array
  3. Group them back to range arrays
  4. Combine the array of range arrays to array of range strings
  */
  let result = rangeArray
    .reduce(function(prevResult, input) {
      input.map(function(minMax) {
        let items = minMax.toString().split('-')
        items[1] = items[1] || items[0]
        prevResult.push(items.map(Number))
      })
      return prevResult
    }, [])
    .sort(function(a, b) {
      return a[0] - b[0] || a[1] - b[1]
    })
    .reduce(function(prev, current) {
      let last = prev[prev.length - 1] || []
      if (current[0] <= last[1] + 1) {
        if (last[1] < current[1]) {
          last[1] = current[1]
        }
        return prev
      }
      return prev.concat([current])
    }, [])
    .map(function(a) {
      return a[0] === a[1] ? a[0] : a.join('-')
    })
  return result
}
const combineAssetRange = (arr, totalLength) => {
  let assetString = 'Assets : '
  if (arr != null && arr.length > 0) {
    if (arr.length === totalLength) return ''
    else {
      let rangeArray = []
      arr.forEach(e => {
        switch (e.id) {
          case '< $100M':
            rangeArray.push(['0-100'])
            break
          case '$100M - $500M':
            rangeArray.push(['100-500'])
            break
          case '$500M - $1B':
            rangeArray.push(['500-1000'])
            break
          case '$1B - $10B':
            rangeArray.push(['1000-10000'])
            break
          case '$10B - $100B':
            rangeArray.push(['10000-100000'])
            break
          case '> $100B':
            rangeArray.push(['100000 - 100001'])
            break
        }
      })
      let result = combineRange(rangeArray)
      result.forEach(e => {
        if (e.startsWith('0')) {
          assetString += '< '
          assetString += e.substring(e.indexOf('-') + 1) + '; '
        } else if (e.endsWith('1')) {
          assetString += ' > '
          assetString += e.substring(0, e.indexOf('-')) + '; '
        } else {
          assetString += e + '; '
        }
      })
      assetString = assetString
        .replace('100000', '$100B')
        .replace('10000', '$10B')
        .replace('1000', '$1B')
        .replace('500', '$500M')
        .replace('100;', '$100M;')
        .replace('100-', '$100M-')
      assetString = assetString.substring(0, assetString.length - 2)
    }
  } else {
    assetString += '-'
  }

  return assetString
}

const combineAgeRange = (arr, totalLength) => {
  let ageString = 'Age Groups : '
  if (arr != null && arr.length > 0) {
    if (arr.length === totalLength) {
      return ''
    } else {
      let rangeArray = []
      arr.forEach(e => {
        switch (e.id) {
          case '< 1 Year':
            rangeArray.push(['0 - 1.0'])
            break
          case '10+ Years':
            rangeArray.push(['10.1 - 11'])
            break
          case '> 5 Years':
            rangeArray.push(['5.1 - 6.1'])
            break
          case 'Null':
            break
          default:
            rangeArray.push([
              e.id.substring(0, e.id.indexOf(' Y')).replace(' -', '.1 -')
            ])
        }
      })
      let result = combineRange(rangeArray)
      result.forEach(e => {
        if (e.startsWith('0')) {
          ageString += '< '
          ageString += e.substring(e.indexOf('-') + 1) + ' Years; '
        } else if (e.endsWith('11')) {
          ageString += '> '
          ageString += e.substring(0, e.indexOf('.1-')) + ' Years; '
        } else if (e.endsWith('6.1')) {
          ageString += '> '
          ageString += e.substring(0, e.indexOf('.1-')) + ' Years; '
        } else {
          ageString += e.replace('.1-', ' - ') + ' Years; '
        }
      })
      ageString = ageString.substring(0, ageString.length - 2)
    }
  } else {
    ageString += '-'
  }
  return ageString
}
export default {
  canShowProducts(state) {
    return state.showMbProducts === 'Yes'
  },
  selectedReportFilters(state, getters) {
    let filters = {}

    let validFilters = getters.getValidFilters

    validFilters.forEach(vf => {
      let camelCasedName = _.camelCase(vf.name)
      let filterAttributeName = vf.name.toLocaleLowerCase().replace(/\W/g, '_')
      if (
        ['fi_asset_group', 'mb_age_group', 'feature_mb_age_group'].includes(
          filterAttributeName
        )
      ) {
        let values = extractAttribute(state[camelCasedName])
        if (values.length === state[camelCasedName + 'Options'].length) {
          filters[filterAttributeName] = ['(ALL)']
        } else {
          filters[filterAttributeName] = extractAttribute(state[camelCasedName])
        }
      } else {
        filters[filterAttributeName] = extractAttribute(state[camelCasedName])
      }
    })

    return filters
  },
  reportSubTitle(state, getters) {
    const IA_SUBTITLE = `[#DATE_LEVEL#]Institution Analytics - [#REPORT_NAME#] [#MARKET_MEASURE#][#TOP_FEATURES#][#FI_TYPE#][#PERIOD#]`
    const VA_SUBTITLE = `[#TOP_VENDORS#][#MARKET_MEASURE#][#REPORT_NAME#] [#FI_TYPE#][#PERIOD#][#CLIENT_SIZE_METRIC#]`
    let validFilters = getters.getValidFilters
    let reportName = state.selectedReport
      ? state.selectedReport.name.replace(/VA\W/g, '')
      : 'NA'
    reportName = reportName.replace(' Sat ', ' Satisfaction ')
    reportName = reportName.replace(' Seg ', ' Segment ')
    reportName = reportName.replace(' Enroll ', ' Enrollment ')
    let subTitle =
      state.selectedReport &&
      state.selectedReport.report_type === 'Institution Analytics'
        ? IA_SUBTITLE
        : VA_SUBTITLE
    subTitle = subTitle.replace('[#REPORT_NAME#]', reportName)
    if (isFilterEnabled(validFilters, 'Top Vendors'))
      subTitle = subTitle.replace(
        '[#TOP_VENDORS#]',
        `Top ${state.topVendors} Vendors `
      )
    else subTitle = subTitle.replace('[#TOP_VENDORS#]', '')

    if (isFilterEnabled(validFilters, 'Date Level'))
      subTitle = subTitle.replace('[#DATE_LEVEL#]', `${state.dateLevel}ly `)
    else subTitle = subTitle.replace('[#DATE_LEVEL#]', '')

    if (isFilterEnabled(validFilters, 'Market Measure'))
      subTitle = subTitle.replace(
        '[#MARKET_MEASURE#]',
        `(by ${state.marketMeasure}) `
      )
    else if (isFilterEnabled(validFilters, 'OLB Market Measure'))
      subTitle = subTitle.replace(
        '[#MARKET_MEASURE#]',
        `(by ${state.olbMarketMeasure}) `
      )
    else subTitle = subTitle.replace('[#MARKET_MEASURE#]', '')

    if (isFilterEnabled(validFilters, 'Top Features'))
      subTitle = subTitle.replace(
        '[#TOP_FEATURES#]',
        `of Top ${state.topFeatures} Features added `
      )
    else subTitle = subTitle.replace('[#TOP_FEATURES#]', '')

    if (isFilterEnabled(validFilters, 'FI Type')) {
      subTitle = subTitle.replace('[#FI_TYPE#]', ` for ${state.fiType} `)
      if (isEnrollmentReport(state.selectedReport.name))
        subTitle = addMemberText(subTitle, state.fiType)
    } else subTitle = subTitle.replace('[#FI_TYPE#]', '')

    if (
      isFilterEnabled(validFilters, 'Previous Period') &&
      isFilterEnabled(validFilters, 'Current Period')
    )
      subTitle = subTitle.replace(
        '[#PERIOD#]',
        ` ( ${getFormattedDate(state.previousPeriod)} - ${getFormattedDate(
          state.currentPeriod
        )})`
      )
    else if (
      isFilterEnabled(validFilters, 'Beginning Period') &&
      isFilterEnabled(validFilters, 'Current Period')
    )
      subTitle = subTitle.replace(
        '[#PERIOD#]',
        ` ( ${getFormattedDate(state.beginningPeriod)} - ${getFormattedDate(
          state.currentPeriod
        )})`
      )
    else if (isFilterEnabled(validFilters, 'Current Period'))
      subTitle = subTitle.replace(
        '[#PERIOD#]',
        ` as of ${getFormattedDate(state.currentPeriod)}`
      )
    else subTitle = subTitle.replace('[#PERIOD#]', '')
    if (isFilterEnabled(validFilters, 'Client Size Metric'))
      subTitle = subTitle.replace(
        '[#CLIENT_SIZE_METRIC#]',
        ` - ${state.clientSizeMetric}`
      )
    else if (isFilterEnabled(validFilters, 'OLB Client Size Metric'))
      subTitle = subTitle.replace(
        '[#CLIENT_SIZE_METRIC#]',
        ` - ${state.olbClientSizeMetric}`
      )
    else subTitle = subTitle.replace('[#CLIENT_SIZE_METRIC#]', '')
    return subTitle
  },

  //TODO - Need to be removed
  reportSubTitle2(state, getters) {
    let validFilters = getters.getValidFilters
    let reportName = state.selectedReport
      ? state.selectedReport.name.replace(/VA\W/g, '')
      : 'NA'
    let subTitle = ''
    if (isFilterEnabled(validFilters, 'Top Vendors')) {
      subTitle += `Top ${state.topVendors} Vendors `
    }
    if (isFilterEnabled(validFilters, 'Date Level'))
      subTitle += `${state.dateLevel}ly `

    if (isFilterEnabled(validFilters, 'Market Measure'))
      subTitle += `(by ${state.marketMeasure}) `
    if (state.selectedReport.report_type === 'Institution Analytics')
      subTitle += `${state.selectedReport.report_type} - ${reportName} `
    else subTitle += `${reportName} `

    if (isFilterEnabled(validFilters, 'Top Features')) {
      subTitle += `of Top ${state.topFeatures} Features added `
    }
    if (
      isEnrollmentReport(state.selectedReport.name) &&
      isFilterEnabled(validFilters, 'FI Type')
    )
      subTitle = addMemberText(subTitle, state.fiType)

    if (isFilterEnabled(validFilters, 'FI Type'))
      subTitle += ` for ${state.fiType} `
    if (
      isFilterEnabled(validFilters, 'Previous Period') &&
      isFilterEnabled(validFilters, 'Current Period')
    )
      subTitle += ` ( ${getFormattedDate(
        state.previousPeriod
      )} - ${getFormattedDate(state.currentPeriod)})`
    else if (
      isFilterEnabled(validFilters, 'Beginning Period') &&
      isFilterEnabled(validFilters, 'Current Period')
    )
      subTitle += ` ( ${getFormattedDate(
        state.beginningPeriod
      )} - ${getFormattedDate(state.currentPeriod)})`
    else if (isFilterEnabled(validFilters, 'Current Period'))
      subTitle += ` as of ${getFormattedDate(state.currentPeriod)}`
    return subTitle
  },

  selectedFiltersMessage(state, getters) {
    let validFilters = getters.getValidFilters
    let subTitle = ``
    if (isFilterEnabled(validFilters, 'FI Asset Group'))
      subTitle += `${combineAssetRange(
        state.fiAssetGroup,
        state.fiAssetGroupOptions.length
      )} `
    if (subTitle.trim() !== ``) subTitle += ' & '
    if (isFilterEnabled(validFilters, 'MB Age Group'))
      subTitle += `${combineAgeRange(
        state.mbAgeGroup,
        state.mbAgeGroupOptions.length
      )}`
    if (isFilterEnabled(validFilters, 'Feature MB Age Group'))
      subTitle += `${combineAgeRange(
        state.featureMbAgeGroup,
        state.featureMbAgeGroupOptions.length
      )}`
    if (subTitle.endsWith('& '))
      subTitle = subTitle.substring(0, subTitle.length - 2)
    return subTitle
  },

  getValidFilters(state) {
    return state.reportFilters.filter(f =>
      state.selectedReport.filter_ids.includes(f.id)
    )
  }
}
