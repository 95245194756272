<template>
  <router-link
    tag="li"
    :class="[
      'open',
      'nav-item',
      'nav-dropdown',
      togglable ? 'togglable-dd' : 'no-togglable-dd'
    ]"
    :to="url"
    disabled
  >
    <div
      :class="[
        'nav-link',
        'nav-dropdown-toggle',
        togglable ? 'togglable' : 'no-togglable'
      ]"
      @click="handleToggle"
    >
      <i :class="icon"></i> {{ name }}
    </div>
    <ul class="nav-dropdown-items">
      <slot></slot>
    </ul>
  </router-link>
</template>

<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    url: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    navType: {
      type: String,
      default: ''
    },
    togglable: {
      type: Boolean,
      default: false
    },
    children: {
      type: Array
    }
  },
  mounted() {
    this.updateToggle()
  },
  updated() {
    if (this.togglable) {
      let path = this.$route.path.includes('key_metrics')
        ? this.$route.fullPath
        : this.$route.path
      if (!this.children.find(child => child.url === path)) {
        this.$el.classList.remove('open')
      }
    }
  },
  data() {
    return {}
  },
  computed: {},
  methods: {
    handleToggle(e) {
      if (this.togglable) {
        e.preventDefault()
        e.target.parentElement.classList.toggle('open')
      }
    },
    updateToggle() {
      if (this.togglable) {
        let path = this.$route.path.includes('key_metrics')
          ? this.$route.fullPath
          : this.$route.path
        if (this.children.find(child => child.url === path)) {
          this.$el.classList.add('open')
        } else {
          this.$el.classList.remove('open')
        }
      }
    }
  },
  watch: {
    $route: function() {
      this.updateToggle()
    }
  }
}
</script>

<style lang="scss">
.sidebar {
  .sidebar-nav {
    .nav-dropdown {
      .nav-dropdown-toggle {
        padding-bottom: 0.25rem;
        padding-left: 0.25rem;

        font-weight: 600;
        text-transform: uppercase;
        font-size: 13px;

        &.togglable {
          padding-top: 0.25rem;
          padding-bottom: 0.25rem;
          line-height: 21px;
        }

        &.no-togglable {
          &::before {
            display: none;
          }
        }
      }
      &.no-togglable-dd {
        &.open {
          background: initial;
        }
      }
      margin-bottom: 0;
    }
  }
}

.sidebar-minimized {
  .sidebar {
    .nav-dropdown {
      .nav-dropdown-toggle {
        &.no-togglable {
          padding-left: 0;
        }

        &.togglable {
          padding-left: 1rem;
          &::before {
            display: initial;
          }
        }
      }

      .sub-nav-child {
        padding-left: 1rem !important;
      }
    }
  }
}
.nav-link {
  &.sub-nav {
    padding-left: 30px !important;

    i {
      margin-right: 0.1rem !important;
    }
  }
}
</style>
