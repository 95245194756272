export const currentUserEmail = state => {
  return state.userEmail
}

export const userName = state => {
  return state.userName
}

export const isActive = state => {
  return state.isActive
}

export const companyType = state => {
  return state.companyType
}

export const authorizedUser = state => {
  return state.userEmail
}

export const userClientGroup = state => {
  return state.userGroups.find(group => group.group_type_id === 3)
}

export const userRole = state => {
  return state.userRole.name
}

export const isFiMenu = state => {
  return state.isFiMenu
}

export const isDirectLogin = state => {
  return state.isDirectLogin
}
