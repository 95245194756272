import Vue from 'vue'
import axios from 'axios'
import store from '@/store/store.js'
import { toCamelCase } from '@/utilities'
//helpers
import ReportTransformer from '@/modules/report_writer/helpers/report.transformer.js'
import ReportRetransformer from '@/modules/report_writer/helpers/report.retransformer.js'

const setReportWriterElements = function(res) {
  let customElements = retransformElements(res)
  store.commit('ReportWriter/setReportElements', customElements)
}

const retransformElements = function(res) {
  let globalSettings = store.getters['ReportWriter/globalSettings']
  let globalFinMetrics = store.getters['ReportWriter/globalFinMetrics']
  let globalFinStatements = store.getters['ReportWriter/globalFinStatements']
  let globalTechnographics = store.getters['ReportWriter/globalTechnographics']
  let setOptions = [
    { id: 1, text: 'As Reported' },
    { id: 2, text: 'Merger Restated' }
  ]
  let spanOptions = [
    { id: 1, text: 'Quarterly' },
    { id: 2, text: 'Trailing-12 Months' },
    { id: 3, text: 'Yearly' }
  ]
  let scopeOptions = [
    { id: 'trend', text: '5-Period Trend' },
    { id: 'single', text: 'Current Period' }
  ]
  let propertyOptions = [
    { id: 'default', text: 'Default' },
    { id: 'has_service', text: 'Has Service' },
    { id: 'vendor', text: 'Vendor' },
    { id: 'product', text: 'Product' }
  ]
  return res.report.report_elements.elements.map(rel => {
    let options = [
      ...store.getters[
        `ReportWriter/${toCamelCase(rel.element[0].element_type)}Options`
      ],
      ...store.getters['ReportWriter/defaultOptions']
    ]

    let optionData = options.find(option => {
      return rel.element[0].element_id
        ? option.property === rel.element[0].element_property &&
            parseInt(option.id) === parseInt(rel.element[0].element_id)
        : option.property ===
            (rel.element[0].element_type === 'vendor_fit_static'
              ? rel.element[0].element_properties.element_property
              : rel.element[0].element_property)
    })

    if (
      optionData &&
      (!rel.element[0].element_properties ||
        (rel.element[0].element_properties &&
          rel.element[0].element_type === 'vendor_fit_static'))
    ) {
      let el = {
        seqID: rel.id,
        name: optionData.name,
        type: rel.element[0].element_type,
        property: optionData.property
      }
      if (optionData.id) {
        el.id = optionData.id
      }

      if (el.type === 'vendor_fit_static') {
        el.leading = rel.element[0].element_properties.leading
      }

      return el
    } else if (rel.element[0].element_type === 'fi_contacts') {
      let el = {
        name: 'FI Contacts',
        type: 'fi_contacts',
        email: rel.element[0].element_properties.has_email,
        excludeFI: rel.element[0].element_properties.exclude_fi,
        property: 'include_contacts',
        titles: rel.element[0].element_properties.titles,
        roles: rel.element[0].element_properties.roles
      }
      return el
    } else if (rel.element[0].element_type === 'firmographics') {
      //MBC - Firmographics
      if (rel.element[0].element_properties) {
        let el = {
          name: rel.element[0].element_properties.name,
          type: 'firmographics',
          property: rel.element[0].element_properties.element_property,
          seqID: rel.id,
          elementOption: rel.element[0].element_properties.element_option,
          actionTaken: rel.element[0].element_properties.action_taken,
          outputType: rel.element[0].element_properties.output_type
        }
        return el
      } else {
        let el = {
          type: 'firmographics',
          seqID: rel.id,
          property: rel.element[0].element_property
        }
        return el
      }
    } else if (rel.element[0].element_type === 'mortgage_lending') {
      //MBC
      let el = {
        ruleId: rel.element[0].element_properties.rule_id,
        name: rel.element[0].element_properties.name,
        type: 'mortgage_lending',
        property: rel.element[0].element_properties.element_property,
        seqID: rel.id,
        filterOn: rel.element[0].element_properties.filter_on,
        filterOperator: rel.element[0].element_properties.filter_operator,
        actionTaken: rel.element[0].element_properties.action_taken,
        outputType: rel.element[0].element_properties.output_type,
        filters: transformFilterElements(
          rel.element[0].element_properties.filters
        )
      }
      return el
    } else {
      let el = res[rel.element[0].element_type].find(
        r => parseInt(r.id) === parseInt(rel.element[0].element_id)
      )

      el.seqID = rel.id

      if (rel.element[0].element_properties) {
        el.scope = rel.element[0].element_properties.scope
        el.set = rel.element[0].element_properties.set
        el.span = rel.element[0].element_properties.span
        el.property = rel.element[0].element_properties.element_property
        el.isGlobalSetting =
          rel.element[0].element_properties.is_global_setting || false

        if (rel.element[0].element_properties.is_global_setting == true) {
          let type = [
            'financial_statements',
            'common_financial_statements'
          ].includes(rel.element[0].element_type)
            ? 'Financial Statements'
            : 'Financial Metrics'
          globalSettings[type] = 'true'
          store.commit('ReportWriter/setGlobalSettings', globalSettings)
          if (type === 'Financial Statements') {
            globalFinStatements['set'] = setOptions.find(s => s.id == el.set)
            globalFinStatements['span'] = spanOptions.find(s => s.id == el.span)
            globalFinStatements['scope'] = scopeOptions.find(
              s => s.id == el.scope
            )
            store.commit(
              'ReportWriter/setGlobalFinStatements',
              globalFinStatements
            )
          } else {
            globalFinMetrics['set'] = setOptions.find(s => s.id == el.set)
            globalFinMetrics['span'] = spanOptions.find(s => s.id == el.span)
            globalFinMetrics['scope'] = scopeOptions.find(s => s.id == el.scope)
            store.commit('ReportWriter/setGlobalFinMetrics', globalFinMetrics)
          }
        }
      } else {
        el.property = rel.element[0].element_property
        if (
          rel.element[0].element_type === 'technographics' &&
          rel.element[0].is_global_setting === true
        ) {
          globalSettings['Technographics'] = 'true'
          store.commit('ReportWriter/setGlobalSettings', globalSettings)
          globalTechnographics['property'] = propertyOptions.filter(prop =>
            el.property.includes(prop.id)
          )
          store.commit(
            'ReportWriter/setGlobalTechnographics',
            globalTechnographics
          )
        }
      }
      return el
    }
  })
}
const transformFilterElements = function(filters) {
  let filterList = []
  filters.forEach(f => {
    filterList.push({
      id: f.id,
      ruleId: f.rule_id,
      filterId: f.filter_id,
      type: 'filter',
      childOperand: {
        id: f.element_id,
        label: f.element_name,
        property: f.element_property
      },
      value: f.value,
      count: null,
      error: false,
      operand: null,
      operator: f.operator,
      loading: false
    })
  })

  return filterList
}
export default {
  userReports(params) {
    return axios
      .get('/api/self_serve_reports/user_reports', {
        handleErrors: true,
        params: params
      })
      .then(res => {
        return res.data
      })
  },
  userReportsForFIType(fiType, institutionId) {
    return axios
      .get('/api/self_serve_reports/user_fi_type_reports', {
        handleErrors: true,
        params: { fi_type: fiType, bank_id: institutionId }
      })
      .then(res => {
        return res.data
      })
  },
  navigatorReports: () => {
    return axios
      .get('/api/self_serve_reports/navigator_reports', { handleErrors: true })
      .then(res => {
        return res.data
      })
  },
  reporterReports: () => {
    return axios
      .get('/api/self_serve_reports/reporter_reports', { handleErrors: true })
      .then(res => {
        return res.data
      })
  },
  create(name, description, groupIDs, fiType, isMbc, addedElements = null) {
    let reportTransformer = new ReportTransformer(addedElements)

    return axios
      .post(
        '/api/self_serve_reports',
        {
          name: name.trim(),
          description: description,
          is_mbc: isMbc,
          self_serve_report_groups_attributes: groupIDs.map(groupID => {
            return { group_id: groupID }
          }),
          fi_type: fiType,
          report_elements: JSON.stringify(reportTransformer.elementsToSave),
          element_format: JSON.stringify(
            reportTransformer.formatCustomizedElements
          )
        },
        {
          handleErrors: false
        }
      )
      .then(
        res => {
          res.data.report.group_ids = res.data.report_groups
          return res.data
        },
        error => {
          if (error.response.status === 422) {
            Vue.toasted.show('Validation failed. Report name already exists.', {
              icon: 'chain-broken',
              type: 'error'
            })
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
        }
      )
  },
  edit(reportId) {
    return axios
      .get(`/api/self_serve_reports/${reportId}/edit`, {
        handleErrors: true
      })
      .then(res => {
        setReportWriterElements(res.data)
        new ReportRetransformer(res.data.report).retransform()
      })
  },
  editBankServices(reportId) {
    return axios
      .get(`/api/self_serve_reports/${reportId}/edit_bank_services`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  update(
    reportId,
    reportName,
    reportDescription,
    groupIDs,
    fiType,
    isMbc,
    addedElements = null
  ) {
    let reportTransformer = new ReportTransformer(addedElements)

    return axios
      .put(
        `/api/self_serve_reports/${reportId}`,
        {
          name: reportName.trim(),
          fi_type: fiType,
          description: reportDescription,
          is_mbc: isMbc,
          group_ids: groupIDs,
          report_elements: JSON.stringify(reportTransformer.elementsToSave),
          element_format: JSON.stringify(
            reportTransformer.formatCustomizedElements
          )
        },
        {
          handleErrors: false
        }
      )
      .then(
        res => {
          res.data.report.group_ids = res.data.report_groups
          return res.data
        },
        error => {
          if (error.response.status === 422) {
            Vue.toasted.show('Validation failed. Report name already exists.', {
              icon: 'chain-broken',
              type: 'error'
            })
          } else {
            Vue.toasted.show(
              'Sorry, an error has occurred. Please try again. If the problem continues, please know that our team has been alerted.',
              {
                icon: 'chain-broken',
                type: 'error'
              }
            )
          }
        }
      )
  },
  delete(reportID) {
    return axios
      .delete(`/api/self_serve_reports/${reportID}`, {
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  },
  metricsList(reportID, options) {
    return axios
      .get('/api/self_serve_reports/metric_list', {
        params: {
          id: reportID,
          industry_reporter: options['industry_reporter']
        },
        handleErrors: true
      })
      .then(res => {
        return res.data
      })
  }
}
