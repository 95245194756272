// global
import Vue from 'vue'
import router from '@/router'
// api
import downloadsAPI from '@/modules/core/api/downloads'
import segmentationsAPI from '@/api/finapps/segmentations'
// utils
import Excel from 'exceljs'
import downloadStyle from '@/mixins/ExcelDownloadStyle'

export default {
  fetchSegmentRules({ commit, state }) {
    let groupIDs = state.selectedGroups.map(sg => ({
      id: sg.id,
      group_type: sg.group_type
    }))

    return segmentationsAPI.segmentRules(groupIDs).then(res => {
      commit('setSegmentRules', res.rules)
      commit('setTotalFICount', res.total_fi_count)
    })
  },
  checkReportPageDependentData({ state }) {
    if (!state.selectedGroups.length) {
      Vue.toasted.global.invalid('Please select FI Group.')
      router.push('/analytics/industry_reporter/select_fi_group')
    } else if (!state.selectedReport) {
      Vue.toasted.global.invalid('Please select Report.')
      router.push('/analytics/industry_reporter/select_report')
    } else {
      return Promise.resolve()
    }
  },
  downloadReport({ state }) {
    const workbook = new Excel.Workbook()
    const sheet = workbook.addWorksheet(state.downloadReportData.name)

    const xlsx_formats = {
      P1: '#,##0.0%',
      P2: '#,##0.00%',
      N2: '#,##0.00',
      C2: '$#,###0.00',
      C0: '#,###0.00000',
      N0: '#,###0.00000',
      '#,###': '#,###0',
      '$#,###': '$#,###0',
      '$#,###/M': '$#,###0',
      '$#,###0.0/M': '$#,###0.0',
      '$#,###0.0': '$#,###0.0'
    }

    sheet.addRow()
    sheet.getRow(1).values = [
      'Copyright ' +
        new Date().getFullYear() +
        " FI Navigator Corporation. Any data, files, or documents downloaded or obtained from FI Navigator is a component of the Licensee's Licensed Materials and deemed as Confidential Information. Licensee may only distribute Licensed Materials internally, either in original or modified form. Any other form of distribution is not permitted without the prior written consent of, and with express attribution to, FI Navigator Corporation. Unauthorized distribution of copyrighted material by any intentional or unintentional recipient is subject to civil and criminal liabilities. NOTICE: FI Navigator has embedded data fingerprinting unique to each data export and components of the data itself to source any unauthorized distribution."
    ]

    state.downloadReportData.data.forEach(rowData => {
      let reportData = rowData.map(rd => {
        return rd.format ? (rd.value || rd.value === 0 ? +rd.value : 'NA') : rd
      })
      let sheetRow = sheet.addRow(reportData)

      sheetRow.eachCell((cell, cellIndex) => {
        let rowCellData = rowData[cellIndex - 1]

        if (rowCellData.format) {
          cell.numFmt = xlsx_formats[rowCellData.format]
          cell.alignment = { horizontal: 'right' }
        }
      })
    })

    downloadStyle.headerStyle(sheet)

    downloadsAPI.downloadAsExcel(workbook, state.selectedReport.name)
  }
}
