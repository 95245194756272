<template>
  <div>
    <b-card class="page-card">
      <b-row class="search-section justify-content-center">
        <b-col sm="10">
          <div class="search-bar">
            <b-input-group>
              <div class="input-group-prepend">
                <span class="input-group-text">
                  <i class="fa fa-search fa-lg"></i>
                </span>
              </div>
              <b-form-input
                v-focus
                id="prependedInput"
                class="form-control no-focus-highlt"
                size="lg"
                type="text"
                placeholder="Enter a solution type or a specific vendor or product name"
                v-model="searchText"
              >
              </b-form-input>
            </b-input-group>
          </div>

          <div class="search-result std-border" v-if="showResult">
            <b-card-group :class="{ loading: loading }">
              <b-card class="right-border vendor-result">
                <div>
                  <b-row>
                    <b-col sm="6">
                      <h6 class="mb-0"><b>VENDOR PRODUCTS</b></h6>
                    </b-col>
                    <b-col sm="6">
                      <div class="cursor-pointer text-right">
                        <a
                          :href="redirectUrl('/technographics/vendor_coverage')"
                          target="_parent"
                          ><b
                            ><u><h6>View All Vendors</h6></u></b
                          ></a
                        >
                      </div>
                    </b-col>
                  </b-row>
                  <b-list-group class="selectable-list-group">
                    <v-wait for="fetchingProducts">
                      <template slot="waiting">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="10"
                          ></content-placeholders-text>
                        </content-placeholders>
                      </template>

                      <div v-if="emptyVendorProducts">
                        <b-list-group-item
                          class="text-center pt-4 pb-4 empty-result"
                        >
                          <div class="p-2">
                            <h6 class="mb-0">
                              <i> No matching Vendors/Products </i>
                            </h6>
                          </div>
                        </b-list-group-item>
                      </div>

                      <div
                        v-else
                        v-for="(vendor, index) in this.vendorProducts"
                        :key="index"
                      >
                        <div class="list-group-item list-group-item-action p-2">
                          <a
                            :href="
                              redirectUrl(
                                '/technographics/vendor_report/' + vendor.id
                              )
                            "
                            target="_parent"
                            >{{ vendor.name }}</a
                          >
                        </div>

                        <div
                          class="list-group-item list-group-item-action p-2 pl-4"
                          v-for="(product, index) in vendor.products"
                          :key="index"
                        >
                          <a
                            :href="
                              redirectUrl(
                                '/technographics/product_report/' +
                                  product.id +
                                  '?vendor_id=' +
                                  vendor.id +
                                  '&bank_service_id=' +
                                  product.bank_service_id
                              )
                            "
                            target="_parent"
                          >
                            {{ product.name }}
                          </a>
                        </div>
                      </div>

                      <div v-if="this.enableShowMoreVendors">
                        <b-list-group-item
                          class="text-right"
                          v-if="this.showMoreVendorProducts"
                          @click="increaseLimit('vendor')"
                        >
                          <b
                            ><i
                              ><u
                                ><h6>
                                  ...and {{ remainingVendorProducts }} more
                                </h6></u
                              ></i
                            ></b
                          >
                        </b-list-group-item>
                        <b-list-group-item
                          class="text-right"
                          v-else
                          @click="decreaseLimit('vendor')"
                        >
                          <b
                            ><i
                              ><u><h6>...View Less</h6></u></i
                            ></b
                          >
                        </b-list-group-item>
                      </div>
                    </v-wait>
                  </b-list-group>
                </div>
              </b-card>

              <b-card class="left-border solution-result">
                <div>
                  <b-row>
                    <b-col sm="6">
                      <h6 class="mb-0"><b>SOLUTIONS</b></h6>
                    </b-col>
                    <b-col sm="6">
                      <div class="cursor-pointer text-right">
                        <a
                          :href="redirectUrl('/technographics/solutions')"
                          target="_parent"
                          ><b
                            ><u><h6>View All Solutions</h6></u></b
                          ></a
                        >
                      </div>
                    </b-col>
                  </b-row>

                  <b-list-group class="selectable-list-group">
                    <v-wait for="fetchingProducts">
                      <template slot="waiting">
                        <content-placeholders :rounded="true">
                          <content-placeholders-text
                            :lines="10"
                          ></content-placeholders-text>
                        </content-placeholders>
                      </template>

                      <div v-if="emptyServices">
                        <b-list-group-item
                          class="text-center pt-4 pb-4 empty-result"
                        >
                          <div class="p-2">
                            <h6 class="mb-0"><i>No matching Solutions</i></h6>
                          </div>
                        </b-list-group-item>
                      </div>

                      <div
                        class="list-group-item list-group-item-action p-2"
                        v-for="(solution, index) in services"
                        :key="index"
                      >
                        <a
                          :href="
                            redirectUrl(
                              '/technographics/service_report/' + solution.id
                            )
                          "
                          target="_parent"
                        >
                          {{ solution.name }}
                        </a>
                      </div>

                      <div v-if="this.enableShowMoreServices">
                        <b-list-group-item
                          class="text-right"
                          v-if="this.showMoreServices"
                          @click="increaseLimit('service')"
                        >
                          <b
                            ><i
                              ><u
                                ><h6>...and {{ remainingServices }} more</h6></u
                              ></i
                            ></b
                          >
                        </b-list-group-item>
                        <b-list-group-item
                          class="text-right"
                          v-else
                          @click="decreaseLimit('service')"
                        >
                          <b
                            ><i
                              ><u><h6>...View Less</h6></u></i
                            ></b
                          >
                        </b-list-group-item>
                      </div>
                    </v-wait>
                  </b-list-group>
                </div>
              </b-card>
            </b-card-group>
          </div>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import _ from 'lodash'

export default {
  name: 'Search',
  beforeRouteEnter(to, from, next) {
    next(() => {
      document.title = 'Fintech - Product Search'
    })
  },
  data() {
    return {
      searchText: '',
      vendorProductsList: [],
      serviceList: [],
      enableShowMoreVendors: false,
      enableShowMoreServices: false,
      defaultVendorLimit: 5,
      defaultServiceLimit: 7,
      vendorLimit: 0,
      serviceLimit: 0,
      loading: false,
      showResult: false,
      totalRequest: 0
    }
  },
  created() {
    this.vendorLimit = this.defaultVendorLimit
    this.serviceLimit = this.defaultServiceLimit
    this.debouncedSearch = _.debounce(this.search, 100)
  },
  computed: {
    resultHeight() {
      return window.innerHeight - 200
    },
    emptyVendorProducts() {
      return this.vendorProductsList.length === 0 && this.loading === false
    },
    emptyServices() {
      return this.serviceList.length === 0 && this.loading === false
    },
    remainingVendorProducts: function() {
      return this.vendorProductsList.length - this.defaultVendorLimit
    },
    remainingServices: function() {
      return this.serviceList.length - this.defaultServiceLimit
    },
    showMoreVendorProducts: function() {
      return this.vendorLimit === this.defaultVendorLimit
    },
    showMoreServices: function() {
      return this.serviceLimit === this.defaultServiceLimit
    },
    vendorProducts: function() {
      let productsList = []
      let limitedProducts = _.take(this.vendorProductsList, [this.vendorLimit])
      let groupedProducts = _.groupBy(limitedProducts, 'vendor')

      _.forEach(groupedProducts, function(products) {
        let mappedProducts = _.map(products, function(product) {
          return {
            id: product.product_id,
            name: product.product,
            bank_service_id: product.bank_service_id
          }
        })
        productsList.push({
          id: products[0].id,
          name: products[0].vendor,
          products: mappedProducts
        })
      })
      return productsList
    },
    services: function() {
      return _.take(this.serviceList, [this.serviceLimit])
    }
  },
  methods: {
    search: function() {
      if (this.searchText !== '') {
        this.loading = true
        this.showResult = true
        this.totalRequest = this.totalRequest + 1
        return this.$http
          .get('/api/search', {
            params: { term: this.searchText },
            handleErrors: false
          })
          .then(
            response => {
              this.resetLimit()
              this.serviceList = response.data.result.services
              this.vendorProductsList = response.data.result.vendor_products
              this.enableShowMoreVendors =
                this.vendorProductsList.length > this.vendorLimit
              this.enableShowMoreServices =
                this.serviceList.length > this.serviceLimit
              this.totalRequest = this.totalRequest - 1
              if (this.totalRequest === 0) {
                this.loading = false
              }
            },
            () => {
              this.totalRequest = this.totalRequest - 1
              this.loading = false
            }
          )
      } else {
        this.showResult = false
      }
    },
    increaseLimit(type) {
      if (type === 'service') {
        this.serviceLimit = 1000
      } else if (type === 'vendor') {
        this.vendorLimit = 1000
      }
    },
    decreaseLimit(type) {
      if (type === 'service') {
        this.serviceLimit = this.defaultServiceLimit
      } else if (type === 'vendor') {
        this.vendorLimit = this.defaultVendorLimit
      }
    },
    resetLimit() {
      this.vendorLimit = this.defaultVendorLimit
      this.serviceLimit = this.defaultServiceLimit
    },
    redirectUrl(pageUrl) {
      return process.env.VUE_APP_URL_NAME + pageUrl
    }
  },
  watch: {
    searchText: function() {
      this.debouncedSearch()
    }
  }
}
</script>

<style lang="scss" scoped>
.search-section {
  .search-result {
    border-top: none;
    font-size: 14px;

    .vendor-result {
      max-height: 20rem;
      overflow: auto;
    }

    .solution-result {
      max-height: 20rem;
      overflow: auto;
    }
  }

  .empty-result {
    cursor: not-allowed;
    opacity: 0.5;
    pointer-events: none;
  }

  .right-border {
    border-right: 1px solid #c2cfd6;
  }

  .left-border {
    border-left: 1px solid #c2cfd6;
  }
}

.loading {
  opacity: 0.5;
  pointer-events: none;
}

.page-card {
  background: #e4e5e6;
}

.search-result {
  a {
    color: inherit;

    &:hover {
      text-decoration: none;
    }
  }
}

.list-group-item-action {
  color: initial;
}

.chmln-bubble {
  display: none;
}
</style>
