const deepClone = function(obj) {
  if (Array.isArray(obj)) {
    return obj.map(deepClone)
  } else if (obj && typeof obj === 'object') {
    var cloned = {}
    var keys = Object.keys(obj)
    for (var i = 0, l = keys.length; i < l; i++) {
      var key = keys[i]
      cloned[key] = deepClone(obj[key])
    }
    return cloned
  } else {
    return obj
  }
}

export const MONTHNAMES = function() {
  return [
    null,
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December'
  ]
}

export const METRICVALUEFORMAT = function() {
  return {
    P1: '#,##0.0%',
    P2: '#,##0.00%',
    N2: '#,##0.00',
    '$#,###': '$#,###0',
    '#,###': '#,###0',
    C2: '$#,###0.00',
    '$#,###/M': '$#,###0',
    '$#,###0.0/M': '$#,###0.0',
    '$#,###0.0': '$#,###0.0',
    '$###.0': '$#,###0.0',
    C0: '$#,###0',
    N0: '#,###0'
  }
}

export const formatUrl = function(url) {
  if (!url) {
    return ''
  }
  if (url.indexOf('http://') === 0 || url.indexOf('https://') === 0) {
    return url
  }
  return 'http://' + url.replace(/"/g, '')
}

export const BTErrorMessage =
  'There was a problem processing your credit card; please double check your payment information and try again.'

export const toCamelCase = function(str) {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}

export const numberOrdinal = function(number) {
  const english_ordinal_rules = new Intl.PluralRules('en', {
    type: 'ordinal'
  })
  const suffixes = {
    one: 'st',
    two: 'nd',
    few: 'rd',
    other: 'th'
  }
  return suffixes[english_ordinal_rules.select(parseInt(number))]
}

export const s3URLFor = function(entity, entityID) {
  switch (entity) {
    case 'vendor':
      return `https://finapps-vendor-logos.s3-us-west-2.amazonaws.com/logo_${entityID}.png`
    default:
      return `https://finapps-logos.s3.amazonaws.com/logo_${entityID}.png`
  }
}

export default deepClone
