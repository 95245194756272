import getters from './report_writer.getters'
import mutations from './report_writer.mutations'
import actions from './report_writer.actions'

import { getField, updateField } from 'vuex-map-fields'

const initialState = () => ({
  segments: [
    'Firmographics',
    'Financial Metrics',
    'Financial Statements',
    'Technographics',
    'Segmentation',
    'Performographics',
    'Market Area',
    'Digital Channels',
    'FI Contacts',
    'VendorFit'
  ],
  segmentsForMBC: [
    'Firmographics',
    'Technographics',
    'Mortgage Lending',
    'FI Contacts'
  ],
  irSegemnts: [
    'Firmographics',
    'Financial Metrics',
    'Financial Statements',
    'Technographics'
  ],
  disabledSegments: ['Regulatory Statements'],
  firmographicMBCDefaultOptions: [
    { name: 'RSSD ID', property: 'id' },

    { name: 'FI Type', property: 'fi_type' },
    { name: 'Full Name', property: 'name' },
    {
      name: 'Street Address / Main Office',
      property: 'address'
    },
    { name: 'City', property: 'city' },
    { name: 'State', property: 'state' },
    { name: 'Zip', property: 'postal_code' },
    { name: 'Domain', property: 'domain' },
    { name: 'LinkedIn', property: 'social_link' },
    { name: 'LEI', property: 'lei' },
    { name: 'Tax ID', property: 'id_tax' },
    { name: 'Agency Code', property: 'agency_code' },
    { name: 'Other Lender Code', property: 'other_lender_code' },
    { name: 'Website', property: 'website_url' }
  ],
  firmographicsElementList: [
    {
      id: 1,
      operand: null,
      operandLabel: null,
      actionTaken: [],
      outputType: null,
      outputTypeLabel: null,
      elementOption: []
    }
  ],
  mlElementList: [
    {
      id: 1,
      operand: null,
      operandLabel: null,
      actionTaken: [],
      outputType: null,
      filterOperator: 'AND',
      filterOn: false,

      filters: [
        {
          id: '1_1_1',
          ruleId: '1_1',
          filterId: null,
          type: 'filter',
          childOperand: null,
          value: [],
          count: null,
          error: false,
          operand: null,
          operator: null,
          loading: false
        }
      ]
    }
  ],
  defaultOptions: [
    { name: 'RSSD ID', property: 'id' },
    { name: 'Regulatory ID', property: 'fdic_certificate_number' },
    {
      name: 'Primary ABA routing number',
      property: 'primary_aba_routing_number'
    },
    { name: 'FI Type', property: 'fi_type' },
    { name: 'Full Name', property: 'full_name' },
    {
      name: 'Street Address / Main Office',
      property: 'address'
    },
    { name: 'City', property: 'city' },
    { name: 'State', property: 'state' },
    { name: 'Zip', property: 'postal_code' },
    { name: 'Assets', property: 'assets' },
    { name: 'Branches', property: 'branches' },
    { name: 'Domain', property: 'domain' },
    { name: 'LinkedIn', property: 'social_link' }
  ],

  // FI Types: bank, credit_union, both and all.
  // bank : Shows only of bank elements
  // credit_union : Shows only on credit_union elements
  // both : Shows when the creteria is bank & CU
  // all : Shows always

  firmographicOptions: [
    { name: 'Short Name', property: 'name', fi_type: 'all' },
    {
      name: 'Physical Street Address / Main Office',
      property: 'physical_address',
      fi_type: 'all'
    },
    { name: 'Physical City', property: 'physical_city', fi_type: 'all' },
    { name: 'Physical State', property: 'physical_state', fi_type: 'all' },
    { name: 'Physical Zip', property: 'physical_postal_code', fi_type: 'all' },
    { name: 'Website', property: 'website_url', fi_type: 'all' },
    {
      id: 291,
      name: 'Deposit Accounts',
      fi_type: 'both',
      type: 'common_metric'
    },
    {
      id: 1931,
      name: 'Deposit Accounts',
      fi_type: 'bank',
      metric_name: 'X_DEPB',
      type: 'metrics'
    },
    {
      id: 10014,
      name: 'Deposit Accounts',
      metric_name: 'FINDPATDAST8',
      fi_type: 'credit_union',
      type: 'metrics'
    },
    { name: 'Employees', property: 'employees', fi_type: 'all' },
    { name: 'Stock/Mutual', property: 'mutual', fi_type: 'all' },
    {
      name: 'FI Speciality Type',
      property: 'bnk_type_analys_cd',
      fi_type: 'all'
    },
    { name: 'Tax ID', property: 'id_tax', fi_type: 'all' },
    { name: 'Charter', property: 'charter_class', fi_type: 'all' },
    { name: 'FI Age Group', property: 'fi_age_group', fi_type: 'all' },
    { name: 'FI Age (Years)', property: 'established', fi_type: 'all' },

    { name: 'Loan Focus', property: 'loan_class_id', fi_type: 'all' },
    { name: 'Funding Focus', property: 'fund_class_id', fi_type: 'all' },
    { name: 'OBS Focus', property: 'obs_class_id', fi_type: 'all' },
    { name: 'Sub-S Election', property: 'subchapters', fi_type: 'bank' },
    { name: 'Minority-Owned', property: 'minority_owned', fi_type: 'bank' },

    {
      name: 'Type of Membership',
      property: 'type_of_membership',
      fi_type: 'credit_union'
    },
    {
      name: 'Low Income Designation',
      property: 'low_income_designation',
      fi_type: 'credit_union'
    },
    {
      name: 'Total CU Members',
      fi_type: 'credit_union',
      type: 'metrics',
      metric_name: 'FINMISCMBST4'
    }
  ],
  segmentationOptions: [
    { name: 'Vendor Diversity', property: 'vendor_diversity' },
    {
      name: 'Retail Services Innovation',
      property: 'innovation_score_retail'
    },
    {
      name: 'Business Services Innovation',
      property: 'innovation_score_business'
    },
    { name: 'Acquisition Risk', property: 'merger_risk_score' },
    { name: 'Failure Risk', property: 'failure_risk' },
    { name: 'Enterprice Risk Score', property: 'failure_risk_class_value' },
    { name: 'Organic Account Growth', property: 'org_acct_growth_5_yr_score' },
    { name: 'Reported Account Growth', property: 'acq_acct_growth_5_yr_score' },
    { name: 'Acquisitive Score', property: 'acquisitive_score' }
  ],
  digitalChannelOptions: [
    { property: 'app_age', name: 'Years with Retail MB App' },
    {
      property: 'app_age_vendor',
      name: 'Years with Current Retail MB Vendor'
    },
    { property: 'mb_installs', name: 'Retail MB App Installs' },
    { property: 'mb_customers', name: 'Retail MB App Enrolled Customers' },
    { property: 'mb_ratings_count', name: 'Number of Retail MB App Ratings' },
    { property: 'mb_avg_rating', name: 'Current Retail MB App Rating' },
    {
      property: 'mb_utilization_installs',
      name: 'Retail MB Installs to Deposit Accounts'
    },
    {
      property: 'mb_utilization_customers',
      name: 'Retail MB Customers to Deposit Accounts'
    },
    {
      property: 'mb_utilization_customers_members',
      name: 'Retail MB Customers to CU Members'
    },
    {
      property: 'mb_feature_level_name',
      name: 'Retail MB Feature Segmentation'
    },
    { property: 'olb_customers', name: 'Retail OLB Enrolled Customers' },
    {
      property: 'olb_utilization_customers',
      name: 'Retail OLB Customer to Deposit Accounts'
    },
    {
      property: 'olb_utilization_customers_members',
      name: 'Retail OLB Customers to CU Members'
    },
    {
      name: 'Retail Online Banking Vendor/Product',
      bank_service_name: 'Retail Online Banking',
      fi_type: 'both',
      type: 'technographics'
    },
    {
      name: 'Retail Mobile Banking Vendor/Product',
      bank_service_name: 'Retail Mobile Banking App',
      fi_type: 'both',
      type: 'technographics'
    }
  ],
  performographicOptions: [
    {
      id: '1',
      name: 'Primary Profit Opportunity',
      property: 'primarytheme'
    },
    {
      id: '2',
      name: 'Primary Growth Opportunity',
      property: 'primarytheme'
    },
    { id: '3', name: 'Primary Risk Opportunity', property: 'primarytheme' }
  ],
  marketAreaOptions: [
    { name: 'Market Coverage', property: 'market_coverage' },
    { name: 'Market Growth', property: 'fi_mkt_growth_1yr' },
    { name: 'Market Size', property: 'market_size_name' },
    { name: 'Market Share', property: 'fi_market_share' },
    { name: 'Market Rank', property: 'fi_market_share_rank' },
    { name: 'Region', property: 'region' }
  ],
  vendorFitOptions: [{ name: 'Core Processing', property: 'core_processing' }],
  fiContactsOptions: [{ name: 'FI Contacts', property: 'include_contacts' }],
  metricTrees: {},
  bankServices: [],
  vendorProducts: [],
  showOutput: true,
  selectedReport: {
    id: null,
    name: null,
    group_ids: [],
    description: null,
    report_elements: null,
    fi_type: 'both'
  },
  activeSegment: null,
  metricMenuData: [],
  metricTreeData: [],
  commonMetricTreeData: [],
  financialStatementsData: [],
  financialCommonStatementsData: {},
  reportElements: [],
  customizedReportElements: [],
  elementsAddedInPricing: [],
  savedReportElements: [],
  fiTypeOptions: [
    { value: 'bank', text: 'Bank' },
    { value: 'credit_union', text: 'Credit Union' },
    { value: 'bhc', text: 'BHC' },
    { value: 'both', text: 'Bank & Credit Union' }
  ],
  actualFIType: 'both',
  segmentIcons: {
    Firmographics: 'cui-building',
    Segmentation: 'cui-move',
    Technographics: 'cui-terminal',
    Performographics: 'cui-gem',
    'Market Area': 'cui-chart-pie',
    'Financial Metrics': 'cui-spreadsheet',
    'Financial Statements': 'icon-layers',
    'Digital Channels': 'icon-screen-smartphone',
    'Regulatory Statements': 'icon-layers',
    'FI Contacts': 'cui-address-book',
    VendorFit: 'cui-puzzle-piece',
    'Mortgage Lending': 'cui-home'
  },
  contactTitles: [],
  contactRoles: [],
  userCustomReports: [],
  firmographicMetrics: [],
  firmographicCommonMetrics: [],
  digitalChannelBankServices: [],
  elementsInfo: [],
  allMetricAndStatementIDs: {},
  selectedReportNameAsInDB: null,
  loadingContactCount: false,
  elementSequenceID: 0,
  globalSettings: {
    'Financial Metrics': 'false',
    'Financial Statements': 'false',
    Technographics: 'false'
  },
  globalFinMetrics: {
    set: { id: 1, text: 'As Reported' },
    span: { id: 2, text: 'Trailing-12 Months' },
    scope: { id: 'single', text: 'Current Period' }
  },
  globalFinStatements: {
    set: { id: 1, text: 'As Reported' },
    span: { id: 2, text: 'Trailing-12 Months' },
    scope: { id: 'single', text: 'Current Period' }
  },
  globalTechnographics: {
    property: [{ id: 'default', text: 'Default' }]
  }
})

export default {
  namespaced: true,
  state: initialState(),
  getters: {
    ...getters,
    getField
  },
  mutations: {
    ...mutations,
    resetState(state) {
      let baseState = initialState()
      Object.keys(baseState).forEach(key => {
        state[key] = baseState[key]
      })
    },
    updateField
  },
  actions
}
