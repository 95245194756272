export default {
  setInstitution(state, institution) {
    state.selectedInstitution = institution
  },
  resetData(state) {
    state.selectedInstitution = {}
  },
  setUserSections(state, userSections) {
    state.userSections = userSections
  },
  setReportId(state, reportId) {
    state.reportId = reportId
  },
  setReportProgress(state, reportProgress) {
    state.reportProgress = reportProgress || 0
  },
  setReportStatus(state, status) {
    state.reportStatus = status
  },
  setSectionConfigs(state, configs) {
    state.sectionConfigs = configs
  },
  updateUserSections(state, payload) {
    state.userSections = payload
  },
  setFIProfileDetails(state, payload) {
    state.profileDetail = payload
  },
  setProfitCardDetails(state, payload) {
    state.profitCards = payload
  },
  setDigitalChannelsCards(state, payload) {
    state.digitalChannelsCards = payload
  },
  setContacts(state, payload) {
    state.contacts = payload
  },
  setGrowthCardDetails(state, payload) {
    state.growthCards = payload
  },
  setRiskCardDetails(state, payload) {
    state.riskCards = payload
  },
  setOfferingDetails(state, cards) {
    state.offeringCards = cards
  },
  setStrategyDetails(state, cards) {
    state.strategyCards = cards
  },
  setMarketDetails(state, cards) {
    state.marketCards = cards
  },
  setMarketDepositShare(state, payload) {
    let marketDetails = state.marketCards.find(card => {
      return card.card.category === 'deposit_market_share'
    })

    if (marketDetails) {
      marketDetails.market_data.competitors = payload.competitors
      marketDetails.market_data.growth = payload.growth
    }
  },
  setTechnographicDetails(state, offerings) {
    state.technographicCards = offerings
  },
  setMergerHistoryDetails(state, history) {
    state.mergerHistory = history
  },
  setMetricBaseInfo(state, payload) {
    state.peerGroup = payload.peerGroup
    state.span = payload.span
    state.currentPeriod = payload.currentPeriod
  },
  setPeerGroup(state, payload) {
    state.peerGroup = payload
  },
  resetProfileData(state) {
    state.profileDetail = {}
    state.profitCards = []
    state.growthCards = []
    state.riskCards = []
    state.offeringCards = []
    state.strategyCards = []
    state.marketCards = []
    state.digitalChannelsCards = []
    state.technographicCards = []
    state.mergerHistory = []
    state.marketShareFIType = 'both'
    state.contacts = []
    state.hasEmail = 'Yes'
    state.titles = []
    state.roles = []
  },
  setIsMobile(state, payload) {
    state.isMobile = payload
  }
}
