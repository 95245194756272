import toBeAdded from '@/views/pages/toBeAdded'

export default {
  path: '/digital_channels',
  name: 'DigitalChannel',
  redirect: '/digital_channels/about',
  meta: { requiredAuth: true },
  component: () => import('./pages/Layout.vue'),
  children: [
    {
      path: 'about',
      name: 'DigitalChannelsReport',
      component: () => import('@/modules/digital_channels/pages/Report'),
      meta: {
        type: 'digital_channels',
        modulePath: '/digital_channels/about'
      }
    },
    {
      path: 'retail_mobile_banking',
      name: 'RetailMobileBanking',
      component: toBeAdded,
      meta: { type: 'digital_channels' }
    },
    {
      path: 'retail_online_banking',
      name: 'RetailOnlineBanking',
      component: toBeAdded,
      meta: { type: 'digital_channels' }
    }
  ]
}
