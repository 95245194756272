import axios from 'axios'
import _ from 'lodash'
import Excel from 'exceljs'
import downloadStyle from '@/mixins/ExcelDownloadStyle'
import downloadsAPI from '@/modules/core/api/downloads'

const combineRange = rangeArray => {
  /*
  1. Split the array elements(ranges) to min and max values and add to an array
  2. Sort them within that array
  3. Group them back to range arrays
  4. Combine the array of range arrays to array of range strings
  */
  let result = rangeArray
    .reduce(function(prevResult, input) {
      input.map(function(minMax) {
        let items = minMax.toString().split('-')
        items[1] = items[1] || items[0]
        prevResult.push(items.map(Number))
      })
      return prevResult
    }, [])
    .sort(function(a, b) {
      return a[0] - b[0] || a[1] - b[1]
    })
    .reduce(function(prev, current) {
      let last = prev[prev.length - 1] || []
      if (current[0] <= last[1] + 1) {
        if (last[1] < current[1]) {
          last[1] = current[1]
        }
        return prev
      }
      return prev.concat([current])
    }, [])
    .map(function(a) {
      return a[0] === a[1] ? a[0] : a.join('-')
    })
  return result
}
const combineAssetRange = (arr, totalLength) => {
  let assetString = 'Assets : '
  if (arr != null && arr.length > 0) {
    if (arr.length === totalLength) return ''
    else {
      let rangeArray = arr.map(item => [item])

      let result = combineRange(rangeArray)
      result.forEach(e => {
        if (e.startsWith('0')) {
          assetString += '< '
          assetString += e.substring(e.indexOf('-') + 1) + '; '
        } else if (e.endsWith('3000000000')) {
          assetString += ' > '
          assetString += e.substring(0, e.indexOf('-')) + '; '
        } else {
          assetString += e + '; '
        }
      })

      assetString = assetString.substring(0, assetString.length - 2)
    }
  } else {
    assetString += '-'
  }

  return assetString
}
const loadTechnographicsOptions = allTreeData => {
  var options = []
  allTreeData.forEach(item => {
    var opt = {
      name: item.text,
      id: item.data.id,
      $isDisabled: !item.state.selectable,
      displayText: item.text
    }
    options.push(opt)
    if (item.children) loadChild(item.children, options, 1)
  })
  return options
}
const loadChild = (children, options, depth) => {
  var padding = ''
  for (var i = 0; i <= depth; i++) {
    padding = padding + '   '
  }
  children.forEach(item => {
    //all nodes except leaf nodes are disabled
    var disabled = item.state.selectable != undefined ? true : false
    var opt = {
      name: item.text,
      id: item.data.id,
      $isDisabled: disabled,
      displayText: padding + item.text
    }
    options.push(opt)
    if (item.children) loadChild(item.children, options, depth + 1)
  })
}
export default {
  namespaced: true,
  state: {
    selectedVendorReport: 'Market Share',
    solutionId: null,
    bankServiceName: null,
    reports: [],
    marketMeasure: 1,
    // vendorCount: null,
    // topVendorCount: 15,
    showProduct: true,
    fiAssetGroup: [
      { value: '0-100000', text: '< 100,000' },
      { value: '100000-500000', text: '100,000 - 500,000' },
      { value: '500000-1000000', text: '500,000 - 1,000,000' },
      { value: '1000000-10000000', text: '1,000,000 - 10,000,000' },
      { value: '10000000-100000000', text: '10,000,000 - 100,000,000' },
      { value: '100000000-3000000000', text: '> 100,000,000' }
    ],
    fiType: 0,
    knownVendor: 1,
    reportLoading: false,
    states: [],
    coreVendorProducts: [],
    selectedCoreVendorProducts: [],
    firmographicId: 2,
    reportData: [],
    dataFormat: [],
    reportTotal: {},
    reportHeaders: [],
    reportOtherData: null,
    topVendors: 15,
    topVendorsMax: 15,
    showAllVendors: false,
    downloadReportData: null,
    technographicsOptions: null,
    peerGroupId: null,
    isAdvisor: false
  },

  getters: {
    solution: state => {
      return state.solutionId
    },
    selectedAssetGroups: () => assetGroups => {
      return assetGroups.flatMap(group =>
        group.value.split(/[-<>]/).map(value => parseInt(value))
      )
    },
    assetMin: (state, getters) => {
      let min = Math.min(...getters.selectedAssetGroups(state.fiAssetGroup))
      if (state.fiAssetGroup.length === 6 || min === 0) {
        return null
      }
      return min
    },
    assetMax: (state, getters) => {
      let max = Math.max(...getters.selectedAssetGroups(state.fiAssetGroup))
      if (state.fiAssetGroup.length === 6 || max === 3000000000) {
        return null
      }
      return max
    },
    getTechnographicsOptions: state => {
      return state.technographicsOptions
    },
    getTopVendors: state => {
      return state.topVendors
    },
    getSelectedVendorReport: state => {
      return state.selectedVendorReport
    },
    selectedFiltersMessage(state) {
      let subTitle = `${combineAssetRange(
        state.fiAssetGroup.map(item => item.value),
        6
      )} `
      return subTitle
    },
    reportSubTitle(state, getters) {
      let subTitle = `[#TOP_VENDORS#][#REPORT_NAME#] [#MARKET_MEASURE#][#FI_TYPE#]`
      subTitle = subTitle.replace(
        '[#TOP_VENDORS#]',
        `Top ${state.topVendors} Vendors `
      )
      subTitle = subTitle.replace('[#REPORT_NAME#]', state.selectedVendorReport)
      subTitle = subTitle.replace(
        '[#MARKET_MEASURE#]',
        `by ${getters.marketMeasureName} `
      )
      subTitle = subTitle.replace(
        '[#FI_TYPE#]',
        ` for ${getters.headerFiType} `
      )
      return subTitle
    },
    marketMeasureName(state) {
      switch (state.marketMeasure) {
        case 1:
          return 'Client Count'
        case 2:
          return 'Assets'
        case 3:
          return 'Accounts'
        default:
          return 'Client Count'
      }
    },
    headerFiType(state) {
      switch (state.fiType) {
        case 0:
          return 'All FIs'
        case 1:
          return 'Banks'
        case 2:
          return 'Credit Unions'
        default:
          return 'All FIs'
      }
    }
  },

  mutations: {
    updatecoreVendorProducts(state, products) {
      state.coreVendorProducts = products
    },
    updateCoreVendors(state, data) {
      state.selectedCoreVendorProducts = data
    },
    updateSolution(state, solutionId) {
      state.solutionId = solutionId
    },
    updateStates(state, states) {
      state.states = states
    },
    updateReports(state, reports) {
      state.reports = reports
    },
    updateMarketMeasure(state, data) {
      state.marketMeasure = data
    },
    // updateVendorCount(state, data) {
    //   state.vendorCount = data
    // },
    // updateTopVendorCount(state, data) {
    //   state.topVendorCount = data
    // },
    updateShowProduct(state, data) {
      state.showProduct = data
    },
    updateFiAssetGroup(state, data) {
      state.fiAssetGroup = data
    },
    updateFiType(state, data) {
      state.fiType = data
    },
    updateKnownVendor(state, data) {
      state.knownVendor = data
    },
    updateReportLoading(state, loadingState) {
      state.reportLoading = loadingState
    },
    updateFirmographicId(state, data) {
      state.firmographicId = data
    },
    setReportData(state, payload) {
      state.reportData = payload
    },
    setDataFormat(state, payload) {
      state.dataFormat = payload
    },
    setReportTotal(state, payload) {
      state.reportTotal = payload
    },
    setReportHeaders(state, payload) {
      state.reportHeaders = payload
    },
    setReportOtherData(state, payload) {
      state.reportOtherData = payload
    },
    updateTopVendors(state, payload) {
      state.topVendors = payload
    },
    setShowAllVendors(state, payload) {
      state.showAllVendors = payload
    },
    setTopVendors(state, payload) {
      state.topVendors = payload
    },
    setTopVendorsMax(state, payload) {
      state.topVendorsMax = payload
    },
    setBankServiceName(state, payload) {
      state.bankServiceName = payload
    },
    setSelectedVendorReport(state, payload) {
      state.selectedVendorReport = payload
    },
    setDownloadReportData(state, payload) {
      state.downloadReportData = payload
    },
    setTechnographicsOptions(state, payload) {
      state.technographicsOptions = payload
    },
    setPeerGroupId(state, payload) {
      state.peerGroupId = payload
    },
    setIsAdvisor(state, payload) {
      state.isAdvisor = payload
    }
  },

  actions: {
    getMarketShareReports({ dispatch, commit, state }) {
      commit('updateReportLoading', true)
      dispatch('resetTopVendorsIfZero')
      axios
        .get('api/market_shares', {
          handleErrors: true,
          params: {
            bank_service_id: state.solutionId,
            market_measure: state.marketMeasure,
            fi_type: state.fiType,
            fi_asset_group: state.fiAssetGroup.map(fg => fg.value),
            core_vendors: state.selectedCoreVendorProducts.map(fg => fg.key),
            known_vendor: state.knownVendor,
            show_products: state.showProduct,
            top_vendors: state.topVendors,
            peer_group_id: state.peerGroupId,
            is_advisor: state.isAdvisor
          }
        })
        .then(res => {
          Promise.all([dispatch('fetchAllData')]).then(() => {
            dispatch('prepareReportData', res.data.results)
            dispatch('setTopVendorsData', res.data.total_vendors)
            if (res.data.results.length > 0) {
              commit(
                'setBankServiceName',
                res.data.results[0].bank_service_name
              )
            } else commit('setBankServiceName', null)
            commit('updateReportLoading', false)
            if (!state.solutionId && res.data.results.length > 0) {
              commit('updateSolution', res.data.results[0].bank_service_id)
            }
          })
        })
    },
    getFirmographics({ dispatch, commit, state }) {
      commit('updateReportLoading', true)
      dispatch('resetTopVendorsIfZero')
      axios
        .get('api/firmographics', {
          handleErrors: true,
          params: {
            bank_service_id: state.solutionId,
            market_measure: state.marketMeasure,
            fi_type: state.fiType,
            fi_asset_group: state.fiAssetGroup.map(fg => fg.value),
            core_vendors: state.selectedCoreVendorProducts.map(fg => fg.key),
            known_vendor: state.knownVendor,
            show_products: state.showProduct,
            firmographics: state.firmographicId,
            top_vendors: state.topVendors
          }
        })
        .then(res => {
          Promise.all([dispatch('fetchAllData')]).then(() => {
            dispatch('prepareReportData', res.data.results)
            dispatch('setTopVendorsData', res.data.total_vendors)
            if (res.data.results.length > 0)
              commit(
                'setBankServiceName',
                res.data.results[0].bank_service_name
              )
            else commit('setBankServiceName', null)
            commit('updateReportLoading', false)
            if (!state.solutionId && res.data.results.length > 0) {
              commit('updateSolution', res.data.results[0].bank_service_id)
            }
          })
        })
    },
    fetchAllData({ commit, state }) {
      if (state.technographicsOptions == null) {
        return axios
          .get('/api/bank_services/tree', {
            handleErrors: true,
            params: {
              purchase_solution: true,
              new_order: false,
              market_share: true
            }
          })
          .then(data => {
            commit(
              'setTechnographicsOptions',
              loadTechnographicsOptions(data.data.results)
            )
          })
      } else return
    },
    resetTopVendorsIfZero({ state, commit }) {
      if (isNaN(state.topVendors) || parseInt(state.topVendors) === 0)
        commit('setTopVendors', 15)
    },
    prepareReportData({ commit }, reportData) {
      let reportTotal = _.remove(reportData, rd => {
        return rd.vendor === 'Grand Total'
      })[0]

      let reportOtherData = _.remove(reportData, rd => {
        return rd.vendor === 'Others'
      })[0]

      if (reportTotal && reportTotal.product === '0') reportTotal.product = ''
      if (reportOtherData && reportOtherData.product === '-1')
        reportOtherData.product = ''
      commit('setReportTotal', reportTotal)
      commit('setReportOtherData', reportOtherData)
      commit('setReportData', reportData)
    },
    setTopVendorsData({ state, commit }, totalVendors) {
      let receivedVendors = state.reportData ? state.reportData.length : 0
      if (receivedVendors >= totalVendors) {
        commit('updateTopVendors', totalVendors)
        commit('setShowAllVendors', true)
      } else if (state.showAllVendors && receivedVendors < totalVendors) {
        commit('setShowAllVendors', false)
        commit('updateTopVendors', receivedVendors)
      } else if (receivedVendors < state.topVendors)
        commit('updateTopVendors', receivedVendors)

      commit('setTopVendorsMax', totalVendors)
    },
    getStatesList({ commit }) {
      axios
        .get('api/states', {
          handleErrors: true
        })
        .then(res => {
          commit(
            'updateStates',
            res.data.states.map(s => s.code)
          )
        })
    },
    getCoreVendorProducts({ state, commit }) {
      if (!state.coreVendorProducts.length) {
        axios
          .get('/api/bank_services/134', {
            handleErrors: true
          })
          .then(res => {
            commit('updatecoreVendorProducts', res.data.vendor_products)
          })
      }
    },
    downloadReport({ state }) {
      const workbook = new Excel.Workbook()
      const sheet = workbook.addWorksheet(
        state.downloadReportData.technographic.replace('/', ' or ')
      )
      sheet.addRow([
        'Copyright ' +
          new Date().getFullYear() +
          " FI Navigator Corporation. Any data, files, or documents downloaded or obtained from FI Navigator is a component of the Licensee's Licensed Materials and deemed as Confidential Information. Licensee may only distribute Licensed Materials internally, either in original or modified form. Any other form of distribution is not permitted without the prior written consent of, and with express attribution to, FI Navigator Corporation. Unauthorized distribution of copyrighted material by any intentional or unintentional recipient is subject to civil and criminal liabilities. NOTICE: FI Navigator has embedded data fingerprinting unique to each data export and components of the data itself to source any unauthorized distribution."
      ])
      sheet.addRow()
      const nameRow = sheet.addRow([state.downloadReportData.name])
      sheet.mergeCells(nameRow.number, 1, nameRow.number, 10)
      const techRow = sheet.addRow([
        'Technographic - ' + state.downloadReportData.technographic
      ])
      sheet.mergeCells(techRow.number, 1, techRow.number, 10)
      const subTitleRow = sheet.addRow([state.downloadReportData.subTitle])
      sheet.mergeCells(subTitleRow.number, 1, subTitleRow.number, 10)
      const filterTextRow = sheet.addRow([state.downloadReportData.filterText])
      sheet.mergeCells(filterTextRow.number, 1, filterTextRow.number, 10)
      sheet.addRow()
      const xlsx_formats = {
        P0: '#,##0%',
        P1: '#,##0.0%',
        P2: '#,##0.00%',
        N2: '#,##0.00',
        N1: '#,##0.0',
        C2: '$#,###0.00',
        C0: '#,###0.00000',
        N0: '#,###0.00000',
        '#,###': '#,###0',
        '$#,###': '$#,###0',
        '$#,###/M': '$#,###0',
        '$#,###0.0/M': '$#,###0.0',
        '$#,###0.0': '$#,###0.0'
      }

      state.downloadReportData.data.forEach(rowData => {
        let reportData = rowData.map(rd => {
          return rd.format
            ? rd.value || rd.value === 0
              ? +rd.value
              : 'NA'
            : rd
        })
        let sheetRow = sheet.addRow(reportData)

        sheetRow.eachCell((cell, cellIndex) => {
          let rowCellData = rowData[cellIndex - 1]
          if (rowCellData.format) {
            if (rowCellData.format.alignment) {
              cell.value = rowCellData.value
              cell.alignment = { horizontal: rowCellData.format.alignment }
            } else {
              cell.numFmt = xlsx_formats[rowCellData.format]
              cell.alignment = { horizontal: 'right' }
            }
          }
        })
      })
      //Set column width, excluding first 5 rows
      sheet.columns.forEach(function(column) {
        var maxLength = 0

        column['eachCell'](function(cell, index) {
          if (index > 5) {
            var columnLength = cell.value ? cell.value.length : 1
            if (columnLength > maxLength) {
              maxLength = columnLength + 5
            }
          }
        })
        column.width = maxLength < 10 ? 10 : maxLength
      })
      //Highlight rows
      downloadStyle.yearHeaderStyle(sheet, 3) // Report name
      downloadStyle.yearHeaderStyle(sheet, 4) // Technographic name
      downloadStyle.highlightedRowStyle(sheet, 8) // Report header

      downloadStyle.highlightedRowStyle(sheet, sheet.rowCount) // Report Footer
      downloadsAPI.downloadAsExcel(workbook, state.downloadReportData.name)
    }
  }
}
