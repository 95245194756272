import * as getters from './advisor.getters'
import * as mutations from './advisor.mutations'
import * as actions from './advisor.action'

export default {
  namespaced: true,
  state: {
    selectedInstitution: {},
    selectedPeerGroup: {},
    selectedMetricId: null,
    selectingPeerGroup: false,
    peerGroups: [],
    menuData: [],
    reportId: null,
    reportToken: null,
    reportStatus: 'Preparing...',
    reportProgress: 0,
    spanId: 2,
    setId: 2,
    chartType: 'performance',
    statusDisplay: 'table',
    primaryTheme: null,
    treeData: null,
    bankKeyMetrics: [],
    cuKeyMetrics: [],
    bhcKeyMetrics: [],
    profitFlag: true
  },
  mutations,
  actions,
  getters
}
