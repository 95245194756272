<template>
  <div>
    <b-modal
      id="idle-timer"
      hide-header
      hide-footer
      no-close-on-esc
      no-close-on-backdrop
      modal-class="timer-modal"
    >
      <div class="d-flex align-items-center mb-3">
        <span class="px-2">
          <svg
            enable-background="new 0 0 35 33.5"
            viewBox="0 0 35 33.5"
            xmlns="http://www.w3.org/2000/svg"
          >
            <ellipse
              clip-rule="evenodd"
              cx="17.4"
              cy="16.3"
              fill="#2a5db0"
              fill-rule="evenodd"
              rx="16"
              ry="15.4"
            />
            <path
              d="m16.8 10.8c-.6 0-1.1-.2-1.5-.5s-.6-.8-.6-1.3.2-1 .6-1.3.9-.5 1.5-.5 1.1.2 1.5.5.6.8.6 1.3-.2 1-.6 1.3-.9.5-1.5.5zm-1.7 13.6v-11.9h3.5v11.9z"
              fill="#fff"
              transform="translate(0 .5)"
            />
          </svg>
        </span>
        <b> Your session will expire in {{ countDown }} seconds. </b>
      </div>
      <div class="row">
        <div class="col-sm-12 text-center">
          <button type="submit" class="btn btn-primary" @click="refreshSSO">
            Stay Logged In
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script charset="utf-8">
// global
import { mapGetters, mapActions, mapMutations } from 'vuex'

export default {
  name: 'Ethos',
  created() {},
  data() {
    return {
      countDown: 60,
      showIdleTimer: false,
      idleTillNow: false
    }
  },
  mounted() {},
  destroyed() {},
  computed: {
    ...mapGetters('Authentication', ['currentUserEmail'])
  },
  methods: {
    ...mapActions('Authentication', ['logOut']),
    ...mapMutations('Ethos', ['setRedirectURL']),
    timerCountDown() {
      setTimeout(
        function() {
          if (this.showIdleTimer) {
            if (this.countDown === 1) {
              this.$bvModal.hide('idle-timer')
              this.$router.push({ path: '/sso', query: { timeout: true } })
            } else {
              this.countDown = this.countDown - 1
              this.timerCountDown()
            }
          }
        }.bind(this),
        1000
      )
    },
    refreshSSO() {
      this.setRedirectURL(this.$route.path)
      window.location.href = '/saml'
    }
  },
  onIdle() {
    if (!this.showIdleTimer) {
      this.showIdleTimer = true
      this.timerCountDown()
      this.$bvModal.show('idle-timer')
    }
  }
}
</script>

<style lang="scss" src="./_ethos_theme.scss"></style>

<style lang="scss">
.timer-modal {
  .modal-dialog {
    max-width: 90%;
  }
}
</style>
