<template>
  <div class="">
    <b-card class="page-card" headerClass="page-card-header" bodyClass="page-card-body">
      <div slot="header">
        <b-row>
          <b-col cols="12">
            <div class="page-header-left">
              <div class="pr-1 header-icon"><i :class="cardIcon"></i></div>
              <div>
                <h5 class="mb-0">
                  <b>{{ pageName }}</b>
                  <span v-if="pageType">
                    - <i>{{ pageType }}</i></span
                  >
                </h5>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>

      <slot name="page_content"></slot>
    </b-card>
  </div>
</template>

<script>
export default {
  name: 'LayoutPageCard',
  props: {
    pageName: {
      type: String,
      required: true,
      default: '-'
    },
    pageType: {
      type: String,
      required: false
    },
    iconClass: {
      type: Array,
      required: false
    }
  },
  computed: {
    cardIcon() {
      return this.iconClass ? this.iconClass : ['fa', 'fa-bar-chart', 'fa-lg']
    }
  }
}
</script>

<style lang="scss" scoped></style>
