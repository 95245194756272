import axios from 'axios'

export const fetchMandatoryData = function({ dispatch }, payload) {
  let resourceList =
    payload.type === 'edit'
      ? [
          dispatch('fetchFIStatesList'),
          dispatch('fetchSharedGroups', payload.peerGroupId),
          dispatch('fetchInitInstitutions', payload.fiType),
          dispatch('fetchSelectedInstitutions', payload.peerGroupId)
        ]
      : [
          dispatch('fetchFIStatesList'),
          dispatch('fetchInitInstitutions', payload.fiType)
        ]
  return Promise.all(resourceList)
}

export const fetchQuickSearchInitData = function({ dispatch }, fiId) {
  return Promise.all([
    dispatch('fetchFIDetail', fiId),
    dispatch('fetchQSSizeMetrics'),
    dispatch('fetchQSProfitabilityMetrics'),
    dispatch('fetchQSStatesList'),
    dispatch('fetchQSRegionsList')
  ])
}

export const fetchAdvancedSearchInitData = function({ dispatch }, fiId) {
  return Promise.all([
    dispatch('fetchAdvancedSearchFormValues', fiId),
    dispatch('fetchAdvancedSearchLabelValues', fiId)
  ])
}

export const fetchAdvancedSearchFormValues = function({ commit, state }, fiId) {
  if (state.asAgeGroupList.length) {
    return Promise.resolve()
  }

  return axios
    .get('/api/fi_advanced_search/values_for_ui', {
      params: {
        financial_institution_id: fiId
      },
      handleErrors: true
    })
    .then(res => {
      commit('setAdvancedQueryFields', res.data.values)
    })
}

export const fetchAdvancedSearchLabelValues = function(
  { commit, state },
  fiId
) {
  if (state.asProfileValues.total_assets) {
    return Promise.resolve()
  }

  return axios
    .get('/api/fi_advanced_search/label_values', {
      params: {
        institution_id: fiId
      },
      handleErrors: true
    })
    .then(res => {
      commit('setAdvancedQueryLabelValues', res.data)
    })
}

export const fetchInitInstitutions = function({ commit }, fiType) {
  return axios
    .get('/api/banks', {
      params: {
        fi_type: fiType
      },
      handleErrors: true
    })
    .then(res => {
      commit('setFIInstitutionsList', res.data.financial_institutions)
    })
}

export const fetchFIStatesList = function({ commit, state }) {
  if (state.fiStatesList.length) {
    return Promise.resolve()
  }

  return axios
    .get('/api/states', {
      handleErrors: true
    })
    .then(res => {
      commit('setFIStatesList', res.data.states)
    })
}

export const fetchSharedGroups = function({ commit }, peerGroupId) {
  return axios
    .get(`/api/peer_groups/${peerGroupId}/shared_groups`, {
      handleErrors: true
    })
    .then(res => {
      commit('setSharedGroups', res.data.shared_groups)
    })
}

export const fetchSelectedInstitutions = function(
  { commit, state },
  peerGroupId
) {
  if (state.selectedInstitutions.length) {
    return Promise.resolve()
  }

  return axios
    .get(`/api/peer_groups/${peerGroupId}/edit`, { handleErrors: true })
    .then(res => {
      commit('setPeerGroup', res.data.peer_group)
      commit('setSelectedInstitutions', res.data.banks)
      commit('setSavedPeerData', res.data)
    })
}

export const fetchFIDetail = function({ commit, state }, fiId) {
  if (Object.keys(state.qsFISizeDetail).length) {
    return Promise.resolve()
  }
  return axios
    .get(`/api/financial_institutions/${fiId}/values`, {
      handleErrors: true
    })
    .then(res => {
      commit('setFISizeDetail', res.data.values.size)
      commit('setFIProfitabilityDetail', res.data.values.profitability)
      commit('setFIGeoDetail', res.data.values.geography)
    })
}

export const fetchQSSizeMetrics = function({ commit, state }) {
  if (state.qsSizeMetricsList.length) {
    return Promise.resolve()
  }

  return axios
    .get('/api/query_rules/sizes', {
      handleErrors: true
    })
    .then(res => {
      commit('setQSSizeMetrics', res.data.sizes)
    })
}

export const fetchQSProfitabilityMetrics = function({ commit, state }) {
  if (state.qsProfitabilityMetricsList.length) {
    return Promise.resolve()
  }

  return axios
    .get('/api/query_rules/profitabilities', {
      handleErrors: true
    })
    .then(res => {
      commit('setQSProfitabilityMetrics', res.data.profitabilities)
    })
}

export const fetchQSStatesList = function({ commit, state }) {
  if (state.qsStatesList.length) {
    return Promise.resolve()
  }

  return axios
    .get('/api/financial_institutions/states', {
      handleErrors: true
    })
    .then(res => {
      commit('setQSStatesList', res.data.states)
    })
}

export const fetchQSRegionsList = function({ commit, state }) {
  if (state.qsRegionsList.length) {
    return Promise.resolve()
  }

  return axios
    .get('/api/financial_institutions/regions', {
      handleErrors: true
    })
    .then(res => {
      commit('setQSRegionsList', res.data.regions)
    })
}

export const resetAllUserInputs = function({ commit }) {
  commit('resetPeerGroupFromUserInputs')
  commit('resetQuickSearchUserInputs')
  commit('resetAdvancedSearchUserInputs')
  commit('resetAdvancedSearchFormValidity')
}
