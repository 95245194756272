import apiUser from '../api/users'
import apiGroup from '../api/groups'

export default {
  fetchAllUsers({ state, commit }) {
    if (state.users.length) {
      return Promise.resolve()
    }

    return apiUser.all().then(res => {
      commit('setUsers', res.users)
    })
  },

  fetchAllGroups({ state, commit }) {
    if (state.groups.length) {
      return Promise.resolve()
    }

    return apiGroup.all().then(res => {
      commit('setGroups', res.groups)
    })
  },

  resetReportAccess({ commit }) {
    commit('resetReportAccessData')
  }
}
