<template>
  <aside class="aside-menu">
    <Metrics v-if="showAside('Report View')"></Metrics>
    <MarketShare
      v-if="showAside('Market Reports')"
      report-type="marketShare"
    ></MarketShare>
    <MarketShare
      v-if="showAside('Firmographics')"
      report-type="firmographics"
    ></MarketShare>
    <IndustryReporter
      v-if="showAside('IRReport')"
      report-type="industryReporter"
    ></IndustryReporter>
    <digital-channels
      v-if="showAside('DigitalChannelsReport')"
      report-type="digitalChannels"
    ></digital-channels>
  </aside>
</template>

<script>
import Metrics from '@/views/aside/Metrics'
import MarketShare from '@/views/aside/MarketShare'
import IndustryReporter from '@/modules/industry_reporter/components/ReportAside'
import DigitalChannels from '@/views/aside/DigitalChannels'

export default {
  name: 'c-aside',
  components: {
    Metrics,
    MarketShare,
    DigitalChannels,
    IndustryReporter
  },
  computed: {},
  methods: {
    showAside(type) {
      return type === this.$route.name
    }
  }
}
</script>

<style lang="scss">
.aside-header {
  padding: 0.75rem 1.25rem;
  border-bottom: 1px solid #a4b7c1;

  .close-icon {
    float: right;
    padding-top: 6px;
    cursor: pointer;
  }
}
</style>
